import React, { FC, useState } from "react";
import { Platform, Pressable, View } from "react-native";
import TableTitle from "../../components/TableTitle";
import Table from "../../../../Table/Table";
import { HorseFormTableDescriptor } from "../../components/TableDescriptors/HorseForm";
import { Performance } from "../../../../../types/races/horse";
import { FormLoader } from "../../../../Horse/Loader/HorseLoader";
import { EmptyState } from "../../../../States/states";
import RText from "../../../../RText/RText";
import { getRunnerPosition } from "../../../../../utils/getRunnerFinishText";
import {
  CourseButton,
  DateButton,
  ProfileButton,
  ReplayButton,
} from "./TableLinks";

type HorseFormTableProps = {
  form: Performance[];
  loading: boolean;
  tableTitle?: boolean;
  expandFormBtn?: boolean;
  type?: "horse" | "jockey" | "trainer";
  limit?: number;
};

export const HorseFormTable: FC<HorseFormTableProps> = ({
  form,
  loading,
  tableTitle = true,
  expandFormBtn,
  type = "horse",
  limit = undefined,
}) => {
  const [showMoreForm, setShowMoreForm] = useState<boolean>(!expandFormBtn);

  if (loading) return <FormLoader />;

  if (form.length === 0) {
    return <EmptyState message="No Form Records for this horse" />;
  }

  const formRecords = form.sort((a, b) => {
    return (
      new Date(b.race_start_time_scheduled).getTime() -
      new Date(a.race_start_time_scheduled).getTime()
    );
  });

  return (
    <View
      style={{ flex: Platform.OS === "web" ? undefined : 1, marginBottom: 20 }}
    >
      {tableTitle && type === "horse" && <TableTitle headerLeft="Form" />}
      <View style={formRecords.length > 3 && { marginBottom: 20 }}>
        <Table
          data={{
            header: {
              date: { value: "DATE" },
              course: { value: "COURSE" },
              dist: { value: "DIST." },
              going: { value: "GOING" },
              cl: { value: "CL." },
              type: { value: "TYPE" },
              pos: { value: "POS." },
              beaten: { value: "BEATEN" },
              // weight: { value: "WEIGHT" },
              ...(type === "jockey" || type === "trainer"
                ? { horse: { value: "HORSE" } }
                : {}),
              ...(type === "jockey" || (type === "horse" && form?.trainer)
                ? { trainer: { value: "TRAINER" } }
                : {}),
              ...(type === "trainer" || type === "horse"
                ? { jockey: { value: "JOCKEY" } }
                : {}),
              sp: { value: "SP" },
              or: { value: "OR" },
              replay: { value: "REPLAY" },
            },
            body: formRecords.slice(0, limit).map((form, i) => {
              let injectedData = [];

              //days break
              if (formRecords[i - 1]?.meeting_date && form.meeting_date) {
                const diff =
                  (new Date(formRecords[i - 1]?.meeting_date).getTime() -
                    new Date(form.meeting_date).getTime()) /
                  (1000 * 60 * 60 * 24);
                if (diff > 50) {
                  injectedData.push(`${diff} day break`);
                }
              }
              // change of owner
              if (formRecords[i - 1]?.owner_name !== form.owner_name) {
                if (formRecords[i - 1]?.owner_name && form.owner_name) {
                  injectedData.push(
                    `Change of Owner: ${form.owner_name} to ${
                      formRecords[i - 1]?.owner_name
                    }`
                  );
                }
              }
              // change of trainer
              if (formRecords[i - 1]?.trainer_name !== form.trainer_name) {
                if (formRecords[i - 1]?.trainer_name && form.trainer_name) {
                  injectedData.push(
                    `Change of Stable: ${form.trainer_name} to ${
                      formRecords[i - 1]?.trainer_name
                    }`
                  );
                }
              }

              return {
                ...(injectedData.length > 0 && type === "horse"
                  ? { injectedData: { value: injectedData } }
                  : {}),
                date: { value: <DateButton data={form} /> },
                course: { value: <CourseButton track={form.track} /> },
                dist: {
                  value: form.race_distance_formatted || form.race_distance,
                },
                going: { value: form.race_going },
                cl: { value: form.race_class },
                type: { value: form.race_type },
                pos: {
                  value: `${getRunnerPosition({
                    position: form.finish_position,
                    text: form.finish_text || form.position_status,
                  })}/${form.race_runner_count}`,
                },
                beaten: { value: form.distance_beaten_cumulative },
                // weight: { value: form.weight_text },
                ...(type === "jockey" || type === "trainer"
                  ? {
                      horse: {
                        value: (
                          <ProfileButton
                            type="horse"
                            name={form?.horse?.name}
                            formRecords={formRecords}
                          />
                        ),
                      },
                    }
                  : {}),
                ...(type === "jockey" || (type === "horse" && form?.trainer)
                  ? {
                      trainer: {
                        value: (
                          <ProfileButton
                            type="trainer"
                            name={form?.trainer?.name}
                            formRecords={formRecords}
                          />
                        ),
                      },
                    }
                  : {}),
                ...(type === "trainer" || type === "horse"
                  ? {
                      jockey: {
                        value: (
                          <ProfileButton
                            type="jockey"
                            name={form?.jockey?.name}
                            formRecords={formRecords}
                          />
                        ),
                      },
                    }
                  : {}),
                sp: { value: form.starting_price?.fractional },
                or: { value: form.rating_text },
                replay: {
                  value: <ReplayButton data={form} />,
                },
              };
            }),
          }}
          tableStyleDescriptor={HorseFormTableDescriptor}
          tableOnPressDescriptor={{}}
          showAllRows={showMoreForm}
        />
      </View>
      {formRecords.length > 3 && expandFormBtn && (
        <Pressable
          onPress={() => {
            setShowMoreForm((prev) => !prev);
          }}
        >
          <RText
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: -5,
              ...(Platform.OS == "web" ? { cursor: "pointer" } : null),
            }}
            size="sm"
            weight="bold"
            color="secondary"
          >
            {showMoreForm ? "Show Less Form " : "Show More Form"}
          </RText>
        </Pressable>
      )}
    </View>
  );
};
