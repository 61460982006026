import React, { FC, ReactNode } from "react";
import { Platform, Text, TextProps, TextStyle, View } from "react-native";
import variables from "../../styles/variables";

const { colors: fc, weight: fw, size: fs, family: ff } = variables.font;

export type RTextFont = keyof typeof ff;
export type RTextColors = keyof typeof fc;
export type RTextWeight = keyof typeof fw;
export type RTextSize = keyof typeof fs;

type DynamicTextStyle =
  | "changeBackgroundOpacity"
  | "underline"
  | "changeTextColor"
  | "none";

export type RTextProps = TextProps & {
  children: string | JSX.Element | JSX.Element[] | ReactNode;
  family?: RTextFont;
  color?: RTextColors | string;
  weight?: RTextWeight;
  size?: RTextSize;
  style?: TextStyle;
  uppercase?: boolean;
  capitalize?: boolean;
  italic?: boolean;
  dynamicTextStyle?: {
    onHover?: DynamicTextStyle;
    onPressed?: DynamicTextStyle;
  };
  onPress?: () => void;
  lowercase?: boolean;
  lineHeight?: number;
};

export const textStyles: { [key: string]: TextStyle } = {
  changeBackgroundOpacity: {
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  underline: {
    textDecorationLine: "underline",
  },
  changeTextColor: {
    opacity: 0.7,
  },
  none: {},
};

const RText: FC<RTextProps> = ({
  family = "primary",
  color,
  weight = "regular",
  size = "sm",
  style,
  uppercase,
  capitalize,
  italic,
  dynamicTextStyle,
  onPress = () => {},
  lowercase,
  lineHeight,
  ...rest
}) => {
  const [hovered, setHovered] = React.useState(false);

  if (Platform.OS == "web" && dynamicTextStyle) {
    return (
      <View
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Text
          style={[
            family && { fontFamily: `${ff[family]}_${fw[weight]}` },
            // try variable colors first, then accept #aaa-zzz colors, then default to primary
            { color: (color && (fc[color] || color)) || "#1d1c39" },
            size && { fontSize: fs[size] },
            uppercase && { textTransform: "uppercase" },
            capitalize && { textTransform: "capitalize" },
            italic && { fontStyle: "italic" },
            hovered &&
              dynamicTextStyle.onHover &&
              textStyles[dynamicTextStyle.onHover],
            dynamicTextStyle.onPressed &&
              textStyles[dynamicTextStyle.onPressed],
            lineHeight && { lineHeight: lineHeight },
            style,
          ]}
          {...rest}
        />
      </View>
    );
  }

  return (
    <Text
      style={[
        family && { fontFamily: `${ff[family]}_${fw[weight]}` },
        // try variable colors first, then accept #aaa-zzz colors, then default to primary
        { color: (color && (fc[color] || color)) || "#1d1c39" },
        size && { fontSize: fs[size] },
        uppercase && { textTransform: "uppercase" },
        capitalize && { textTransform: "capitalize" },
        italic && { fontStyle: "italic" },
        lowercase && { textTransform: "lowercase" },
        lineHeight && { lineHeight: lineHeight },
        style,
      ]}
      {...rest}
    />
  );
};

export default RText;
