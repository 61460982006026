const variables = {
  spacing: {
    xxxsmall: 2,
    xxsmall: 5,
    xsmall: 10,
    small: 20,
    medium: 30,
    large: 40,
    xlarge: 100,
  },
  colors: {
    separator: "#57556b",
    separatorLight: "#c6c6cd",
    hovered: "#f8f8f9",
    palette: {
      rtv: {
        primary: "#1d1c39",
        secondary: "#e1087e",
        tertiary: "#18b8b8",
        mainBackground: "#fff",
        yellow: "#f3cd27",
        black: "#000",
        white: "#fff",
        darkBlue: "#000033",
        tracker: "#fdf3f9",
        unselectedTab: "#4a4961",
      },
      neutral: {
        x0: "#fff",
        x25: "#f2f2f2",
        x50: "#efeff1",
        x100: "#e8e8eb",
        x150: "#d9d9d9",
        x200: "#c6c6cd",
        x500: "#8e8d9c",
        x600: "#55556a",
        x800: "#3f3e57",
      },
      transparent: {
        primary: "rgba(29, 28, 57, 0.2)",
        secondary: "rgba(225, 8, 126, 0.2)",
        tertiary: "rgba(24, 184, 184, 0.2)",
      },
      border: {
        secondary: "rgba(225, 8, 126, 1)",
        tertiary: "rgba(24, 184, 184, 1)",
      },
      transparentHover: {
        primary: "rgba(29, 28, 57, 0.3)",
        secondary: "rgba(225, 8, 126, 0.3)",
        tertiary: "rgba(24, 184, 184, 0.3)",
      },
      slightlyTransparent: {
        primary: "rgba(29, 28, 57, 0.8)",
      },
      buttons: {
        default: {
          backgroundColor: "#ce2e7c",
          borderColor: "#ce2e7c",
          color: "#fff",
          hovered: {
            backgroundColor: "#e9348d",
            borderColor: "#e9348d",
          },
          selected: {
            backgroundColor: "#af266a",
            borderColor: "#af266a",
          },
        },
        outline: {
          backgroundColor: "#fff",
          borderColor: "#ce2e7c",
          color: "#1d1c39",
          hovered: {
            backgroundColor: "#efeff1",
            borderColor: "#e9348d",
          },
          selected: {
            backgroundColor: "#af266a",
            borderColor: "#fff",
            color: "#fff",
          },
        },
        alternate: {
          backgroundColor: "#fff",
          borderColor: "#1d1c39",
          color: "#1d1c39",
          hovered: {
            backgroundColor: "#efeff1",
            borderColor: "#1d1c39",
          },
          selected: {
            backgroundColor: "#1d1c39",
            borderColor: "#1d1c39",
            color: "#fff",
          },
        },
        disabled: {
          backgroundColor: "#e8e8eb",
          borderColor: "#e8e8eb",
          text: {
            color: "#1d1c39",
          },
        },
      },
      watchAndBet: {
        yellow: "#ffc900",
        blue: "#18b8b8",
        red: "#e5385e",
        green: "#54ad4d",
      },
      notification: "#27ABAA",
      raceiQ: {
        darkGreen: "#159600",
        green: "rgba(140, 198, 63, 0.75)",
        lightGreen: "rgba(216, 210, 63, 0.75)",
        yellow: "rgba(247, 147, 30, 0.75)",
        orange: "#F7931E",
        red: "rgba(237, 28, 36, 0.75)",
        lightGrey: "#F5F5F5",
        grey: "#E0E0E0",
        darkGrey: "#BDBDBD",
        teal: "#3f98a1",
      },
    },
    raceIq: {
      speedColorsMap: {
        very_slow: "#9FC3F1",
        slow: "#C7DBF6",
        par: "#EFEFEF",
        fast: "#F6CBCC",
        very_fast: "#ED9799",
        null: "#EFEFEF",
      },
      scoresMap: {
        1: "#ED1C24",
        2: "#F7791E",
        3: "#F7931E",
        4: "#FCCC21",
        5: "#FCEE21",
        6: "#D8D23F",
        7: "#8CC63F",
        8: "#5DAC41",
        9: "#1EA92C",
        10: "#159600",
      },
    },
  },

  font: {
    size: {
      xxxs: 7,
      xxxs_xxs: 8,
      xxs: 9,
      xxs_xs: 10,
      xs: 12,
      xs_sm: 13,
      sm: 14,
      md: 16,
      md_lg: 18,
      lg: 20,
      xl: 24,
      xxl: 30,
      xxxl: 36,
      supersize: 70,
    },
    weight: {
      light: "300Light",
      regular: "400Regular",
      semiBold: "600SemiBold",
      bold: "700Bold",
    },
    colors: {
      primary: "#1d1c39",
      secondary: "#e1087e",
      tertiary: "#18b8b8",
      white: "#fff",
      black: "#000",
      grey: "#8e8d9c",
      disabled: "#d9d9d9",
      yellow: "#fbc857",
      orange: "#ff4000",
      green: "#009933",
      purple: "#330080",
      teal: "#3f98a1",
      muted: "#999999",
    },
    family: {
      primary: "TitilliumWeb",
      secondary: "Montserrat",
    },
    margin: {
      1: 0, // h1 styles at the level layout
      2: 8, // give all h2's a marginBottom of 8
      3: 0, // h3 styles at the individual component level
      4: 0,
      5: 0,
      6: 0,
    },
  },
  form: {
    input: {},
    focused: {},
    nonEditable: {},
    error: {},
  },
  buttonSizes: {
    small: {
      width: 50,
      height: 42,
    },
    medium: {
      width: 100,
      height: 42,
    },
    large: {
      width: 144,
      height: 62,
    },
  },
  hero: {
    spacing: 12,
    row: {
      h1: {
        medium: 34,
        large: 46,
        xlarge: 54,
        xxlarge: 54,
      },
      h2: {
        medium: 16,
        large: 16,
        xlarge: 20,
        xxlarge: 20,
      },
      button: {
        medium: 16,
        large: 16,
        xlarge: 20,
        xxlarge: 20,
      },
    },
  },

  sizes: {
    // Sizes for the components, like button, input, etc.
    // Values here are just an example, please change them to your needs
    // And remove comments next to it when you are done
    tabBarIcons: { width: 28, height: 28 },
    xxsmall: { width: 8, height: 8 },
    xsmall: { width: 12, height: 12 },
    small: { width: 16, height: 16 },
    tipTag: { width: 20, height: 20 },
    medium: { width: 24, height: 24 },
    large: { width: 32, height: 32 },
    larger: { width: 35, height: 35 },
    xlarge: { width: 40, height: 40 },
    xxlarge: { width: 48, height: 48 },
    xxxlarge: { width: 56, height: 56 },
    supersize: { width: 1000, height: 300 },
    takeoverAdWebMediumAndDown: { width: 60, height: 12 },
    takeoverAdWebLargeAndUp: { width: 75, height: 16 },
    takeoverAdMobile: { width: 60, height: 14 },
    raceiqMobile: { width: 50, height: 50 },
    raceiqDesktop: { width: 70, height: 50 },
  },
  layoutMaxWidth: 1500,
  width4K: 2000,
};

export default variables;
