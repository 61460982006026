// This component is used to animate rotating a component, toggle for example
// Props:
//  - children: React.ReactNode
//  - trigger?: boolean
//  - duration?: number
//  - startDegrees?: number
//  - degreesToRotate?: number
//  - waitFor?: boolean. If set, the animation will wait for the waitFor condition to be true before animating
// Usage:
//  <RotateAnimation startDegrees={0} degreesToRotate={180} trigger={open}>
//    {sampleData}
//  </RotateAnimation>
//
//  where sampleData is a React.ReactNode

import { Animated, Easing, Platform } from "react-native";
import React, { useEffect, useState } from "react";

type Props = {
  trigger: boolean;
  duration?: number;
  children: React.ReactNode;
  startDegrees?: number;
  degreesToRotate?: number;
  waitFor?: boolean;
};

const RotateAnimation = ({
  trigger,
  duration = 200,
  children,
  startDegrees = 0,
  degreesToRotate = 360,
  waitFor = true,
}: Props) => {
  const [rotate] = useState(new Animated.Value(0));

  useEffect(() => {
    if (waitFor) {
      Animated.timing(rotate, {
        toValue: trigger ? 1 : 0,
        duration: duration,
        easing: Easing.linear,
        useNativeDriver: Platform.OS != "web",
      }).start();
    }
  }, [trigger]);

  const spin = rotate.interpolate({
    inputRange: [0, 1],
    outputRange: [`${startDegrees}deg`, `${degreesToRotate}deg`],
  });

  return (
    <Animated.View
      style={{
        transform: [{ rotate: spin }, { perspective: 1000 }],
      }}
    >
      {children}
    </Animated.View>
  );
};

export default RotateAnimation;
