import React from "react";
import { Platform, ViewStyle } from "react-native";
import Loader from "./Utils/";

/* Adjust the width and height to set the size of the loader */
/* Use the style prop for margin/padding */
/* Use the radius prop to set the border radius */

type Props = {
  width?: string | number;
  height?: string | number;
  radius?: number;
  style?: ViewStyle;
};

const RectangleLoader: React.FC<Props> = ({
  width = 50,
  height = 20,
  style,
  radius = 3,
}) => {
  return (
    <Loader.ContentLoader
      accessibilityLabel="loading-content"
      speed={2}
      width={width}
      height={height}
      backgroundColor="#e8e8eb"
      foregroundColor="#aeaeb2"
      style={style}
    >
      {Platform.OS === "web" ? (
        <rect rx={radius} ry={radius} width={width} height={height} />
      ) : (
        <Loader.Rect width={width} height={height} rx={radius} ry={radius} />
      )}
    </Loader.ContentLoader>
  );
};

export default RectangleLoader;
