import React, { useCallback, useState } from "react";
import {
  FlatList,
  View,
  ViewStyle,
  FlatListProps,
  PixelRatio,
} from "react-native";
import { useScrollControl } from "../../hooks/useScrollControl";
import { FlashList } from "@shopify/flash-list";
import { useResponsive } from "../../hooks/useResponsive";

type Props<T> = FlatListProps<T> & {
  elementDistance: number;
  style?: ViewStyle;
  childrenWidth: number;
  hideArrows?: boolean;
  scrollRef?: React.RefObject<FlatList>;
  data: T[];
  heightOverride?: number;
};

// For content Previews, they follow this format of sizes
// use this  to calculate the height in advance to optimize the FlashList.
// for the other edge cases, i.e UpcomingRaces, they can be injected via height ovverride
const standardPaddingBothSides = 10;
const extraPixelBothSides = 2;
const standardAspectRatio = 16 / 9;
const extraVerticalPadding = 50;
const topVerticalPadding = 5;

const RFlatList = <T extends {}>({
  elementDistance,
  style,
  childrenWidth,
  hideArrows,
  scrollRef,
  data,
  heightOverride,
  ...rest
}: Props<T>) => {
  const { isXSmallOnly } = useResponsive();
  const pixelRatio = PixelRatio.get();

  // Align width
  const rawWidth = isXSmallOnly ? 220 : 300;
  const width = Math.floor(rawWidth * pixelRatio) / pixelRatio;

  // Align height
  const height =
    (width - (standardPaddingBothSides + extraPixelBothSides)) *
      (1 / standardAspectRatio) +
    extraVerticalPadding +
    topVerticalPadding +
    extraPixelBothSides;

  const {
    handleLayout,
    handleHideArrow,
    hideLeftArrow,
    hideRightArrow,
    setPosition,
    ScrollIndicator,
  } = useScrollControl({
    scrollRef,
    elementDistance,
    childrenWidth,
  });

  const handleScroll = useCallback(
    ({ nativeEvent }) => {
      setPosition(nativeEvent.contentOffset.x);
      handleHideArrow(nativeEvent);
    },
    [setPosition, handleHideArrow]
  );

  if (rest.horizontal) {
    return (
      <View style={style}>
        {!hideArrows && !hideLeftArrow && <ScrollIndicator type="left" />}
        <FlashList
          {...rest}
          ref={scrollRef}
          data={data}
          onScroll={handleScroll}
          horizontal
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={400}
          estimatedItemSize={childrenWidth / data.length}
          estimatedListSize={{
            width: childrenWidth,
            height:
              Math.floor((heightOverride || height) * pixelRatio) / pixelRatio,
          }}
        />
        {!hideArrows && !hideRightArrow && <ScrollIndicator type="right" />}
      </View>
    );
  } else {
    return <FlatList style={style && style} {...rest} data={data} />;
  }
};

export default React.memo(RFlatList);
