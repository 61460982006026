import strftime from "strftime";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isoWeek from "dayjs/plugin/isoWeek";
import advancedFormat from "dayjs/plugin/advancedFormat";

// polyfill for android Intl
if (typeof Intl === "undefined") {
  require("intl");
  require("intl/locale-data/jsonp/en");
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

const tz = dayjs.tz.guess();

export const today = dayjs(new Date()).toDate();

export const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const ONE_DAY_IN_SECONDS = 60 * 60 * 24;

/**
 * Convert the given time string to a formatted string representing the time in the "+0100" timezone.
 *
 * @example
 * format RaceTime("2023-05-31T10:30:00Z"); // returns "11:30"
 *
 * @param time The time string to convert.
 * @returns The formatted time string.
 */
export const formatRaceTime = (time: string) => {
  if (!time) return;
  return time?.split("T")[1].split(":").slice(0, 2).join(":");
};

/**
 * Convert the given date to a formatted readable date string representing the date in the "+0100" timezone.
 *
 * @example
 * formatRaceTime("2023-05-31T10:30:00Z"); // returns "31-May-2023"
 *
 * @param date The date time string to convert.
 * @returns The formatted date string.
 */
export const paramFriendlyDate = (date: Date | string) => {
  const timezone = strftime;
  const formattedDate = timezone("%d-%B-%Y", new Date(date));
  return formattedDate;
};

/**
 * Get a full, calenderesque date, i.e 31st May 2023
 *
 * @example
 * formatRaceTime("2023-05-31T10:30:00Z"); // returns "31st May 2023"
 *
 * @param date The date time string to convert.
 * @returns The formatted date string.
 */
export const fullCalendarDate = (date: Date | string) => {
  const timezone = strftime;
  const ddmmyyyy = timezone("%d-%B-%Y", new Date(date));
  let [dd, mm, yyyy] = ddmmyyyy.split("-");
  // remove leading 0 from dd if necessary
  // if (dd.startsWith("0")) dd = dd.substring(0, 1);

  return `${getOrdinal(parseInt(dd))} ${mm} ${yyyy}`;
};

/**
 * Remove the colon from the time string.
 *
 * @example
 * paramRaceTime("11:30"); // returns "1130"
 *
 * @param time The time string to modify.
 * @returns The modified time string.
 */
export const paramRaceTime = (time: string): string => time.replace(":", "");

/**
 * Determines whether the provided time string represents a future time.
 *
 * @example
 * isFuture("2030-05-31T10:30:00Z"); // returns true
 *
 * @param time The time string to check.
 * @returns true if the time string represents a future time, false otherwise.
 */
export const isFuture = (time: string | Date) => {
  const isFuture = new Date(time).getTime() > Date.now();
  return isFuture;
};

/**
 * Format the date string to a string in the format "%a %b %-d ".
 *
 * @example
 * formatDate("2023-05-31"); // returns "Wed May 31 "
 *
 * @param date The date string to format.
 * @returns The formatted date string.
 */
export const formatDate = (date: string) => {
  const formattedDate = strftime("%a %-d %b", new Date(date));
  return formattedDate;
};

/**
 * Format the date string to a string in the format "%a %b %-d %Y".
 *
 * @example
 * formatDateWithYear("2023-05-31"); // returns "Wed 31 May 2023"
 *
 * @param date The date string to format.
 * @returns The formatted date string.
 */
export const formatDateWithYear = (date: string) => {
  const formattedDate = strftime("%a %-d %b %Y", new Date(date));
  return formattedDate;
};

/**
 * Convert the given date to a formatted readable date string representing the date in the "+0100" timezone.
 *
 * @example
 * formatDateWithoutDay("2023-05-31T10:30:00Z"); // returns "31 May 2023"
 *
 * @param date The date time string to convert.
 * @returns The formatted date string.
 */
export const formatDateWithoutDay = (date: Date | string) => {
  const timezone = strftime;
  const formattedDate = timezone("%d %b %Y", new Date(date));
  return formattedDate;
};

/**
 * Format the date string to a string in the format "%d%b%y ".
 *
 * @example
 * formatShorteningDate("2023-05-31"); // returns "31May23 "
 *
 * @param date The date string to format.
 * @returns The formatted date string.
 */
export const formatShorteningDate = (date: string) => {
  const formattedDate = strftime("%d%b%y", new Date(date));
  return formattedDate;
};

/**
 * Convert the given number of seconds to a string representing minutes and seconds in the format "mm:ss".
 *
 * @example
 * parseSecondsInMinutes(123); // returns "02:03"
 *
 * @param seconds The number of seconds to convert.
 * @returns The formatted string.
 */
export const parseSecondsInMinutes = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${secondsLeft
    .toString()
    .padStart(2, "0")}`;
};

/**
 * Sort the data by the "start_time_scheduled" property of the objects.
 *
 * @example
 * racesSortedByStartTime([{start_time_scheduled: "2023-05-31T10:30:00Z"}, {start_time_scheduled: "2023-05-31T09:30:00Z"}]);
 * // returns [{start_time_scheduled: "2023-05-31T09:30:00Z"}, {start_time_scheduled: "2023-05-31T10:30:00Z"}]
 *
 * @param data The array to sort.
 * @returns The sorted array.
 */
export const racesSortedByStartTime = (data) => {
  return data?.sort((a, b) => {
    const dateA: any = new Date(a.start_time_scheduled);
    const dateB: any = new Date(b.start_time_scheduled);
    return dateA - dateB;
  });
};

/**
 * Convert the Date object or date string to a string in the format "YYYY-MM-DD".
 *
 * @example
 * YYYYMMDD(new Date("2023-05-31")); // returns "2023-05-31"
 * YYYYMMDD("2023-05-31T10:30:00Z"); // returns "2023-05-31"
 *
 * @param date The date to convert.
 * @returns The formatted string.
 */
export const YYYYMMDD = (date: Date | string) => {
  // if (typeof date === "string") {
  //   date = new Date(date);
  // }
  return dayjs(date).format("YYYY-MM-DD");
};

export const YYYYMMDDWithTimeZone = (date: string | Date) => {
  if (typeof date === "string") date = new Date(date);

  const t = date.toLocaleString("en-GB", { timeZone: tz });

  const [day, month, year] = t.split(",")[0].split("/");

  return `${year}-${month}-${day}`;
};

/**
 * Convert the Date object or date string to a string in the format "HHSS".
 *
 * @example
 * HHSS(new Date("2023-05-31T10:30:00Z")); // returns "1030"
 * HHSS("2023-05-31T10:30:00Z"); // returns "1030"
 *
 * @param date The date to convert.
 * @returns The formatted string.
 */
export const HHSS = (date: Date | string) => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  hours = hours.length < 2 ? "0" + hours : hours;
  minutes = minutes.length < 2 ? "0" + minutes : minutes;
  return hours + minutes;
};

/**
 * Returns "today", "tomorrow", "yesterday" or the weekday of the provided date.
 * If no date is provided, returns "today".
 *
 * @example
 * getRelativeOrWeekday(new Date()); // returns "today"
 * getRelativeOrWeekday(new Date("2023-05-31")); // returns "wednesday"
 *
 * @param date The date to check.
 * @returns The relevant string.
 */
export const getRelativeOrWeekday = (date = new Date()) => {
  const inputDate = dayjs(date);
  // const today = dayjs();
  const tomorrow = dayjs().add(1, "day");
  const yesterday = dayjs().subtract(1, "day");

  if (inputDate.isSame(today, "day")) {
    return "today";
  } else if (inputDate.isSame(tomorrow, "day")) {
    return "tomorrow";
  } else if (inputDate.isSame(yesterday, "day")) {
    return "yesterday";
  } else {
    return inputDate.format("dddd").toLowerCase();
  }
};

/**
 * Returns the weekday of the provided date.
 *
 * @example
 * getWeekday(new Date("2023-05-31")); // returns "wednesday"
 *
 * @param date The date to check.
 * @returns The relevant string.
 */
export const getWeekday = (date = new Date()) => {
  const inputDate = dayjs(date);

  return inputDate.format("dddd").toLowerCase();
};

/**
 * Get the Date object for the date day days from now.
 * If no value is provided, return the Date object for the current date.
 *
 * @example
 * getDate(); // returns current date
 * getDate(1); // returns tomorrow's date
 *
 * @param day The number of days from now.
 * @returns The date.
 */
export const getDate = (day: number = 0): Date => {
  const date = new Date();
  date.setDate(date.getDate() + day);
  return date;
};

/**
 * Get the ordinal suffix string for the provided number.
 *
 * @example
 * getOrdinal(1); // returns "st"
 * getOrdinal(2); // returns "nd"
 * getOrdinal(3); // returns "rd"
 * getOrdinal(4); // returns "th"
 *
 * @param n The number.
 * @returns The ordinal suffix.
 */
export const getOrdinalSuffix = (n: number) => {
  return [`st`, `nd`, `rd`][((((n + 90) % 100) - 10) % 10) - 1] || `th`;
};

/**
 * Get the ordinal string for the provided number.
 *
 * @example
 * getOrdinal(1); // returns "1st"
 * getOrdinal(2); // returns "2nd"
 * getOrdinal(3); // returns "3rd"
 * getOrdinal(4); // returns "4th"
 *
 * @param n The number.
 * @returns The ordinal string.
 */
export const getOrdinal = (n: number) => `${n}${getOrdinalSuffix(n)}`;

/**
 * Convert a month string to a number, with January as 1 and December as 12.
 *
 * @example
 * parseMonthStringToNumeral("February"); // returns 2
 * parseMonthStringToNumeral("Feb"); // returns 2
 * @param month The month string to convert.
 * @returns The number.
 */
export const parseMonthStringToNumeral = (month: string): number => {
  const fullMonths = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  // pulls out the month, even if input is apr or april or April
  const res =
    fullMonths
      .flatMap((fullMonth) => {
        if (fullMonth.includes(month.toLowerCase()))
          return fullMonths.indexOf(fullMonth);
      })
      .filter(Boolean)[0] + 1;

  return res;
};

/**
 * Formats a date string for use in a URL.
 *
 * @param {string} dateString - A string representing a date in ISO format (e.g. "2022-01-01").
 * @returns {string} A string representing the formatted date in the format of `day-month-year` (e.g. "1-jan-2022").
 *
 * @example
 * const date = "2022-01-01";
 * const formattedDate = formatDateForURL(date);
 * console.log(formattedDate); // Output: "1-jan-2022"
 */
export const formatDateForURL = (dateString: string) => {
  if (!dateString) return;
  const [y, m, d] = dateString.split("T")[0].split("-");
  const monthStr = months[parseInt(m) - 1];
  return `${d}-${monthStr}-${y}`;
};

export const formatTimeForURL = (dateString: string) => {
  const [hh, mm] = dateString.split("T")[1].split(":");
  return hh + mm;
};

/**
 * Formats a date string in the format of "Weekday Month day, year".
 *
 * @param {string} dateString - A string representing a date in ISO format (e.g. "2022-01-01").
 * @returns {string} A string representing the formatted date in the format of "Weekday Month day, year" (e.g. "Tuesday July 1, 2023").
 *
 * @example
 * const date = "2023-07-01";
 * const formattedDate = formatDateLong(date);
 * console.log(formattedDate); // Output: "Tuesday July 1, 2023"
 */
export const formatDateLong = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
};

// Example: Fri 31 Jun 2023
export const formatFriendlyDate = (date: Date) => {
  const formattedDate = strftime("%a %d %b %Y", new Date(date));
  return formattedDate;
};

// Example: Friday 31 June 2023
export const formatFriendlyLongDate = (date: Date) => {
  const formattedDate = strftime("%A %d %B %Y", new Date(date));
  return formattedDate;
};

/**
 * Determines whether the provided time string is today
 *
 * @example
 * isToday("2030-07-17T10:30:00Z"); // returns true if the date is today
 *
 * @param date The date string to check.
 * @returns true if the date string is today, false otherwise.
 */

export const isToday = (date: string | number) => {
  const inputDate = new Date(date);
  // const today = new Date();

  // Check if the inputDate's year, month, and day are equal to today's year, month, and day
  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
};

/**
 * Determines whether the provided time string is Tomorrow
 *
 * @example
 * isTomorrow("2030-07-17T10:30:00Z"); // returns true if the date is Tomorrow
 *
 * @param date The date string to check.
 * @returns true if the date string is Tomorrow, false otherwise.
 */
// Implementation of the isTomorrow function
export const isTomorrow = (date: string | number) => {
  const inputDate = new Date(date);
  const now = new Date();

  // Set the time of today to midnight to compare only the date parts
  const tomorrow = new Date(now);
  tomorrow.setDate(now.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const dayAfterTomorrow = new Date(tomorrow);
  dayAfterTomorrow.setDate(tomorrow.getDate() + 1);

  // Check if the inputDate is within the range of tomorrow's day
  return inputDate >= tomorrow && inputDate < dayAfterTomorrow;
};

/**
 * Determines whether the provided time string is in the next hour
 *
 * @example
 * isInTheNextHour("2030-11-30T16:45:00Z"); // returns true if the date is within the nxt hour
 *
 * @param date The date string to check.
 * @returns true if the date string is in the next hour, false otherwise.
 */

export const isInTheNextHour = (date: string) => {
  const inputDate = new Date(date);
  const now = new Date();
  const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);

  return (
    inputDate.getTime() >= now.getTime() &&
    inputDate.getTime() <= oneHourLater.getTime()
  );
};

export const getFriendlyDateString = (
  date: string | Date,
  withYear?: boolean
): string => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateObj = typeof date === "string" ? new Date(date) : date;
  const dayOfWeek = daysOfWeek[dateObj.getDay()];
  const month = monthsOfYear[dateObj.getMonth()];
  const dayOfMonth = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${dayOfWeek} ${month} ${dayOfMonth} ${withYear ? year : ""}`;
};

/**
 * Changes an array of dates into 'Day 1', 'Day 2', 'Day 3' etc.
 *
 */
export const formatDatesAsDayN = (dates: Date[], reverse: boolean) => {
  return dates.map((date, i) => ({
    date,
    day: `day-${i + 1}`,
    index: reverse ? dates.length - i - 1 : i,
  }));
};

/**
 * Calculates the total number of days between two given dates
 */

export const calculateDaysBetweenDates = (date1: Date, date2: Date) => {
  const oneDay = 1000 * 60 * 60 * 24;
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();
  const differenceMs = date2Ms - date1Ms;
  return Math.round(differenceMs / oneDay);
};

/**
 * Returns an array of dates between two given dates
 */

export const getDatesBetweenDates = (startDate: Date, endDate: Date) => {
  const dates = [];
  // Strip hours minutes seconds etc.
  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1 // Will increase month if over range
    );
  }
  return dates;
};

/**
 * Determines whether the two provided time strings represent the same date.
 *
 * @example
 *  isSameDate(
 * `Thu Oct 19 2023 15:35:00 GMT+0100 (British Summer Time)`,
 * `Thu Oct 19 2023 08:00:00 GMT-0700 (Pacific Daylight Time)`
 * )
 * @param dateStr1
 * @param dateStr2
 * @returns true if the two time strings represent the same date, false otherwise.
 */

export const isSameDate = (dateStr1: string, dateStr2: string): boolean => {
  // Parse the date strings into Date objects
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Compare the year, month, and day components
  const year1 = date1.getUTCFullYear();
  const month1 = date1.getUTCMonth();
  const day1 = date1.getUTCDate();

  const year2 = date2.getUTCFullYear();
  const month2 = date2.getUTCMonth();
  const day2 = date2.getUTCDate();

  return year1 === year2 && month1 === month2 && day1 === day2;
};

/**
 * Determines whether the provided date falls within the last fortnight
 *
 * @example
 * isLastFortnight("2023-10-31")
 * @param date
 * @returns true if the date is within the last fortnight, false otherwise.
 */

export const isLastFortnight = (date: Date | string): boolean => {
  if (typeof date === "string") date = new Date(date);

  const fortnightAgo = dayjs().subtract(14, "day");
  return dayjs(date).isAfter(fortnightAgo);
};

/**
 * Get the current month in the format "yyyy-mm-dd".
 * @returns {string} The current month.
 */
export const getCurrentMonth = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = "01";
  return `${year}-${month}-${day}`;
};

/**
 * Get the date X months ago from the current date.
 * @param {number} x - The number of months ago.
 * @returns {string} The date X months ago in the format "yyyy-mm-dd".
 */
export const getXMonthsAgo = (x: number): string => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - x);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = "01";
  return `${year}-${month}-${day}`;
};

/**
 * Checks if a given date is within the next 24 hours.
 *
 * @param {Date|string} date - The date to check. Can be a Date object or a string that can be parsed into a Date.
 * @returns {boolean} Returns true if the date is within the next 24 hours, false otherwise.
 */
export const isDateWithinNext24Hours = (date: Date | string): boolean => {
  if (typeof date === "string") date = new Date(date);

  const now = new Date().getTime();
  return date.getTime() - now < ONE_DAY_IN_MS;
};

/**
 * Checks if the provided date is in the past six months
 *
 * @example
 * isInThePastSixMonths("2023-05-31")
 *
 * @param date The date to check.
 * @returns true if the date is in the past six months, false otherwise.
 */
export const isInThePastSixMonths = (date: Date | string): boolean => {
  if (typeof date === "string") date = new Date(date);

  const sixMonthsAgo = dayjs().subtract(6, "month");
  return dayjs(date).isAfter(sixMonthsAgo);
};

// Example usage:
// const epoch = 171801567700; // example epoch value in seconds
// console.log(convertEpochToReadableDate(epoch));
// Output: Monday, June 10, 2024, 10:34:37
export const convertEpochToReadableDate = (epoch: number): string => {
  const date = new Date(epoch);

  // Define arrays for days and months
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract day of the week, month, day, year, hours, minutes, and seconds
  const dayOfWeek = days[date.getUTCDay()];
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;

  return formattedDate + " UTC";
};

// TESTS....
// console.log("\n\n\n\n\n\n");
// console.log("****", YYYYMMDD(new Date()));
// console.log(
//   "YYYYMMDDWithTimeZone ",
//   YYYYMMDDWithTimeZone("2023-05-31T10:30:00Z")
// );
// console.log("WEEKDAY:::::: ", getRelativeOrWeekday(new Date()));
// console.log("formatRaceTime::::: ", formatRaceTime("2023-05-31T10:30:00Z")); // returns "11:30"
// console.log("formatRaceTime::::: ", formatRaceTime("2023-05-31T10:30:00Z")); // returns "31 May 2023"
// console.log("paramRaceTime::::: ", paramRaceTime("11:30")); // returns "1130"
// console.log("isFuture::::: ", isFuture("2030-05-31T10:30:00Z")); // returns true
// console.log("formatDate::::: ", formatDate("2023-05-31")); // returns "Wed May 31 "
// console.log("formatShorteningDate::::: ", formatShorteningDate("2023-05-31")); // returns "31May23 "
// console.log("parseSecondsInMinutes::::: ", parseSecondsInMinutes(123)); // returns "02:03"
// console.log(
//   "racesSortedByStartTime::::: ",
//   racesSortedByStartTime([
//     { start_time_scheduled: "2023-05-31T10:30:00Z" },
//     { start_time_scheduled: "2023-05-31T09:30:00Z" },
//   ])
// );
// console.log("YYYYMMDD::::: ", YYYYMMDD(new Date("2023-05-31"))); // returns "2023-05-31"
// console.log("HHSS::::: ", HHSS(new Date("2023-05-31T10:30:00Z"))); // returns "1030"
// console.log("getRelativeOrWeekday::::: ", getRelativeOrWeekday(new Date())); // returns "today"
// console.log("getDate::::: ", getDate()); // returns current date
// console.log("getOrdinal::::: ", getOrdinal(1)); // returns "1st"
// console.log(
//   "parseMonthStringToNumeral::::: ",
//   parseMonthStringToNumeral("February")
// ); // returns 2
// console.log("check if todays date: ", isToday("2023-07-17T10:30:00Z"); // returns true
// console.log("check if todays date: ", isToday("2023-07-18T10:30:00Z") // returns false
// console.log(
//   isSameDate(
//     `Thu Oct 19 2023 15:35:00 GMT+0100 (British Summer Time)`,
//     `Thu Oct 19 2023 08:00:00 GMT-0700 (Pacific Daylight Time)`
//   )
// ); // returns true
// console.log(
//   isSameDate(
//     `Fri Oct 20 2023 15:35:00 GMT+0100 (British Summer Time)`,
//     `Thu Oct 19 2023 08:00:00 GMT-0700 (Pacific Daylight Time)`
//   )
// ); // returns false
// console.log(isLastFortnight("2023-10-31")); // returns false
// console.log(isLastFortnight("2023-11-14")); // returns true
// console.log("123isInThePastSixMonths", isInThePastSixMonths("2021-01-01")); // returns false
// console.log("123isInThePastSixMonths", isInThePastSixMonths(today)); //returns true
