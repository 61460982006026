import {
  // View,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Platform,
  View,
  // Easing,
} from "react-native";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import variables from "../../styles/variables";
import RText from "../RText/RText";
import Icon from "../Icon";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import { APP_ENV } from "../../client";
import { useAtom, useSetAtom } from "jotai";
import {
  routeAndPathAtom,
  tabBarHeightAtom,
  unreadNotificationCountAtom,
} from "../../atoms";

type Props = {
  showTabBar: boolean;
  onLayout: (event: any) => void;
};

type Tab = {
  title: string;
  route: string;
  topDirectory: string;
};

const TabBar = ({ showTabBar, onLayout = undefined }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>();
  const navigation = useNavigation();
  const [routeAndPath] = useAtom(routeAndPathAtom);
  const [unreadCount] = useAtom(unreadNotificationCountAtom);
  const setTabBarHeight = useSetAtom(tabBarHeightAtom);

  const TabScreens: Tab[] = useMemo(() => {
    return [
      { title: "Races", route: "Racecards", topDirectory: "racecards" },
      { title: "Tips", route: "RTVTipsters", topDirectory: "tips" },
      { title: "Watch", route: "Watch", topDirectory: "watch" },
      { title: "Results", route: "Results", topDirectory: "results" },
      { title: "Menu", route: "Menu", topDirectory: "menu" },
    ];
  }, []);

  // and then the top most directory, so we can stay selected even on sub pages
  const topDirectory = routeAndPath.path?.split("/")[1] || "/";

  useLayoutEffect(() => {
    if (selectedTab != topDirectory) setSelectedTab(topDirectory);
  }, [topDirectory]);

  const tabBarHeightRef = useRef(new Animated.Value(60)).current;

  useEffect(() => {
    // Don't use this effect in integration env as messes up screenshots
    if (APP_ENV == "integration") return;
    // show/hide tabBar animation
    Animated.timing(tabBarHeightRef, {
      toValue: showTabBar ? 60 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [showTabBar]);

  return (
    <Animated.View
      fsClass="fs-unmask"
      accessibilityLabel="tab-bar"
      onLayout={(e) => {
        setTabBarHeight(e.nativeEvent.layout.height);
        if (onLayout) {
          onLayout(e);
        }
      }}
      style={[
        styles.tabBarContainer,
        {
          height:
            Platform.OS == "ios"
              ? tabBarHeightRef._value + 10
              : tabBarHeightRef,
          overflow: "hidden",
          position: "absolute",
          bottom: 0,
        },
      ]}
    >
      {TabScreens.map((tab: Tab) => {
        const isSelectedTab = tab.topDirectory === selectedTab;
        return (
          <TouchableOpacity
            accessibilityLabel={`TabBar-${tab.title}`}
            key={tab.title}
            onPress={() => {
              if (tab.title === "Menu") {
                navigation.dispatch(DrawerActions.toggleDrawer());
              } else {
                setSelectedTab(tab.topDirectory);
                navigation.navigate(tab.route);
              }
            }}
            style={styles.tabButton}
          >
            <Icon
              name={
                isSelectedTab
                  ? tab.title.toLowerCase() + "Selected"
                  : tab.title.toLowerCase()
              }
              size="tabBarIcons"
              notifications={tab.title === "Menu" && unreadCount}
              style={{ minHeight: 30 }}
              onTabar
            />
            <RText
              style={
                isSelectedTab
                  ? styles.tabButtonTextSelected
                  : styles.tabButtonText
              }
              color="white"
            >
              {tab.title}
            </RText>
          </TouchableOpacity>
        );
      })}
    </Animated.View>
  );
};

const { colors } = variables;

const styles = StyleSheet.create({
  tabBarContainer: {
    width: "100%",
    backgroundColor: colors.palette.rtv.primary,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  tabButton: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 30,
    ...Platform.select({
      ios: {
        paddingBottom: 10, // avoids the system controls, not necessary on android
      },
    }),
  },
  tabButtonText: {
    textTransform: "capitalize",
  },
  tabButtonTextSelected: {
    color: colors.palette.neutral.x500,
  },
});

export default TabBar;
