let analytics = null;

export { analytics };

export async function sendAnalyticsEventAsync(
  eventName: string,
  eventParams: Record<string, any> = {}
) {
  window?.dataLayer?.push({
    event: eventName,
    ...eventParams,
  });
}
