import React from "react"; // This is actually used. It's just not detected by the linter
import { StyleSheet, Pressable, ViewStyle, View } from "react-native";
import Icon from "../Icon";
import variables from "../../styles/variables";
import RImage from "../RImage/RImage";
import LiveButton from "../Buttons/LiveButton";
import { DEFAULT_REPLAY_IMAGE } from "../Image/images/defaultImages";
import { toastSuccess } from "../Toasts/Toasts";
import { useToast } from "react-native-toast-notifications";

const { neutral } = variables.colors.palette;

type Props = {
  thumbnailUrl?: string;
  style?: ViewStyle;
  imageStyle?: ViewStyle;
  isLive?: boolean;
  isChannelBackground?: boolean;
  onPress?: () => void;
  size?: "large" | "small";
  isLocked?: boolean;
  loading?: "lazy" | "eager";
  alt?: string;
  streamChannel?: string | undefined;
};

const VideoPreviewIcon: React.FC<Props> = ({
  thumbnailUrl,
  style,
  imageStyle,
  isLive,
  onPress,
  isChannelBackground,
  size = "large",
  loading = "lazy",
  alt = undefined,
  streamChannel,
  ...rest
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const resolution = size === "large" || isChannelBackground ? 1000 : 300;
  const toast = useToast();

  const flashLockedHelp = () => {
    toastSuccess(
      toast,
      "Please visit racingtv.com/join to see our subscription packages"
    );
  };

  const handlePress = () => {
    if (onPress) {
      onPress();
    }
  };

  return (
    <Pressable
      style={[
        styles.container,
        !isChannelBackground ? { height: "100%" } : {}, // need space for 'on now' text
        style,
      ]}
      onPress={handlePress}
      {...rest}
    >
      {thumbnailUrl ? (
        <RImage
          style={{ ...styles.image, ...imageStyle }}
          source={thumbnailUrl}
          defaultSource={DEFAULT_REPLAY_IMAGE}
          resolution={resolution}
          loading={loading}
          alt={alt}
        />
      ) : null}
      {isLive ? (
        <View style={[styles.icon, isHovered && styles.iconHovered]}>
          <LiveButton onPress={handlePress} />
        </View>
      ) : (
        <Icon
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={[styles.icon, isHovered && styles.iconHovered]}
          name="play"
          size="xlarge"
          fill="palette.neutral.x0"
        />
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    aspectRatio: 16 / 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",

    borderRadius: 5,
    objectFit: "cover",
  },
  imageColor: {
    backgroundColor: neutral.x500,
  },
  icon: {
    position: "absolute",
  },
  iconHovered: {
    transform: [{ scale: 1.2 }],
  },
});

export default React.memo(VideoPreviewIcon);
