import React from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import variables from "../../styles/variables";
import { RTextSize } from "../RText/RText";
import ShowMore from "./ShowMore";
import { LoadingState } from "../States/states";
import RHeading from "../RHeading/RHeading";
import usePressableProps from "../../hooks/usePressableProps";

type SectionHeaderProps = {
  heading: string;
  showMore?: {
    navigateTo: string;
    text?: string;
    queryParams?: any;
  };
  contentLoading?;
  colorTheme?: "primary" | "secondary" | "tertiary";
  containerStyle?: ViewStyle;
  size?: RTextSize;
};

const { spacing } = variables;

const SectionHeader: React.FC<SectionHeaderProps> = ({
  heading,
  showMore,
  contentLoading,
  colorTheme = "primary",
  containerStyle,
  size,
}) => {
  // show loading state if page content is loading
  if (contentLoading) return <LoadingState name="SectionHeaderLoader" />;

  // no error state is required for section header
  // don't display section header if content has loaded and no heading is provided
  if (!contentLoading && !heading) return null;

  // switch case
  const color = () => {
    switch (colorTheme) {
      case "primary":
        return "secondary";
      case "secondary":
        return "primary";
      case "tertiary":
        return "tertiary";
    }
  };

  const pressableProps = showMore
    ? usePressableProps({
        screen: showMore?.navigateTo,
        params: showMore?.queryParams,
      })
    : {};

  // show content if page content has loaded and heading is provided
  return (
    <View style={[styles.section, { ...containerStyle }]}>
      <Pressable
        // For web, use the onPress from linkProps
        {...pressableProps}
      >
        <RHeading
          style={{ marginBottom: 0 }}
          level="2"
          color={color()}
          uppercase
          boldText
        >
          {heading}
        </RHeading>
      </Pressable>
      {showMore ? (
        <ShowMore
          navigateTo={showMore.navigateTo}
          text={showMore.text}
          queryParams={showMore.queryParams}
          dark={colorTheme === "tertiary"}
          size={size}
          style={{ marginTop: -8 }}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginVertical: spacing.xsmall,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
});

export default SectionHeader;
