import React from "react";
import { View } from "react-native";
import Loader from "./Utils/";
import { Platform } from "react-native";

type Props = {
  width?: string | number;
  radius?: number;
  style?: {};
};

const ContentImagePreviewLoader: React.FC<Props> = ({
  width = "100%",
  radius = 5,
  style,
}) => {
  return (
    <View
      style={[
        style,
        {
          width: width,
          aspectRatio: 16 / 9,
          borderRadius: radius,
        },
      ]}
    >
      <Loader.ContentLoader
        accessibilityLabel="loading-content"
        speed={2}
        backgroundColor="#e8e8eb"
        foregroundColor="#aeaeb2"
        style={{ aspectRatio: 16 / 9 }}
      >
        {Platform.OS === "web" ? (
          <rect rx={radius} ry={radius} width="100%" height="100%" />
        ) : (
          <Loader.Rect rx={radius} ry={radius} width="100%" height="100%" />
        )}
      </Loader.ContentLoader>
    </View>
  );
};

export default ContentImagePreviewLoader;
