export class Aborter {
  private currentAbortController: AbortController | null = null;

  /**
   * Aborts the ongoing request, if any.
   */
  abortOngoingRequest() {
    if (this.currentAbortController) {
      console.log(`[Aborter] Aborting ongoing request.`);
      this.currentAbortController.abort();
      this.currentAbortController = null;
    }
  }

  /**
   * Creates a new AbortController instance, aborts the existing one if active.
   * @returns {AbortSignal} The signal of the new AbortController.
   */
  getNewSignal(): AbortSignal {
    this.abortOngoingRequest();
    this.currentAbortController = new AbortController();
    console.log(`[Aborter] Created new AbortController.`);
    return this.currentAbortController.signal;
  }
}
