import React, { useState, useEffect } from "react";
import { View } from "react-native";
import RText from "../../RText/RText";
import { fetchGet } from "../../../client";
import { APIResponse } from "../../../types/responses/APIResponse";
import RaceRow from "../../Racecard/RaceRow";
import Race from "./../../../types/races/race";
import Track from "./../../../types/races/race";
import RHeading from "../../RHeading/RHeading";
import { cleanSlug } from "../../../utils/cleanSlug";
import RacecardRowLoader from "../../States/Loading/Loaders/Races/RacecardRowLoader";
import ReplayAsset from "./ReplayAsset";
import { EmptyState } from "../../States/states";

const RaceAttachment = ({ id, options }) => {
  const [track, setTrack] = useState<Track>();
  const [race, setRace] = useState<Race>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: APIResponse<any> = await fetchGet(
          `/racing/racecards/${id}/attachment`
        );
        setTrack(res.race.track);
        setRace(res.race);
      } catch (err) {
        setError("Error loading race: " + err?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <RacecardRowLoader />;

  if (!track || !race || error) return;

  return (
    <View style={{ minWidth: "100%", maxWidth: "100%" }}>
      <View style={{ marginBottom: 5 }}>
        <RHeading level="3" capitalize>
          {cleanSlug(track.slug)}
        </RHeading>
      </View>

      {options?.widget === "summary" && <RaceRow track={track} race={race} />}

      {options?.widget === "video" ? (
        race?.replay_videos?.length > 0 ? (
          <ReplayAsset race={race} />
        ) : (
          <EmptyState message="video unavailable" />
        )
      ) : null}
    </View>
  );
};

export default RaceAttachment;
