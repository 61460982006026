import { Platform, View } from "react-native";
import React from "react";
import RLinkButton from "../../RLink/RLinkButton";
import { logEvent } from "../../../client/analytics/trackPageView";
import variables from "../../../styles/variables";

type Props = {
  text: string;
  link: string;
  backgroundColor: string;
  textColor: string;
  deviceTypes?: Array<string> | null;
};

const CustomHeaderButton = ({
  text,
  link,
  backgroundColor = variables.colors.palette.rtv.secondary,
  textColor = variables.colors.palette.rtv.white,
  deviceTypes,
}: Props) => {
  if (deviceTypes && !deviceTypes.includes(Platform.OS)) return null;
  if (!link || !text) return null;

  return (
    <View style={{ marginRight: 5 }}>
      <RLinkButton
        slug={link} // assumes link is a slug currently
        text={text}
        backgroundColor={backgroundColor}
        textColor={textColor}
        onPressCallback={() => {
          logEvent({
            key: `custom-header-${text?.split(" ")?.join("-")?.toLowerCase()}`,
            data: {},
          });
        }}
      />
    </View>
  );
};

export default CustomHeaderButton;
