import Storage from "react-native-storage";
import AsyncStorage from "@react-native-async-storage/async-storage";

// !important. Please add a small description to each key you add here.
// The key cannot contain an underscore. It will throw an error.

export const STORAGE_KEYS = {
  TIP_FILTER: "TIP-FILTER", // currently selected tip filters on tips page
  JWT_TOKEN: "JWT-TOKEN", // jwt token
  USER_DETAILS: "USER-DETAILS", // user details
  SEEN_ADVERTS: "SEEN-ADVERTS", // adverts that have been seen
  SEEN_OVERLAY_ADVERTS: "SEEN-OVERLAY-ADVERTS", // adverts that have been seen
  FIREBASE_TOKEN: "FIREBASE-TOKEN", // firebase token
  UNREAD_COUNT: "UNREAD-COUNT", // notifications
  SEEN_TAKEOVER_ADVERTS: "SEEN-TAKEOVER-ADVERTS", // adverts that have been seen
  TRACKED_HORSES: "TRACKED-HORSES", // tracked horses
  SEEN_FULL_RESULTS_TUTORIAL: "SEEN-FULL-RESULTS-TUTORIAL", // full results tutorial seen
};

export const storage = new Storage({
  // maximum capacity, default 1000 key-ids
  size: 1000,

  // Use AsyncStorage for RN apps, or window.localStorage for web apps.
  // If storageBackend is not set, data will be lost after reload.
  storageBackend: AsyncStorage, // for web: window.localStorage

  // expire time, default: 1 day (1000 * 3600 * 24 milliseconds).
  // can be null, which means never expire.
  defaultExpires: null, //1000 * 3600 * 24,

  // cache data in the memory. default is true.
  enableCache: true,

  // if data was not found in storage or expired data was found,
  // the corresponding sync method will be invoked returning
  // the latest data.
  sync: {
    // we'll talk about the details later.
  },
});
