import { View } from "react-native";
import { RaceType, Tote, ToteType } from "../types/resultedRaces";
import RText from "../components/RText";
import { RTextSize } from "../components/RText/RText";
import { OddsFilterOption, RunnerSortOption } from "../components/Tabs/Sorter";

export const getRaceType = (raceTypes: RaceType[]) => {
  const uniqRaceTypes: RaceType[] = raceTypes
    .reduce((prev, current) => {
      return prev.includes(current) ? prev : [...prev, current];
    }, [])
    .filter(Boolean);

  // if no race types (or all null), don't show a race type.
  if (!uniqRaceTypes.length) return null;

  // order below is important!

  // only combo of chase, hurdle, bumper, (which are all jump)
  if (uniqRaceTypes.length < 4 && !uniqRaceTypes.includes("Flat")) {
    return "Jumps";
  }

  if (uniqRaceTypes.length > 1) {
    return "Mixed";
  }

  if (uniqRaceTypes[0] === "Flat") {
    return "Flat";
  }

  return null;
};

export const formatTote = (totes: Tote[], type: ToteType) => {
  const filteredTotes = totes.filter((t) => t.tote_type === type);

  if (!filteredTotes.length) return "-";

  return filteredTotes
    .map((tote) => tote.dividend.formatted?.split(" ")[0])
    .join(", ")
    .replace(/\p{Sc}/gu, "");
};

const equipmentMap = {
  "0": "p",
  "1": "1",
  "2": "2",
  "+": "tp",
  B: "b",
  b: "tb",
  D: "eb",
  H: "h",
  h: "ht",
  V: "v",
  v: "tv",
  w: "w",
  z: "t",
};

export const formatEquipmentText = (text: string, size: RTextSize = "sm") => {
  if (!text) return null;
  const cleanedText = text.replace(/<[^>]+>/g, "");
  const splitText = cleanedText.split("");
  if (splitText.length > 1) {
    return (
      <View style={{ flexDirection: "row" }}>
        <RText size={size}>{equipmentMap[splitText[0]] || ""}</RText>
        <RText size="xs">{equipmentMap[splitText[1]] || ""}</RText>
      </View>
    );
  }
  return <RText size={size}>{equipmentMap[cleanedText] || ""} </RText>;
};

export const formatCountryCode = (code: string) => {
  const lowerCaseCode = code.toLowerCase();
  if (lowerCaseCode === "fra") return "FR";
  if (lowerCaseCode === "nzl") return "NZ";
  if (lowerCaseCode === "gbr") return "";
  return code;
};

export const isNullified = (status: string): boolean =>
  status === "abandoned" || status === "void";

export const sortOptions: RunnerSortOption[] = [
  { text: "Price", value: "price" },
  { text: "Number", value: "cloth_number" },
  { text: "TF Rating", value: "tf_rating" },
  { text: "Official Rating", value: "official_rating" },
];
