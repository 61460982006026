import React, { forwardRef } from "react";
import {
  Linking,
  Platform,
  StyleSheet,
  Pressable,
  View,
  LayoutChangeEvent,
} from "react-native";
import RText, {
  RTextColors,
  RTextFont,
  RTextProps,
  RTextSize,
  RTextWeight,
} from "../RText/RText";
import { useResponsive } from "../../hooks/useResponsive";
import { useNavigation } from "@react-navigation/native";
import { nativeNavFromSlug } from "../Promotion/Utils";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { isInternalLink } from "../Navigation/URLMapper";
import usePressableProps from "../../hooks/usePressableProps";
import { cleanSlug } from "../../utils/cleanSlug";

// This handles what you'd consider a standard web link, on all platforms.
//
// The key complication is that links must produce an <a> tag on web for SEO.
// On native, thats not important, but we must handle external links and internal
// links differently, i.e external links leave the app, whilst internal links must
// open the respective page.
//
// Other types of things can link, for instance a component like 'card', image or button.
//   For a component like card, see RTouchableOpacity which utilizes usePressableProps.
//   For a component like a button, see RLinkButton.

// Examples:
//
// Internal link:
//  <RLink to={{ screen: "Racecards", params: { date: "2024-2-14" }}}>Valentines Racecards</RLink>
//     ...or...
//  <RLink slug="/racecards">Racecards</RLink>
//
// External link (This is detected automatically, see isInternalLink):
//  <RLink href="https://www.example.com/home">Home</RLink>
//
// CMS link:
// <RLink href="www.racingtv.com/racecards">Racecards</RLink>
//
// Link to email:
//  <RLink href="mailto:test@mail.com" type="email">Email</RLink>
//
// Link to phone:
//  <RLink href="tel:123456789" type="phone">Phone</RLink>

type Props = {
  children: any; // Content inside RLink

  // Link object, can be a URL, email, phone, or slug
  href?: string; // Full URL for the link, i.e https://www.example.com/home or https://racingtv.com/racecards
  type?: "href" | "email" | "phone"; // Type of link
  slug?: string; // Slug for internal navigation
  to?: To<ReactNavigation.RootParamList>; // Navigation destination for 'to' type
  shouldOpenInNewTab?: boolean; // Open link in new tab

  // Font props
  weight?: RTextWeight; // Font weight
  style?: any; // Custom style
  size?: RTextSize; // Font size
  color?: RTextColors; // Text color
  family?: RTextFont; // Font family
  dynamicTextStyle?: RTextProps["dynamicTextStyle"]; // Styles for dynamic states
  uppercase?: boolean; // Uppercase styling

  // Pressable props
  pressableStyle?: object; // Style for the Pressable component
  pressableOnLayout?: (event: LayoutChangeEvent) => void; // Layout event for Pressable
  onPressCallback?: () => void; // Callback for onPress event
  rest?: any;
};

export const RLink = forwardRef(
  (
    {
      children,
      href,
      type = "href",
      weight = "semiBold",
      style,
      size,
      color = "secondary",
      family = "primary",
      dynamicTextStyle = { onHover: "underline" },
      uppercase = false,
      pressableStyle,
      pressableOnLayout,
      onPressCallback,
      slug,
      to = { screen: undefined, params: undefined },
      shouldOpenInNewTab = false,
      ...rest
    }: Props,
    ref
  ) => {
    const pressableProps = usePressableProps({
      ...to,
      openInNewTab: shouldOpenInNewTab,
    });
    const navigation = useNavigation();
    const { isMediumAndDown } = useResponsive();

    // Function to handle link opening for ("href", "email", "phone", "slug") types.
    const openLink = () => {
      const linkText =
        typeof children === "string" ? children : children?.props?.children;
      const linkType = detectLinkType(linkText, type);
      const finalUrl = `${linkType}${href || linkText}`;

      // Handles slug and internal link types, navigating internally or opening externally as needed.
      if (slug) {
        const { screen, paramsObj } = nativeNavFromSlug(slug);
        return navigation.navigate(screen, paramsObj);
      } else {
        try {
          // attempt to navigate to the screen in native
          let { screen, paramsObj } = nativeNavFromSlug(finalUrl);
          return navigation.navigate(screen, paramsObj);
        } catch (error) {
          // if the above fails, open the link in the browser
          const url = href || `${finalUrl}`.replaceAll(" ", "");
          return Linking.canOpenURL(url)
            .then((supported) => supported && Linking.openURL(url))
            .catch((error) =>
              console.error(`An error occurred opening ${url} `, error)
            );
        }
      }
    };

    // Renders the content within the Pressable component.
    const Content = () => (
      <RText
        family={family}
        color={color}
        weight={weight}
        style={style ? style : [style, styles.textLink]}
        size={size ? size : isMediumAndDown ? "sm" : "md"}
        dynamicTextStyle={dynamicTextStyle}
        uppercase={uppercase}
      >
        {children}
      </RText>
    );

    // All web links go down this path
    if (Platform.OS === "web" && type != "phone") {
      if (slug) href = `${window.location.origin}/${cleanSlug(slug)}`;

      const target = () => {
        if (shouldOpenInNewTab) return "_blank";
        if (!isInternalLink(href || "") && to.screen === undefined)
          return "_blank";

        return "_self";
      };

      return (
        <View
          style={pressableStyle}
          onLayout={pressableOnLayout}
          ref={ref}
          {...rest}
        >
          <a
            href={href?.length ? href : pressableProps.href}
            target={target()}
            style={{ textDecoration: "none" }}
            onClick={() => onPressCallback && onPressCallback()}
          >
            <Content />
          </a>
        </View>
      );
    }

    // all native links that pass a to object go down this path
    if (to.screen) {
      return (
        <Pressable
          ref={ref}
          style={pressableStyle}
          onLayout={pressableOnLayout}
          {...pressableProps}
        >
          <Content />
        </Pressable>
      );
    }

    // Remaining links on native, such as href, email, phone and slug
    return (
      <Pressable
        onPress={() => {
          onPressCallback && onPressCallback();
          openLink();
        }}
        style={({ pressed }) => [
          pressed ? { opacity: 0.5 } : {}, // Apply lower opacity when pressed
          pressableStyle, // Apply any custom styles passed via props
        ]}
        onLayout={pressableOnLayout}
      >
        <Content />
      </Pressable>
    );
  }
);

// Extracts the slug from a URL.
const extractSlug = (url: string) => {
  // Normalizes URLs by ensuring they start with a protocol.
  const normalizedUrl = url.match(/^https?:\/\//) ? url : `https://${url}`;
  // Removes the protocol and domain, leaving the path and query string.
  const slugWithPossibleQueryString = normalizedUrl.replace(
    /^https?:\/\/[^\/]+/,
    ""
  );
  // Removes the query string
  const slug = slugWithPossibleQueryString.split("?")[0];
  // Removes the leading slash from the slug.
  return slug.startsWith("/") ? slug.slice(1) : slug;
};

// Determines the type of link based on its content and specified type.
const detectLinkType = (text, type) => {
  if (!text) return type;
  // Regex patterns for detecting email, phone, and URL.
  const emailRegex = /@.+\./; // Matches email addresses.
  const phoneRegex = /^\+?\d+(\s\d+)*$/; // Matches phone numbers.
  const urlRegex = /(http|https):\/\/[^\s]+/; // Matches URLs.
  // Returns the appropriate prefix based on the match.
  if (emailRegex.test(text)) {
    return "mailto:";
  } else if (phoneRegex.test(text)) {
    return "tel:";
  } else if (urlRegex.test(text)) {
    return "";
  }
  return "";
};

const styles = StyleSheet.create({
  textLink: {
    ...Platform.select({
      web: {},
      ios: {
        marginBottom: -3,
      },
      android: {
        marginBottom: -4,
      },
    }),
  },
});
