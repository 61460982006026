import React from "react";
import { Platform } from "react-native";

export const useClosePopup = (ref, closePopup) => {
  if (Platform.OS !== "web") return;
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closePopup();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
