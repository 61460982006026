import { Animated, Platform, StyleSheet, Text, View } from "react-native";
import React, { useEffect } from "react";
import Icon from "../Icon";

type Props = {};

const ScrollDownIcon = ({}: Props) => {
  const bounceValue = new Animated.Value(0.8);

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(bounceValue, {
          toValue: 5,
          duration: 500,
          useNativeDriver: Platform.OS != "web",
        }),
        Animated.timing(bounceValue, {
          toValue: 0,
          duration: 500,
          useNativeDriver: Platform.OS != "web",
        }),
      ])
    ).start();
  }, []);

  return (
    <View
      style={{
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        paddingRight: 10,
        bottom: 0,
      }}
    >
      <Animated.View
        style={{
          height: 30,
          width: 30,
          transform: [{ translateY: bounceValue }],
        }}
      >
        <Icon name="scrollDOwn" />
      </Animated.View>
      <View style={{ height: 10 }} />
    </View>
  );
};

export default ScrollDownIcon;
