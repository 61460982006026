import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import variables from "../../styles/variables";
import { useClosePopup } from "../../hooks/useClosePopup";
import RText from "../RText";
import RTouchableOpacity from "../RLink/RTouchableOpacity";

interface Option {
  title: string;
  route: string;
}

interface DropdownProps {
  setValue?: Function;
  options?: Option[];
  children?: any;
  style?: any;
  closePopup?: Function;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  children,
  style,
  closePopup,
}) => {
  const navDropdownRef = React.useRef(null);
  useClosePopup(navDropdownRef, closePopup);

  const renderBottomLine = (data: Option[], name: string) => {
    if (data[data.length - 1].title === name) {
      return { borderBottomWidth: 0 };
    }
  };

  const renderDropdown = (options: Option[]) => {
    // create an array of refs, one for each option
    const hoverRefs = React.useRef<(HTMLDivElement | null)[]>([]);

    return options.map((option: Option, i: number) => {
      // ensure there is a ref for each option
      if (!hoverRefs.current[i]) {
        hoverRefs.current[i] = null;
      }

      return (
        <RTouchableOpacity
          ref={(el) => (hoverRefs.current[i] = el)}
          navScreen={option.route}
          navParams={option?.params || {}}
          style={[
            styles.optionsContainer,
            renderBottomLine(options, option.title),
          ]}
          key={`options${i}`}
          onHoverIn={() => {
            if (hoverRefs.current[i] && Platform.OS === "web") {
              hoverRefs.current[i].children[0].style.color = "#d9e1e8";
            }
          }}
          onHoverOut={() => {
            if (hoverRefs.current[i] && Platform.OS === "web") {
              hoverRefs.current[i].children[0].style.color =
                variables.colors.palette.rtv.primary;
            }
          }}
        >
          <RText family="secondary" weight="bold" style={[text.text]} uppercase>
            {option?.title}
          </RText>
        </RTouchableOpacity>
      );
    });
  };
  return options || children ? (
    <View style={[style]} ref={navDropdownRef}>
      <View style={styles.dropdownContainer}>
        {children ?? renderDropdown(options)}
      </View>
    </View>
  ) : null;
};

const text = StyleSheet.create({
  text: {
    fontWeight: "700",
  },
});

const styles = StyleSheet.create({
  dropdownContainer: {
    width: 250,
    borderRadius: 4,
    borderColor: variables.colors.palette.buttons.disabled.borderColor,
    backgroundColor: "white",
    paddingHorizontal: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 5,
  },
  optionsContainer: {
    display: "flex",
    justifyContent: "center",
    height: 50,
    borderBottomWidth: 0.5,
    borderBottomColor: variables.colors.palette.buttons.disabled.borderColor,
  },
});

export default Dropdown;
