// This component is used to animate the dropdown menu
// Props:
//  - children: React.ReactNode
//  - open?: boolean
//  - style?: ViewStyle
//  - startPoint?: number - this defines where the start of the dropdown occurs. It
//                          is usually half of the child height, but is different on web/mobile.
//                          I don't think its necessary for absolutely positioned children but may be wrong
//                          infact we need to calculate this dynamically. TODO
// Usage:
//  <AnimatedDropdownWrapper style={{ height: 0 }} open={open}>
//    {sampleData}
//  </AnimatedDropdownWrapper>
//
//  where sampleData is an array of React.ReactNode
//  The component will animate the height of the dropdown menu based on the number of children passed in

import React from "react";
import { View, ViewStyle, StyleSheet } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  useDerivedValue,
  withTiming,
} from "react-native-reanimated";

interface Props {
  children: React.ReactNode;
  open?: boolean;
  style?: ViewStyle[];
  duration?: number;
  isFaqList?: boolean;
}

const AnimatedDropdownWrapper = ({
  children,
  open = false,
  style = [{}],
  duration = 150,
  isFaqList = false,
}: Props) => {
  const height = useSharedValue(0);

  const animatedHeight = useDerivedValue(() => {
    return withTiming(open ? height.value : 0, { duration });
  });

  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: animatedHeight.value,
    };
  });

  return isFaqList ? (
    <View style={{ display: open ? "flex" : "none" }}>
      <Animated.View style={[styles.animatedView, animatedStyle]}>
        <View
          onLayout={(e) => {
            height.value = e.nativeEvent.layout.height;
          }}
        >
          <View style={style}>{children}</View>
        </View>
      </Animated.View>
    </View>
  ) : (
    <Animated.View style={[styles.animatedView, animatedStyle]}>
      <View
        onLayout={(e) => {
          height.value = e.nativeEvent.layout.height;
        }}
      >
        <View style={style}>{children}</View>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  animatedView: {
    width: "100%",
    overflow: "hidden",
    // flex: 1,
  },
});

export default AnimatedDropdownWrapper;
