// This component is used to animate sliding in a view from the bottom or side
// Props:
//  - trigger: boolean
//  - children: React.ReactNode
//  - duration?: number
//  - direction?: "horizontal" | "vertical"
//  - style?: ViewStyle | ViewStyle[]
// Usage:
//  <SlideInAnimation trigger={open} direction="vertical" duration={200}>
//    {sampleData}
//  </SlideInAnimation>
//
//  where sampleData is a React.ReactNode

import {
  Animated,
  Platform,
  useWindowDimensions,
  ViewStyle,
} from "react-native";
import React, { useEffect, useRef, forwardRef } from "react";

type Props = {
  trigger: boolean;
  children: React.ReactNode;
  duration?: number;
  direction?: "horizontal" | "vertical";
  style?: ViewStyle | ViewStyle[];
};

const SlideInAnimation = (
  { trigger, duration = 200, direction = "vertical", children, style }: Props,
  ref
) => {
  const { height, width } = useWindowDimensions();

  const animatedValue = useRef(new Animated.Value(0)).current;

  const isHorizontal = direction === "horizontal";

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: trigger ? 0 : isHorizontal ? width : height,
      duration: duration,
      useNativeDriver: Platform.OS !== "web",
    }).start();
  }, [trigger]);

  const styles: any = Array.isArray(style) ? style : { ...style }; // accept styles as array or object

  return (
    trigger && (
      <Animated.View
        ref={ref}
        style={{
          styles,
          transform: [
            { [isHorizontal ? "translateX" : "translateY"]: animatedValue },
          ],
        }}
      >
        {children}
      </Animated.View>
    )
  );
};

export default forwardRef(SlideInAnimation);
