import { fetchGet, fetchPost } from "..";
import { Search } from "../../components/Header/components/SearchClient";
import { Meta, Pagination } from "../../types/responses/APIResponse";
import {
  PreferencesNotificationMethods,
  PreferencesNotificationTypes,
  PreferencesMember,
} from "../../types/preferences";
import {
  CountryCode,
  HorseTrackerType,
  RunnersWithOdds,
  TrackedEntry,
  TrackedHorse,
} from "../../types/races/horse";
import { FinishStateChar } from "../../types/finishState";
import { CacheManager } from "../cache/cache-manager";
import { ONE_DAY_IN_SECONDS } from "../../utils/TimeCalculator";

// GET - Tracker Horses

type TrackedHorsesResponse = {
  meta?: Meta;
  horses: HorseTrackerType[];
};

type PaginatedTrackedHorsesResponse = TrackedHorsesResponse & {
  pagination: Pagination;
};

type TrackedHorses = {
  horses: HorseTrackerType[];
  pagination: Pagination;
};

export const getTrackedHorses = async (
  force: boolean = false
): Promise<HorseTrackerType[]> => {
  if (!force) {
    const cachedHorses = (await CacheManager.getItem(
      "TRACKED_HORSES"
    )) as TrackedHorses;

    // Check if cache is up to date with server
    const synced = await isCacheSynced(cachedHorses);
    console.log("[Tracker] NO NEED TO SYNC ", synced);
    if (synced) return cachedHorses.horses;
  }

  // Not synced - lets update our local copy
  const res = await getTrackedHorsesFromAPI({ page: 1 });
  const nPages = res.pagination.pages;

  // Parrallel fetch all tracked horse pages
  const pagePromises = Array.from({ length: nPages }, (_, i) =>
    getTrackedHorsesFromAPI({ page: i + 1 })
  );

  // Resolve all promises in parallel and collect results
  const allResults = await Promise.all(pagePromises);

  // Combine all the horses from the resolved results
  const trackedHorses: HorseTrackerType[] = allResults.flatMap((h) => h.horses);

  // Update cache with the new horses
  await CacheManager.setItem(
    "TRACKED_HORSES",
    {
      horses: trackedHorses,
      pagination: allResults?.[0]?.pagination,
    },
    ONE_DAY_IN_SECONDS
  );

  return trackedHorses;
};

const isCacheSynced = async (cachedHorses: TrackedHorses): Promise<Boolean> => {
  const res = await getTrackedHorsesFromAPI({ page: 1, limit: 1 });

  const isSynced = cachedHorses
    ? cachedHorses.horses.length === res.pagination.total
    : false;

  console.log(
    `[Tracker] isCacheSynced? ${isSynced}, cached: ${cachedHorses?.horses?.length} vs on server: ${res.pagination.total}`
  );
  return isSynced;
};

// this can return all horses, per page or just the correct number

// ** PRIVATE ** DO NOT CALL OUTSIDE OF THIS FILE
// prettier-ignore
const getTrackedHorsesFromAPI = async ({ page, limit = 25 }: { page: number; limit?: number }): Promise<PaginatedTrackedHorsesResponse> => {

  console.log(
    `[Tracker]::getTrackedHorsesFromAPI: Fetching page ${page} tracked horses`
  );

  try {
    const query = {
      "pagination[page]": page,
      "pagination[limit]": limit
    };
    const res = (await fetchGet("member/tracker/horses", query)) as PaginatedTrackedHorsesResponse;

    if (res.meta.status !== 200)
      throw new Error("Failed to fetch tracked horses");

    return { horses: res.horses, pagination: res.pagination };

  } catch (error) {
    console.error("[getTrackedHorsesFromAPI]::Error", error);
  }
};

// Tell server about a new/updated tracked horse. This will update the local cache without
// having to refetch the entire list of tracked horses (heavy operation)
export const updateTrackedHorse = async (
  id: number,
  comment?: string,
  notifications?: boolean
) => {
  const update = {
    horse: {
      comment,
      notifications,
    },
  };

  // prettier-ignore
  const res = await fetchPost(`member/tracker/horses/${id}`, {}, update, "PUT");

  if (res.meta.status !== 200) {
    throw new Error(`Horse ${id} was not updated`);
  }

  const cachedHorses = await CacheManager.getItem<TrackedHorses>(
    "TRACKED_HORSES"
  );

  if (cachedHorses === null) return;

  // Check if horse id exist in cache using Set
  const setOfHorses = new Set(cachedHorses.horses.map((h) => h.id));
  if (!setOfHorses.has(id)) {
    // Horse not found in cache. Fetch and update cache
    getTrackedHorses(true);
  } else {
    // Update local cache copy with new comment and notification setting
    const updatedHorses = cachedHorses.horses.map((h) => {
      if (h.id === id) {
        h.tracked_horse.comment = comment;
        h.tracked_horse.notifications = notifications;
      }
      return h;
    });

    console.log(`[Tracker] writing ${updatedHorses.length} horses to cache`);
    await CacheManager.setItem(
      "TRACKED_HORSES",
      { horses: updatedHorses, pagination: cachedHorses.pagination },
      ONE_DAY_IN_SECONDS
    );
  }
  return res;
};

// GET - Tracker Entries

type TrackedEntriesResponse = {
  meta: Meta;
  pagination: Pagination;
  runners: TrackedEntry[];
};

export const getTrackedEntries = async (
  page: number
): Promise<TrackedEntriesResponse> => {
  const resp = await fetchGet(`member/tracker/upcoming`, {
    "pagination[page]": page,
    "pagination[limit]": 50,
  });
  return resp;
};

// UPDATE - Tracked Horse

type TrackedHorseResponse = {
  meta: Meta;
  tracked_horse: TrackedHorse;
};

// DELETE - Tracked Horse

export const deleteTrackedHorse = async (
  id: number
): Promise<TrackedHorseResponse> =>
  await fetchPost(`member/tracker/horses/${id}`, {}, {}, "DELETE");

// GET - Search Horses

export type HorseHit = {
  name: string;
  slug: string;
  country_code: CountryCode;
  last_run: {
    date?: string;
    finish_position: number;
    finish_text: FinishStateChar;
    runner_count: number;
  };
};

export type HorseSearchHit = {
  id: number;
  score: number;
  type: string;
  horse: HorseHit;
};

type HorseSearchResponse = {
  hits: HorseSearchHit[];
  search: Search;
  meta: Meta;
  pagination: Pagination;
};

export const getSearchHorseResults = async (
  query: string
): Promise<HorseSearchResponse> =>
  await fetchGet("member/tracker/horses/search", { query });

//  GET - Tracker Preferences

export type TrackerPreferencesResponse = {
  meta: Meta;
  member: PreferencesMember;
  notification_methods_available: PreferencesNotificationMethods;
  notification_types_available: PreferencesNotificationTypes;
};

export const getTrackerPreferences =
  async (): Promise<TrackerPreferencesResponse> =>
    await fetchGet("member/preferences");

export type UpdatedPreferences = {
  preference: {
    member: PreferencesMember;
  };
};

export const updateTrackerPreferences = async (
  preferences: UpdatedPreferences
) => await fetchPost("member/preferences", {}, preferences, "PUT");

type TrackedEntryOddsResponse = {
  meta: Meta;
  runners: RunnersWithOdds[];
};

export const getTrackerEntryOdds = async (
  ids: number[]
): Promise<TrackedEntryOddsResponse> => {
  const runnerIds = ids.map((id) => `runner_ids[]=${id}`).join("&");
  return await fetchGet(`racing/runner/odds?${runnerIds}`);
};
