import React, { useState } from "react";
import { Platform, Pressable, StyleSheet, View, ViewStyle } from "react-native";
import Icon from "../Icon";
import RotateAnimation from "../Animations/RotateAnimation";
import variables from "../../styles/variables";
import AnimatedDropdownWrapper from "../Animations/DropdownAnimation";
import RHeading from "../RHeading/RHeading";
import { useResponsive } from "../../hooks/useResponsive";
import lazyImport from "../../utils/lazyImport/lazyImport";
import RSuspense from "../../utils/RSuspense/RSuspense";

const CmsContent = lazyImport(() => import("../cms/CmsContent"));

const { colors, spacing } = variables;

type Props = {
  text: string;
  content: React.ReactNode;
  openOnMount?: boolean;
  boldText?: boolean;
  style?: ViewStyle;
  hideHeader?: boolean;
};

const RAccordion = ({
  text,
  content,
  openOnMount = false,
  boldText = false,
  style,
  hideHeader = false,
}: Props) => {
  const [open, setOpen] = useState(openOnMount);
  const { isMediumAndDown } = useResponsive();

  return (
    <React.Fragment key={`raccordian-${text}`}>
      {!hideHeader ? (
        <Pressable
          onPress={() => setOpen((prev) => !prev)}
          style={styles.header}
        >
          <View style={Platform.OS === "android" && { width: "97%" }}>
            <RHeading boldText level="3" uppercase>
              {text}
            </RHeading>
          </View>
          <RotateAnimation trigger={open} startDegrees={180}>
            <Icon name="chevronBoldUp" size="small" fill="palette.neutral.x0" />
          </RotateAnimation>
        </Pressable>
      ) : null}

      <AnimatedDropdownWrapper
        open={open}
        style={[
          isMediumAndDown ? styles.animatedWrapperThin : styles.animatedWrapper,
          style,
        ]}
        isFaqList={typeof content === "string"}
      >
        {typeof content === "string" ? (
          <RSuspense>
            <CmsContent content={content} allContent={false} />
          </RSuspense>
        ) : (
          content
        )}
      </AnimatedDropdownWrapper>
    </React.Fragment>
  );
};

export default RAccordion;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "100%",
    maxWidth: "100%",
    paddingHorizontal: spacing.xsmall,
    paddingVertical: spacing.xsmall,
    backgroundColor: colors.palette.neutral.x100,
    borderBottomWidth: 4,
    borderBottomColor: "white",
  },
  headerMargin: {
    marginBottom: 5,
  },
  animatedWrapper: {
    paddingVertical: spacing.xsmall,
    paddingHorizontal: spacing.xsmall,
  },
  animatedWrapperThin: {
    paddingVertical: spacing.xsmall,
    paddingHorizontal: spacing.xsmall,
  },
});
