import { FC, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import RText from "../../../RText/RText";
import variables from "../../../../styles/variables";
import RTextInput from "../../Account/components/RTextInput";
import { useAtom } from "jotai";
import { openAuthModalAtom } from "../../../../atoms";
import { useToast } from "react-native-toast-notifications";
import { TextLoader } from "../../../States/Loading/Loaders";
import useUser from "../../../../hooks/useUser";

import { HorseTrackerType } from "../../../../types/races/horse";
import TrackerButton from "./TrackerButton";
import Gap from "../../../Gap";
import { handleDeleteTrackedHorse, handleUpdateTrackedHorse } from "../utils";
import LoadingSpinner from "../../../LoadingSpinner";

type TrackedHorseCommentProps = {
  horseId: number;
  horseName: string;
  isExpanded: boolean;
  withControls?: boolean;
  trackedHorse?: HorseTrackerType | undefined;
  refreshListOfTrackedHorses?: Function;
};

export const TrackedHorseComment: FC<TrackedHorseCommentProps> = ({
  horseId,
  horseName,
  isExpanded = false,
  withControls = true,
  trackedHorse = undefined,
  refreshListOfTrackedHorses = () => {},
}) => {
  const toast = useToast();
  const [, setAuthModalOpen] = useAtom(openAuthModalAtom);
  const [newComment, setNewComment] = useState(
    trackedHorse?.tracked_horse.comment || ""
  );
  const [loading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { isLoggedIn } = useUser();

  const recieveNotifications =
    trackedHorse?.tracked_horse?.notifications || false;

  useEffect(() => {
    // Reset loading to false whenever 'tracked horse' update has
    // percolated down to this component.
    setUpdating(false);
  }, [trackedHorse]);

  console.log("[TrackedHorseComment] trackedHorse now: ", trackedHorse);

  // TODO - need to pass the whole tracked horse back to parent.

  useEffect(() => {
    if (!newComment && trackedHorse?.tracked_horse?.comment) {
      setNewComment(trackedHorse?.tracked_horse?.comment);
    }
  }, [trackedHorse?.tracked_horse?.comment]);

  const handleTrackHorsePress = async (__trackedHorse) => {
    if (!isLoggedIn) {
      setAuthModalOpen("login");
      return;
    }

    setUpdating(true); // We don't unset this because it gets re-rendered via props, at which point will become false.
    if (__trackedHorse?.id) {
      const success = await handleDeleteTrackedHorse({
        id: __trackedHorse.id,
        name: __trackedHorse.name,
        toast,
      });
      if (success) {
        refreshListOfTrackedHorses();
      }
    } else {
      const {
        meta: { status },
      } = await handleUpdateTrackedHorse({
        id: horseId,
        name: horseName,
        toast,
        comment: newComment,
        notifications: true,
      });

      if (status === 200) {
        console.log(
          "[TrackedHorseComment] refreshListOfTrackedHorses: ",
          __trackedHorse
        );
        refreshListOfTrackedHorses();
      }
    }
  };

  const handleNotificationOrSavePress = async (
    pressType: "save" | "notification"
  ) => {
    if (!isLoggedIn) {
      setAuthModalOpen("login");
      return;
    }
    setUpdating(true);
    const notifications =
      pressType === "notification"
        ? !recieveNotifications
        : recieveNotifications;

    if (!!trackedHorse) {
      const {
        meta: { status },
      } = await handleUpdateTrackedHorse({
        id: horseId,
        name: horseName,
        toast,
        comment: newComment,
        notifications,
      });

      if (status === 200) {
        refreshListOfTrackedHorses();
      }
    } else {
      const {
        meta: { status },
      } = await handleUpdateTrackedHorse({
        id: horseId,
        name: horseName,
        toast,
        comment: newComment,
        notifications: true,
      });

      if (status === 200) {
        refreshListOfTrackedHorses();
      }
    }
  };

  if (loading)
    return (
      <View
        style={[
          styles.commentContainer,
          !!trackedHorse ? styles.trackedComment : styles.notTrackedComment,
          {
            height: isExpanded ? 100 : 0,
            marginTop: isExpanded ? 5 : 0,
          },
        ]}
      >
        <TextLoader height={isExpanded ? 100 : 0} />
      </View>
    );

  if (!isExpanded) return null;

  return (
    <View
      style={{
        backgroundColor: variables.colors.palette.neutral.x100,
        padding: 10,
        borderRadius: 2,
      }}
    >
      <View
        style={[
          styles.commentContainer,
          !!trackedHorse ? styles.trackedComment : styles.notTrackedComment,
        ]}
      >
        <RTextInput
          keyboardType="default"
          placeholder="Tracker comment"
          onChangeText={(e) => setNewComment(e)}
          value={newComment}
          style={styles.comment}
          onBlur={() => null}
          isSecure={false}
          multiline
        />
      </View>
      <View
        style={[
          styles.commentCtas,
          !withControls && { justifyContent: "flex-end" },
        ]}
      >
        {withControls && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "75%",
            }}
          >
            <TrackerButton
              text="Track Horse"
              iconName={
                !!trackedHorse ? "binocularsSelected" : "binocularsUnselected"
              }
              onPress={() => handleTrackHorsePress(trackedHorse)}
            />
            <Gap size="medium" />
            <TrackerButton
              text="Notifications"
              iconName={
                recieveNotifications
                  ? "notificationSelected"
                  : "notificationUnselected"
              }
              onPress={() => handleNotificationOrSavePress("notification")}
            />
          </View>
        )}
        {updating ? (
          <LoadingSpinner
            size="small"
            style={{ width: 20, height: 20, marginRight: 2, marginTop: 2 }}
          />
        ) : (
          <TouchableOpacity
            disabled={newComment == trackedHorse?.tracked_horse?.comment}
            activeOpacity={0.6}
            style={{
              marginRight: 4,
            }}
            onPress={() => handleNotificationOrSavePress("save")}
          >
            <RText
              capitalize
              color={
                newComment == trackedHorse?.tracked_horse?.comment
                  ? "grey"
                  : "secondary"
              }
              weight="bold"
            >
              save
            </RText>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const { colors } = variables;
const styles = StyleSheet.create({
  commentContainer: {
    justifyContent: "space-between",
    width: "100%",
    borderRadius: 2,
    backgroundColor: colors.palette.transparent.secondary,
  },
  comment: {
    width: "100%",
    borderWidth: 0,
  },
  trackedComment: {
    backgroundColor: colors.palette.rtv.tracker,
  },
  notTrackedComment: {
    backgroundColor: "white",
  },
  commentCtas: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: variables.spacing.xsmall,
  },
});
