import React, { FC, useMemo, useState } from "react";
import {
  View,
  StyleSheet,
  Pressable,
  TouchableOpacity,
  Animated,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useAtom } from "jotai";

import HeaderButtons from "./components/HeaderButtons";
import Search from "./components/Search";
import Icon from "../Icon";
import RTVGradient from "../Gradients/RTVGradient";
import PromotionMessage from "../Promotion/PromotionMessage";
import TopBar from "../Topbar";

import {
  cmsDataAtom,
  globalFullscreenAtom,
  headerEndYAtom,
  scrollEnabledAtom,
} from "../../atoms";
import { useResponsive } from "../../hooks/useResponsive";
import variables from "../../styles/variables";
import { DesktopMenu } from "./components/DesktopMenu";
import Gap from "../Gap";
import { RootStackParamList } from "../../types/route";
import { useSearchMarginTopHeight } from "../../hooks/useSearchMarginTopHeight";
import CustomHeaderButton from "./components/CustomHeaderButton";

export type HeaderProps = {
  navOverride?: any;
  tabBarHeightValue?: number;
};

const Header: FC<HeaderProps> = ({ navOverride, tabBarHeightValue }) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { isSmallAndDown, isMediumAndUp } = useResponsive();

  const [isGlobalFullScreen] = useAtom(globalFullscreenAtom);
  const [cmsData] = useAtom(cmsDataAtom);
  const [, setIsScrollEnabled] = useAtom(scrollEnabledAtom);
  const [, setHeaderEndY] = useAtom(headerEndYAtom);

  const [openSearch, setOpenSearch] = useState(false);
  const [searchMarginTopCalc, onLayout] = useSearchMarginTopHeight(0);

  const RenderCustomHeaderButton = useMemo(() => {
    return (
      <CustomHeaderButton
        text={cmsData?.settings?.header_button_text}
        link={cmsData?.settings?.header_button_link}
        backgroundColor={cmsData?.settings?.header_button_background_color}
        textColor={cmsData?.settings?.header_button_text_color}
        deviceTypes={cmsData?.settings?.header_button_device_types}
      />
    );
  }, [cmsData]);

  if (isGlobalFullScreen || Object.keys(cmsData).length === 0) {
    return null;
  }

  const closeSearchAndSetAppScroll = (bool: boolean) => {
    setOpenSearch(bool);
    setIsScrollEnabled(!bool);
  };

  return (
    <View nativeID="webHeader" accessibilityLabel="header">
      <RTVGradient />

      {isMediumAndUp ? (
        <View onLayout={onLayout} style={styles.container}>
          <View
            style={[
              {
                flexDirection: "row",
              },
              styles.alignStart,

              !!cmsData ? { minWidth: 350 } : null,
            ]}
          >
            <TouchableOpacity
              style={styles.logoContainer}
              onPress={() => navigation.navigate("Home")}
              accessibilityLabel="home-button"
            >
              <Gap size="xxsmall" />
              <Icon
                name={isSmallAndDown ? "smallLogo" : "longLogo"}
                style={styles.logo}
                fill="palette.neutral.x0"
              />
              <Gap size="small" />
            </TouchableOpacity>

            <View style={{ flex: 1, paddingHorizontal: 10 }}>
              {!!cmsData?.settings?.navigation_featured_item_title && (
                <PromotionMessage
                  title={cmsData?.settings?.navigation_featured_item_title}
                  slug={cmsData?.settings?.navigation_featured_item_path}
                />
              )}
            </View>
          </View>
          <View
            style={[
              styles.largeScreenContainer,
              {
                flexShrink: 1,
              },
            ]}
          >
            <DesktopMenu />
          </View>
          <View style={[styles.largeScreenButtonContainer, styles.alignEnd]}>
            {RenderCustomHeaderButton}
            <Pressable
              nativeID={"search"}
              key={"search"}
              onPress={() => closeSearchAndSetAppScroll(!openSearch)}
            >
              <Icon
                name={openSearch ? "closeCircled" : "search"}
                size="xlarge"
                style={styles?.leftMargin}
                fill="palette.neutral.x0"
              />
            </Pressable>
            <HeaderButtons />
          </View>
        </View>
      ) : (
        <View onLayout={onLayout}>
          <View style={styles.container}>
            <View
              style={[
                styles.headerSectionContainer,
                styles.alignStart,
                styles.rowFlex,
              ]}
            >
              <HeaderButtons />
            </View>

            <TouchableOpacity
              style={[styles.headerSectionContainer]}
              onPress={() => navigation.navigate("Home")}
              accessibilityLabel="home-button"
            >
              <Icon
                name="smallLogo"
                fill="palette.neutral.x0"
                style={styles.logo}
              />
            </TouchableOpacity>

            <View style={styles.iconsContainer}>
              {RenderCustomHeaderButton}
              <View style={styles.icons}>
                <Pressable
                  nativeID="search"
                  onPress={() => closeSearchAndSetAppScroll(!openSearch)}
                >
                  <Icon
                    name={openSearch ? "closeCircled" : "search"}
                    size="larger"
                    fill="palette.neutral.x0"
                  />
                </Pressable>
              </View>
            </View>
          </View>
          {!!cmsData?.settings?.navigation_featured_item_title && (
            <Animated.View
              style={{
                height: Math.min(tabBarHeightValue, 32),
                overflow: "hidden",
              }}
            >
              <PromotionMessage
                title={cmsData?.settings?.navigation_featured_item_title}
                slug={cmsData?.settings?.navigation_featured_item_path}
              />
            </Animated.View>
          )}
        </View>
      )}
      <Search
        headerHeight={searchMarginTopCalc}
        open={openSearch}
        setOpenSearch={closeSearchAndSetAppScroll}
      />
      <TopBar navOverride={navOverride} setSubmenuHeight={false} />
      <View onLayout={(e) => setHeaderEndY(e.nativeEvent.layout.y)} />
    </View>
  );
};

const { colors, spacing } = variables;
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "row",
    backgroundColor: colors.palette.rtv.primary,
    paddingHorizontal: spacing.xsmall,
    maxHeight: 60,
    minHeight: 60,
    width: "100%",
  },
  leftCTAcontainer: {
    justifyContent: "flex-start",
    flex: 1,
  },
  headerSectionContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  iconsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  icons: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  menuContainer: {
    backgroundColor: colors.palette.rtv.primary,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: spacing.xsmall,
  },
  optionsContainer: {
    display: "flex",
    justifyContent: "center",
    paddingHorizontal: 25,
    height: 50,
    borderBottomWidth: 0.5,
    borderBottomColor: variables.colors.palette.buttons.disabled.borderColor,
  },
  menu: {
    width: "100%",
    flex: 1,
  },
  menuContent: {
    flexGrow: 1,
  },
  menuItem: {
    width: "auto",
    paddingVertical: spacing.xsmall,
    paddingRight: spacing.xsmall,
  },
  more: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  logo: {
    height: "60%",
    weight: "60%",
  },
  largeScreenContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  largeScreenButtonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  alignStart: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  alignEnd: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  extraFlex: {
    flex: 1.5,
  },
  lessFlex: {
    flex: 0.8,
  },
  rowFlex: {
    flexDirection: "row",
  },
  leftMargin: {
    marginLeft: spacing.xsmall,
    height: 1000,
  },
  logoContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
  },
});

export default React.memo(Header);
