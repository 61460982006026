import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import Icon from "../../../Icon";
import RText from "../../../RText";
import Gap from "../../../Gap";

type Props = {
  text?: string;
  iconName?: string;
  onPress?: () => void;
};

export default function TrackerButton({ text = "", iconName, onPress }: Props) {
  return (
    <TouchableOpacity
      activeOpacity={0.6}
      style={[styles.container]}
      onPress={onPress}
    >
      <Icon name={iconName} size="medium" />
      <Gap size="xxsmall" />
      <RText size="xs" family="primary" color="secondary">
        {text}
      </RText>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
  },
});
