import * as Updates from "expo-updates";

let Config = {};

if (Updates.channel === "production") {
  Config.APP_ENV = "production";
  Config.API_KEY = "936fdbdf-1e46-4f82-9d18-d81b4803537d";
} else if (Updates.channel === "staging") {
  Config.APP_ENV = "staging";
  Config.API_KEY = "d2d01b9a-a7b3-4dfc-8534-8b15615a54cd";
} else if (Updates.channel === "integration") {
  Config.APP_ENV = "integration";
  Config.API_KEY = "ff2fee46-d133-4ea6-b9fc-4aa8e61ef7d3";
}

export default Config;
