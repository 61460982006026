import { AVPlaybackStatus } from "expo-av";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Platform,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";
import { Advertisement, useAdvertiser } from "../../hooks/useAdvertiser";
import variables from "../../styles/variables";
import Icon from "../Icon";
import LoadingSpinner from "../LoadingSpinner";
import RText from "../RText";
import VideoPlayer from "../VideoPlayer";
import ControlBar from "./ControlBar";
import SkipAdButton from "./SkipAdButton";
import TitleBar from "./TitleBar";
export type VideoPlayerDisplayType = "embedded" | "overlay" | "sticky";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from "react-native-reanimated";
import { VideoPlayerHandle } from "../VideoPlayer/types";
import { useAtom } from "jotai";
import {
  appScrollRefAtom2,
  globalFullscreenAtom,
  playerPIDFocusedAtom,
  selectedChannelsAtom,
} from "../../atoms";
import StructuredData from "../SEO/StructuredData";
import { useResponsive } from "../../hooks/useResponsive";
import { resizePlayer } from "../../utils/resizePlayer";
import PrerollButton from "./PrerollButton";
import { activateKeepAwakeAsync, deactivateKeepAwake } from "expo-keep-awake";
import RCastButton from "../RCastButton/RCastButton";
import RAirplayButton from "../RCastButton/RAirplayButton";
import Gap from "../Gap";
import { getBrightnessAsync, setBrightnessAsync } from "expo-brightness";
import { getEnvName } from "../../utils/getEnvName";
import { useMobileScreenOrientation } from "../../hooks/useMobileScreenOrientation";
import { userAgentIsSSR } from "../../utils/userAgent";
import { randomUUID } from "expo-crypto";
import {
  removeLastStreamWatchedFromStorage,
  tagName,
} from "../../utils/fullStory";
import { logEvent } from "../../client/analytics/trackPageView";

export type RVideoPlayerProps = {
  autoPlay?: boolean;
  showPlayer?: boolean;
  displayType?: VideoPlayerDisplayType;
  source?: string;
  title?: string;
  description?: string;
  advertisement?: Advertisement;
  isLive?: boolean;
  errorMessage?: string;
  poster?: string;
  contentType?: string;
  onDurationChange?: (duration: number) => void;
  onPlayPress?: () => void;
  onClose?: () => void;
  uploadDate?: Date;
  rest?: any;
  streamChannel?: string;
};

const isWeb = Platform.OS === "web";
const isNative = Platform.OS == "ios" || Platform.OS == "android";
const isIOS = Platform.OS === "ios";
const isMobileWeb = isWeb && !!navigator.userAgent.match(/iPhone|iPad|Android/);
const isAndroid = Platform.OS === "android";
const hideControlsAfterInactivityMs = 1000;
const adButtonMarginBottomValue = isNative || isMobileWeb ? 40 : 50;
const MINIMUM_BRIGHTNESS = 0.75;
const envName = getEnvName();

const RVideoPlayer = forwardRef<any, RVideoPlayerProps>(
  (
    {
      autoPlay = true,
      displayType = "sticky",
      source,
      title = "",
      description = "",
      advertisement,
      isLive,
      errorMessage,
      showPlayer = true,
      poster,
      contentType,
      onClose,
      onDurationChange,
      onPlayPress,
      uploadDate,
      streamChannel,
      ...rest
    },
    ref
  ) => {
    const videoRef = useRef<VideoPlayerHandle>(null);
    const controlBarTimeoutId = useRef(null);
    const adButtonsTimeoutId = useRef(null);
    const hoverTimeoutId = useRef(null);
    const { height, width } = useWindowDimensions();
    const { isXLargeAndDown, isMediumAndUp } = useResponsive();

    const [, setIsGlobalFullScreen] = useAtom(globalFullscreenAtom);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [timer, setTimer] = useState({ currentTime: 0, duration: 1 });
    const [displayControls, setDisplayControls] = useState(false);
    const [currentSource, setCurrentSource] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { adState, handleEndVideo, handleSkipAd } =
      useAdvertiser(advertisement);

    const displaySkipAdButton = adState === "playing" && timer.duration > 6;
    const displayVisitWebsiteButton = adState === "playing";
    const [pid] = useState(`vp_${randomUUID()}`);
    const [pidFocused, setPidFocused] = useAtom(playerPIDFocusedAtom);
    const [selectedChannel] = useAtom(selectedChannelsAtom);
    const [isFocused, setIsFocused] = useState(true);
    const [adButtonMarginBottom, setAdButtonMarginBottom] = useState(
      adButtonMarginBottomValue
    );
    const [appScrollRef2] = useAtom(appScrollRefAtom2);
    const { setOrientation } = useMobileScreenOrientation();

    // Handle brightness level if too low
    const handleBrightness = async () => {
      const currentBrightness = await getAppBrightness();
      if (currentBrightness < MINIMUM_BRIGHTNESS) {
        await changeAppBrightness(MINIMUM_BRIGHTNESS);
      }
    };

    async function changeAppBrightness(value) {
      try {
        await setBrightnessAsync(value);
      } catch (error) {
        console.error("Failed to set brightness:", error);
      }
    }

    async function getAppBrightness(): Promise<number> {
      try {
        const brightness = await getBrightnessAsync();
        return brightness;
      } catch (error) {
        console.error("Failed to get brightness:", error);
      }
    }

    if (isAndroid) handleBrightness();

    useEffect(() => {
      activateKeepAwakeAsync("player")
        .then(() => {
          console.log("RVideoPlayer::KeepAwake activated");
        })
        .catch((e) => {
          console.log("RVideoPlayer::KeepAwake error", e);
        });
      return () => {
        console.log("RVideoPlayer::KeepAwake deactivated");
        deactivateKeepAwake("player");
      };
    }, []);

    useEffect(() => {
      if (timer.duration > 1 && onDurationChange)
        onDurationChange(timer.duration);
    }, [timer.duration]);

    useEffect(() => {
      console.log(source);
      switch (adState) {
        case "off":
          setCurrentSource(source);
          break;
        case "playing":
          setCurrentSource(advertisement?.source);
          break;
        case "ended":
          setCurrentSource(source);
          break;
        case "skipped":
          setCurrentSource(source);
        default:
          setCurrentSource(source);
      }
    }, [adState, source]);

    useEffect(() => {
      if (!!errorMessage) {
        setIsLoading(false);
      }
    }, [errorMessage]);

    useEffect(() => {
      if (isFullScreen) {
        setOrientation("landscape");
        setIsGlobalFullScreen(true);
      } else {
        setOrientation("portrait");
        setIsGlobalFullScreen(false);
        clearTimeout(controlBarTimeoutId.current);
        toggleOverlay(true);
      }
    }, [isFullScreen]);

    // This useEffect is used to inject the Google Cast script into the DOM
    // todo: Find a smarter way to achieve this
    useEffect(() => {
      if (!isWeb || !currentSource) return;
      if (userAgentIsSSR()) return;
      const script = document.createElement("script");
      script.id = "googleCastScript";
      script.type = "text/javascript";
      script.text = `
        document.querySelector("google-cast-launcher").onclick = function () {
          if (cast.framework.CastContext.getInstance().getCurrentSession()) return;

          const castSession = new Promise((resolve, reject) => {
            const interval = setInterval(() => {
              if (cast.framework.CastContext.getInstance().getCurrentSession()) {
                clearInterval(interval);
                resolve(cast.framework.CastContext.getInstance().getCurrentSession());
              }
            }
            , 1000);
          });

          castSession.then((cs) => {
            const mediaInfo = new chrome.cast.media.MediaInfo(
              "${currentSource}",
              "application/x-mpegURL"
            );
            mediaInfo.hlsSegmentFormat = chrome.cast.media.HlsSegmentFormat.TS;
            const request = new chrome.cast.media.LoadRequest(mediaInfo);
            cs.loadMedia(request).then(
              function () {
                console.log("Load succeed");
            },
              function (errorCode) {
                console.log("Error code: " + errorCode);
              }
            );
          });
        };
      `;
      script.async = true;
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }, [currentSource]);

    useEffect(() => {
      if (isWeb) {
        const gclButton = document.querySelector("google-cast-launcher");
        if (gclButton) {
          gclButton.addEventListener("click", () => {
            onPlayPress?.();
            logEvent({ key: "watch-cast", data: { stream_tag: `watch-cast` } });
          });
        }
      }
    }, []);

    const ChromeCastButton = () => {
      return (
        <RCastButton
          source={currentSource}
          title={title}
          subtitle={description}
          poster={poster}
          streamDuration={timer.duration}
          contentType={contentType}
          size={isFullScreen ? "medium" : "small"}
          isLive={isLive}
        />
      );
    };

    const renderCastButton = () => {
      if (isWeb && envName !== "safari")
        return (
          <View>
            {/* @ts-ignore */}
            <google-cast-launcher
              style={{
                width: isFullScreen ? 35 : 24,
                height: isFullScreen ? 35 : 24,
              }}
              id="castbutton"
              // @ts-ignore
            ></google-cast-launcher>
          </View>
        );
      else if (isIOS)
        return (
          <>
            <ChromeCastButton />
            <Gap vertical size="large" />
            <RAirplayButton size={isFullScreen ? "medium" : "small"} />
          </>
        );
      else return <ChromeCastButton />;
    };

    const handleStatusUpdate = (status: AVPlaybackStatus) => {
      if (status.isLoaded) {
        if (isLoading) setIsLoading(false);
        setTimer({
          currentTime: status.positionMillis / 1000,
          duration: status.durationMillis ? status.durationMillis / 1000 : null,
        });
        if (status.isPlaying !== isPlaying) setIsPlaying(status.isPlaying);
        if (status.didJustFinish) handleEndVideo();
      } else if (!isLoading && source) setIsLoading(true);
    };

    const toggleOverlay = (value: boolean = !displayControls) => {
      setDisplayControls(value);
    };

    const handleFullScreen = () => {
      if (!isFullScreen) {
        appScrollRef2?.current?.scrollTo({ y: 0, animated: false }); // stop white spaces on apps fullscreen
        videoRef.current.requestFullscreen();
      } else {
        videoRef.current.exitFullscreen();
      }
    };

    const handleClose = () => {
      onClose?.();
      if (isFullScreen) {
        setOrientation("portrait");
        setIsGlobalFullScreen(false);
        setIsFullScreen(false);
        handleFullScreen();
      }
    };

    const handleSeek = (time: number) => {
      if (videoRef.current) {
        videoRef.current.seekTo(time);
      }
    };

    const handleVolume = (value: number) => {
      if (videoRef.current) {
        videoRef.current.setVolumeTo(value);
      }
    };

    const handleMute = (isMuted: boolean) => {
      if (videoRef.current) {
        videoRef.current.setIsMuted(isMuted);
      }
    };

    const togglePlay = () => {
      videoRef.current.isPlaying().then((playing) => {
        videoRef.current[playing ? "pause" : "play"]();
        toggleOverlay(playing ? true : false);
        !playing && onPlayPress?.();
      });
    };

    const handlePopOut = async () => {
      if (isWeb && videoRef.current) {
        try {
          await videoRef.current.requestPictureInPicture();

          logEvent({
            key: "watch-popout",
            data: { stream_tag: `watch-popout` },
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    const renderLoadingOrPlayIcon = () => {
      if (isLoading)
        return <LoadingSpinner style={defaultStyles.loadingIcon} />;
      else if (!isPlaying || !source)
        return (
          <Pressable
            style={defaultStyles.icon}
            onPress={() => {
              videoRef?.current?.play();
              onPlayPress?.();
            }}
          >
            <Icon name="play" size="xxxlarge" />
          </Pressable>
        );
      else return null;
    };

    const controlBarOpacity = useSharedValue(displayControls ? 1 : 0);
    const controlBarAnimatedStyle = useAnimatedStyle(() => {
      return {
        opacity: withTiming(controlBarOpacity.value, {
          duration: controlBarOpacity.value === 0 ? 1000 : 100,
          easing: Easing.ease,
        }),
      };
    });

    useEffect(() => {
      if (!isLive) return;
      setPidFocused(pid);
    }, []);

    useEffect(() => {
      if (!isLive) return;
      if (selectedChannel.length === 1) {
        setIsFocused(true);
      } else {
        setIsFocused(pidFocused === pid);
      }
    }, [selectedChannel, pidFocused]);

    const handleHoverOrFirstScreenPress = () => {
      clearTimeout(hoverTimeoutId.current);
      toggleOverlay(true);
      hoverTimeoutId.current = setTimeout(() => {
        isPlaying && toggleOverlay(false);
      }, hideControlsAfterInactivityMs);
    };

    const handleMobileShowControls = () => {
      if (isNative || isMobileWeb) {
        /* On touch screen devices, first click will show controls, second click will pause */
        if (controlBarOpacity.value === 1) togglePlay();
        else handleHoverOrFirstScreenPress();
      } else {
        toggleOverlay(true);
        togglePlay();
      }
    };

    const handleScreenPress = () => {
      // fire FS event for streams
      if (selectedChannel?.length >= 1) {
        logEvent({
          key: `track-video-stream`,
          data: {
            stream_tag: `${tagName[selectedChannel[0].name]}-${
              isPlaying ? "exit" : "play"
            }`,
          },
        });

        //if the user has paused the video, remove last stream watched from storage to stop exit tags being sent to FS if the user navigates to a different stream tab or different page
        isPlaying ? removeLastStreamWatchedFromStorage() : null;
      }

      if (isLive) {
        if (pidFocused !== pid) {
          setPidFocused(pid);
        } else {
          handleMobileShowControls();
        }
      } else {
        if (adState === "playing") {
          videoRef?.current?.pause();
          return;
        }
        handleMobileShowControls();
      }
    };

    const handleAdButtonMarginBottom = () => {
      if (displayControls)
        setAdButtonMarginBottom(isFullScreen ? 80 : adButtonMarginBottomValue);
      else
        adButtonsTimeoutId.current = setTimeout(
          () => setAdButtonMarginBottom(variables.spacing.xsmall),
          1000
        );
    };

    useEffect(() => {
      clearTimeout(controlBarTimeoutId.current);
      clearTimeout(adButtonsTimeoutId.current);
      const newValue: number = displayControls ? 1 : 0;
      controlBarTimeoutId.current = setTimeout(
        () => {
          controlBarOpacity.value = newValue;
          handleAdButtonMarginBottom();
        },
        displayControls ? 0 : hideControlsAfterInactivityMs
      );
    }, [displayControls, isFullScreen]);

    useEffect(() => {
      if (isPlaying) {
        toggleOverlay(false);
      }
    }, [isPlaying]);

    const renderControlBar = () => {
      const controlBarStyles = [
        defaultStyles.controlBarContainer,
        styles[displayType].controlBarContainer,
        { display: "flex" },
        controlBarAnimatedStyle,
      ];
      if (isFullScreen) {
        controlBarStyles.push({
          ...defaultStyles.controlBarContainerFullScreen,
          justifyContent: "flex-start",
          paddingTop: 10,
        });
      }

      return (
        <Animated.View style={controlBarStyles}>
          <ControlBar
            isPlaying={isPlaying}
            isFullScreen={isFullScreen}
            isLive={isLive}
            isAdPlaying={adState === "playing"}
            onPlayPausePress={() => {
              if (isPlaying) videoRef?.current?.pause();
              else {
                videoRef?.current?.play();
                onPlayPress?.();
              }
            }}
            onFullScreenPress={handleFullScreen}
            onSeek={handleSeek}
            timer={timer}
            onVolumeChange={handleVolume}
            onMutePress={handleMute}
            onPopOutPress={handlePopOut}
            isFocused={isFocused}
            onPopupOnpen={() => clearTimeout(controlBarTimeoutId.current)}
            onHover={() => handleHoverOrFirstScreenPress()}
            castButtonProps={{
              currentSource,
              title,
              subtitle: description,
              poster,
              contentType,
            }}
          />
        </Animated.View>
      );
    };

    const renderTitleBar = () => {
      const titleBarStyles = [
        defaultStyles.titleBarContainer,
        styles[displayType].titleBarContainer,
        isFullScreen && defaultStyles.titleBarContainerFullScreen,
        (!!title || !!onClose) && { display: "flex" },
        !title && { backgroundColor: "transparent" },
        controlBarAnimatedStyle,
      ];
      return (
        <Animated.View style={titleBarStyles}>
          <TitleBar
            style={{
              close: {
                display: onClose ? "flex" : "none",
                alignItems: "flex-end",
                margin: variables.spacing.xsmall,
              },
            }}
            onClose={handleClose}
            title={title?.capitalize()}
          />
        </Animated.View>
      );
    };

    const renderPlayer = (forcePlay = false) => {
      const sstyles = [
        defaultStyles.videoPlayerContainer,
        styles[displayType].videoPlayerContainer,
        !isLive &&
          isXLargeAndDown && {
            width: resizePlayer(height, width),
          },
        isWeb && { height: "fit-content" },
        isFullScreen && defaultStyles.videoPlayerContainerFullScreen,
        isLive &&
          selectedChannel.length > 1 &&
          isFocused && {
            borderWidth: 5,
            borderColor: variables.colors.palette.rtv.secondary,
          },
      ];

      const castButtonStyle = [
        defaultStyles.castButton,
        controlBarAnimatedStyle,
        Platform.OS == "ios" && isFullScreen && { marginRight: 40 },
      ];

      if (isNative && isFullScreen) {
        sstyles.push({
          width,
          height,
          zIndex: 1000,
          position: "absolute",
          top: 0,
        });
      }

      return (
        <View ref={ref} nativeID={pid} style={sstyles}>
          <View
            nativeID={Platform.OS === "web" ? "video-player-container" : null}
          >
            <Animated.View style={castButtonStyle}>
              {renderCastButton()}
            </Animated.View>
            <Pressable
              style={
                isNative && {
                  backgroundColor: variables.colors.palette.rtv.black,
                  alignItems: "center",
                }
              }
              onPress={handleScreenPress}
            >
              <VideoPlayer
                isLive={isLive}
                ref={videoRef}
                poster={poster}
                autoPlay={forcePlay || autoPlay}
                source={currentSource}
                onStatusUpdate={handleStatusUpdate}
                style={{
                  ...defaultStyles.videoPlayer,
                  width:
                    isFullScreen && Platform.OS != "web" && !Platform.isPad
                      ? "auto"
                      : "100%",
                }}
                onFullScreenChange={setIsFullScreen}
                pid={pid}
                onHover={() => handleHoverOrFirstScreenPress()}
                {...rest}
              />
            </Pressable>
            {renderTitleBar()}
            {renderLoadingOrPlayIcon()}
            {renderControlBar()}
            {displaySkipAdButton && (
              <SkipAdButton
                title="Skip after"
                skipAfterXSeconds={5}
                onPress={handleSkipAd}
                isPlaying={isPlaying}
                style={{
                  ...defaultStyles.adButtons,
                  right: 0,
                  marginRight: variables.spacing.xsmall,
                  marginBottom:
                    displayType === "overlay"
                      ? variables.spacing.xsmall
                      : adButtonMarginBottom,
                }}
              />
            )}
            {displayVisitWebsiteButton && (
              <PrerollButton
                style={{
                  ...defaultStyles.adButtons,
                  left: 0,
                  marginLeft: variables.spacing.xsmall,
                  marginBottom:
                    displayType === "overlay"
                      ? variables.spacing.xsmall
                      : adButtonMarginBottom,
                }}
                prerollsId={advertisement?.prerollsId}
                link={advertisement?.actionDomain}
                title={advertisement?.actionText}
                logo={advertisement?.logo}
              />
            )}
            {!!errorMessage && errorMessage !== "" && (
              <View style={defaultStyles.errorMessageContainer}>
                <RText color="white" size="md">
                  {errorMessage}
                </RText>
              </View>
            )}
          </View>
          {!!(Platform.OS == "web") && (
            <StructuredData
              type="VideoObject"
              data={{
                name: title,
                contentUrl: source,
                thumbnailUrl: poster,
                description: description,
                uploadDate: uploadDate, // SEO TODO - we need this to be given on all video endpoints from the API. Also need to implement on IndividualOnDemand.tsx
                // "interactionStatistic": {
                //   "@type": "InteractionCounter",
                //   "interactionType": { "@type": "WatchAction" },
                //   "userInteractionCount": 5647018
                // },
                // "duration": "PT1M54S", PT (Period of Time) 1M (1 minute) 54S (54 seconds)
              }}
            />
          )}
        </View>
      );
    };

    return renderPlayer();
  }
);

const defaultStyles = StyleSheet.create({
  videoPlayerContainer: {
    position: "absolute",
    justifyContent: "center",
    margin: "auto",
    minWidth: "50%",
    maxWidth: "100%",
  },
  videoPlayerContainerFullScreen: {
    width: "100%",
    height: "100%",
    backgroundColor: variables.colors.palette.rtv.black,
    position: "absolute",
    top: 0,
    zIndex: 900000,
  },
  videoPlayer: {
    width: "100%",
    aspectRatio: 16 / 9,
    backgroundColor: variables.colors.palette.rtv.black,
  },
  titleBarContainer: {
    display: "none",
    width: "100%",
    position: "absolute",
    minHeight: isWeb ? variables.spacing.large : variables.spacing.small,
    maxHeight: isWeb ? variables.spacing.large : variables.spacing.medium,
  },
  titleBarContainerFullScreen: {
    backgroundColor: "transparent",
    top: isIOS ? 20 : 10,
  },
  controlBarContainer: {
    display: "none",
    width: "100%",
    backgroundColor: variables.colors.palette.rtv.primary,
    position: "absolute",
  },
  controlBarContainerFullScreen: {
    position: "absolute",
    justifyContent: "center",
    bottom: 0,
    height: 60,
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: [{ translateX: -28 }, { translateY: -28 }],
  },
  loadingIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: [{ translateX: -12 }, { translateY: -12 }],
    scale: 1.45,
  },
  errorMessageContainer: {
    position: "absolute",
    top: "50%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  adButtons: {
    minWidth: 100,
    position: "absolute",
    bottom: 0,
  },
  castButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1000,
    margin: variables.spacing.xsmall,
  },
});

const overlayStyles = StyleSheet.create({
  overlay: {
    // display: "flex",
    // flex: 1,
    // width: "100%",
    // height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  videoPlayerContainer: {
    // alignSelf: "center",
    // top: 0,
    // bottom: 0,
    // margin: "auto",
    // width: "80%",
  },
  titleBarContainer: {
    // height: 50,
    // backgroundColor: "transparent",
    // top: -50,
  },
  controlBarContainer: {
    // height: 50,
    // bottom: -49,
  },
});

const stickyStyles = StyleSheet.create({
  videoPlayerContainer: {
    // width: "100%",
    // top: isWeb ? 0 : 49,
  },
  titleBarContainer: {
    // height: 30,
    // backgroundColor: "transparent",
    // top: 0,
  },
  controlBarContainer: {
    // height: 30,
    // bottom: 0,
  },
});

const embeddedStyles = StyleSheet.create({
  videoPlayerContainer: {
    position: "relative",
    width: "100%",
  },
  titleBarContainer: {
    backgroundColor: variables.colors.palette.rtv.primary,
    top: 0,
  },
  controlBarContainer: {
    // height: 30,
    bottom: -1,
  },
});

const styles = {
  embedded: embeddedStyles,
  overlay: overlayStyles,
  sticky: stickyStyles,
};

export default RVideoPlayer;
