import { StatusBar } from "expo-status-bar";
import React, {
  useEffect,
  useRef,
  FC,
  useState,
  useMemo,
  Suspense,
  useLayoutEffect,
  useCallback,
} from "react";
import {
  NavigationContainer,
  NavigationContainerRefWithCurrent,
  createNavigationContainerRef,
  useNavigation,
} from "@react-navigation/native";
import lazyImport from "./src/utils/lazyImport/lazyImport";
import URLMapper from "./src/components/Navigation/URLMapper";
import {
  Platform,
  ScrollView,
  View,
  Modal,
  Pressable,
  Animated,
  AppState,
  useWindowDimensions,
  StyleSheet,
  AppStateStatus,
} from "react-native";
import Gap from "./src/components/Gap";
import * as SplashScreen from "expo-splash-screen";
import { useResponsive } from "./src/hooks/useResponsive";
import variables from "./src/styles/variables";
import TabBar from "./src/components/TabBar/TabBar";
import ToastManager from "./src/components/Toasts/ToastManager";
import { CalendarPickerCalendar } from "./src/components/CalendarPicker/CalendarPicker";
import { ClickOutsideProvider } from "react-native-click-outside";
import { logEvent, trackPageView } from "./src/client/analytics/trackPageView";
import { loadAsync } from "expo-font";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Header from "./src/components/Header";
import linking from "./src/components/Navigation/URLMapper";
import "./src/utils/case";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  APP_ENV,
  MEMBERS_APP_URL,
  BASE_URL,
  fetchGet,
  FRONTEND_URL,
} from "./src/client";
import AuthModal from "./src/components/Auth/AuthModal";
import RModal from "./src/components/RModal/RModal";
import UpdateDetailsModal from "./src/components/Screens/Account/components/UpdateDetailsModal";
import { STORAGE_KEYS, storage } from "./src/utils/storage";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  globalFullscreenAtom,
  openAuthModalAtom,
  openDrawerAtom,
  pageDataAtom,
  scrollEnabledAtom,
  userAtom,
  changeDetailsModalAtom,
  advertisingAtom,
  advertOverlayModalAtom,
  showSkyScraperAdAtom,
  globalPlayerSourceAtom,
  cmsDataAtom,
  autoMetaDescriptionAtom,
  appScrollRefAtom,
  freeVideoPeriodsAtom,
  festivalsDataAtom,
  showPreferenceHeaderAtom,
  tabAdLayoutAtom,
  tabLayoutAtom,
  floatingPopupAtom,
  routeAndPathAtom,
  signupPageNumberAtom,
} from "./src/atoms";
import { getActiveFestivals, getCmsData } from "./src/client/cms";
import { getAdverts, advertClick, saveAdvertSeen } from "./src/client/adverts";
import { Path, buildPath } from "./src/utils/buildPath";
import useRefreshUser from "./src/hooks/useRefreshUser";
import AdBanner from "./src/components/Layout/AdBanners/AdBanner";
import { AdvertDetails, Placements } from "./src/types/advert";
import { ErrorBoundary } from "./src/utils/ErrorBoundary";
import Bugsnag from "@bugsnag/expo";
import * as ScreenOrientation from "expo-screen-orientation";
import { onMessageListener } from "./src/client/firebase/messaging";
import LoadingSpinner from "./src/components/LoadingSpinner";
import { useZendesk } from "./src/hooks/useZendesk";
import { useCookieBanner } from "./src/hooks/useCookieBanner";
import RSuspense from "./src/utils/RSuspense/RSuspense";
import useDotDigital from "./src/hooks/useDotDigital";
import debounce from "lodash.debounce";
import { withIAPContext } from "react-native-iap";
import { getLiveChannelsSource } from "./src/client/watch/live";
import { enableFreeze } from "react-native-screens";
import { CacheManager } from "./src/client/cache/cache-manager";
import UseUpdates from "./src/hooks/UseUpdates";
import { getUserState } from "./src/utils/auth";
import PreferenceHeader from "./src/components/Screens/Account/components/PreferenceHeader";

import useWebsocket from "./src/hooks/useWebsocket";
import useUser from "./src/hooks/useUser";
import { userAgentIsSSR } from "./src/utils/userAgent";
import TakeoverAdBanner from "./src/components/Layout/AdBanners/TakeoverAdBanner";
import { useApplePay } from "./src/hooks/useApplePay";
import { APPLE_PAY_AVAILABLE } from "./src/utils/flags";
import usePushNotifications from "./src/hooks/usePushNotifications";
import { sendAnalyticsEventAsync } from "./src/client/firebase/analytics";
import FullStory from "./src/services/FullStory";
import useDeepLink from "./src/hooks/useDeepLink";
import { AborterManager } from "./src/utils/aborters/AborterManager";
import {
  useFloating,
  arrow,
  offset,
  autoPlacement,
  shift,
} from "@floating-ui/react-native";
import {
  getLastStreamWatchedFromStorage,
  removeLastStreamWatchedFromStorage,
} from "./src/utils/fullStory";

if (Platform.OS !== "web") {
  // Start a interval task to clear the network cache to prevent out of memory errors
  CacheManager.startCleanupTask(1800000); // 1800000ms == 30 mins

  // Prevent adding screens to the DOM which aren't currently visible/attached
  // Theres a chance this has a non zero improvement to web performance - but
  // removing from web until thats verified for safety.
  enableFreeze(true);
}

const RVideoPlayer = lazyImport(() => import("./src/components/RVideoPlayer/"));
const Drawer = lazyImport(() => import("./src/components/Navigation/"));

if (APP_ENV === "production") {
  console.log = function () {};
}

const isWeb = Platform.OS === "web";
const isIos = Platform.OS === "ios";

// This is a workaround for the floating popup that pops top left before moving to the correct position
// It should be fixed through a useLayoutEffect with next react-native release (0.75)
const POPUP_DELAY_MS = 75;
const POPUP_OFFSET = 5;

if (!isWeb)
  ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);

/* Fix for https://github.com/software-mansion/react-native-reanimated/issues/3355 */
if (isWeb) {
  (window as any)._frameTimestamp = null;
} else {
  // used to make actioncable work on native apps
  // in theory these do not exist on native apps anyway
  global.addEventListener = () => {};
  global.removeEventListener = () => {};
}

function App() {
  const navRef = createNavigationContainerRef();
  const appScrollRef = useRef<ScrollView>(undefined);
  const [pageData, setPageData] = useAtom(pageDataAtom);
  const [routeAndPath, setRouteAndPath] = useAtom(routeAndPathAtom);
  const setAdverts = useSetAtom(advertisingAtom);

  const setShowSkyScraper = useSetAtom(showSkyScraperAdAtom);
  const [festivalsData, setFestivalsData] = useAtom(festivalsDataAtom);

  const { refreshUser } = useRefreshUser();
  const [user] = useAtom(userAtom);

  useEffect(() => {
    let skipBugsnag = false;
    if (isWeb) {
      // initialize dataLayer
      window.dataLayer = window?.dataLayer || [];
      if (window.location.href.includes("localhost")) {
        skipBugsnag = true;
      }
    }

    if (!skipBugsnag && !Bugsnag.isStarted()) {
      Bugsnag.start({
        releaseStage: APP_ENV,
      });
    }
  }, []);

  // identify user for fullstory
  useEffect(() => {
    if (user?.detail?.csn) {
      const {
        detail: { csn, email, name_family, name_given },
      } = user;
      console.log("[Fullstory] Identifying user: ", csn, email);
      FullStory.identifyUser({
        uid: csn,
        data: {
          displayName: `${name_given} ${name_family}`,
          email,
        },
      });
    }
  }, [user]);

  if (Platform.OS == "web") {
    useLayoutEffect(() => {
      refreshUser();
    }, []);
  }

  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    if (Platform.OS == "web") return;
    const loadFontsAsync = async () => {
      // Dynamically import the fonts for native platforms
      const fontsModule = await import("./src/styles/fonts");
      loadAsync(fontsModule.FONTS).then(() => {
        setFontsLoaded(true);
      });
    };

    loadFontsAsync();
  }, []);

  // Keep showing splash screen we're ready to hide
  useEffect(() => {
    if (Platform.OS == "web") return;

    (async function prepare() {
      if (fontsLoaded) {
        await refreshUser();

        await SplashScreen.preventAutoHideAsync();
      }
    })();
  }, [fontsLoaded]);

  useEffect(() => {
    if (Platform.OS !== "web") return;

    const handleBeforeUnload = () => {
      sendExitStreamTagEventToFullStory();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (Platform.OS === "web") return;
    const handleAppStateChange = (nextAppState: AppStateStatus) => {
      if (nextAppState === "background") sendExitStreamTagEventToFullStory();
    };

    const subscription = AppState.addEventListener(
      "change",
      handleAppStateChange
    );

    return () => {
      subscription.remove();
    };
  }, []);

  const aborterManager = AborterManager.getInstance();

  const requestPageData = useCallback(
    async (path: string) => {
      console.log(`[requestPageData] Starting request for path: ${path}`);

      const aborterKey = "pageDataRequest"; // Unique key for this request
      const aborter = aborterManager.getAborter(aborterKey);

      try {
        console.log(`[requestPageData] Sending request... Path ${path}`);

        const signal = aborter.getNewSignal();
        const res = await fetchGet("pages", { path }, false, {}, signal);

        console.log(
          `[requestPageData] Request successful. Setting page data. Path ${path}`
        );
        setPageData(res);
      } catch (err) {
        if (err.name === "AbortError") {
          console.log(`[requestPageData] Request was aborted. Path ${path}`);
        } else {
          console.error(
            `[requestPageData] Pages fetch error: ${err} Path ${path}`
          );
          setPageData(err);
        }
      } finally {
        console.log(
          `[requestPageData] Request completed. Cleanup for path: ${path}`
        );
      }
    },
    [setPageData]
  );

  const fetchAndSetAds = async (path: Path) => {
    if (userAgentIsSSR()) return;
    if (path == "/") path = "/home"; // special case home

    getAdverts(path)
      .then((adverts) => {
        // merge takeover into adverts placements to handle the same way
        if (adverts?.takeover) {
          adverts.placements = {
            ...adverts.placements,
            takeover: adverts.takeover,
          };
          // keep all other placements but remove the overlays as takeover is king
          // we do this to stop incrementing ads we have not seen
          delete adverts.placements.overlay_primary;
          delete adverts.placements.overlay_secondary;
        }
        setShowSkyScraper(!!adverts?.placements?.skyscraper);
        setAdverts(adverts.placements);
        if (adverts?.placements) {
          saveAdvertSeen({
            path: path,
            placements: adverts?.placements as Placements,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR GETTING Adverts", err.message);
        setAdverts(null);
      });
  };

  const fetchFestivalsData = async () => {
    const response = await getActiveFestivals();
    setFestivalsData(response);
  };

  const sendExitStreamTagEventToFullStory = async () => {
    try {
      const lastStreamName = await getLastStreamWatchedFromStorage();
      if (lastStreamName) {
        logEvent({
          key: "track-video-stream",
          data: { stream_tag: `${lastStreamName}-exit` },
        });

        await removeLastStreamWatchedFromStorage();
      }
    } catch (e) {
      console.error("Error sending exit stream tag to FullStory", e);
    }
  };

  if (!fontsLoaded && Platform.OS != "web") return null;

  const scrollToTop = () => {
    appScrollRef?.current?.scrollTo({
      y: 0,
      animated: false,
    });
  };

  const handlePageChange = async (
    navRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
  ) => {
    const path = buildPath(navRef);
    if (path === routeAndPath.path || !path) return;

    setRouteAndPath({ route: navRef.getCurrentRoute()?.name, path: path });

    await Promise.all([
      trackPageView(navRef),
      sendExitStreamTagEventToFullStory(),
      requestPageData(path),
      fetchAndSetAds(path),
      festivalsData === undefined ? fetchFestivalsData() : Promise.resolve(),
    ]);
  };

  return (
    <SafeAreaProvider>
      <HelmetProvider>
        <ClickOutsideProvider>
          <ToastManager>
            <NavigationContainer
              fsClass="fs-unmask"
              documentTitle={{
                enabled: true,
                formatter: () =>
                  pageData?.page?.meta_title ||
                  window?.document?.title ||
                  "Racing TV", // if undefined - it needs to be set in cms or per page.
              }}
              linking={URLMapper}
              ref={navRef}
              onStateChange={async () => {
                if (!navRef?.current) return;

                console.log(
                  `[APP.tsx] onStateChange fired: ${JSON.stringify(
                    navRef?.getCurrentRoute()?.name
                  )}`
                );

                scrollToTop();
                await handlePageChange(navRef);
              }}
            >
              <ErrorBoundary
                user={user}
                currentRoute={routeAndPath.route}
                nav={navRef}
              >
                <AppContent appScrollRef={appScrollRef} navRef={navRef} />
              </ErrorBoundary>
            </NavigationContainer>
          </ToastManager>
        </ClickOutsideProvider>
      </HelmetProvider>
    </SafeAreaProvider>
  );
}

type AppContentProps = {
  appScrollRef?: React.RefObject<ScrollView>;
  navRef: any;
};

const AppContent: FC<AppContentProps> = ({ appScrollRef, navRef }) => {
  const [pageData] = useAtom(pageDataAtom);
  const [autoMetaDescription] = useAtom(autoMetaDescriptionAtom);
  const setCmsData = useSetAtom(cmsDataAtom);
  const [isGlobalFullScreen] = useAtom(globalFullscreenAtom);
  const [isAuthModalOpen] = useAtom(openAuthModalAtom);
  const [isScrollEnabled] = useAtom(scrollEnabledAtom);
  const [isDrawerOpen] = useAtom(openDrawerAtom);
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useAtom(
    changeDetailsModalAtom
  );
  const [signupPageNumber, setSignupPageNumber] = useAtom(signupPageNumberAtom);

  useEffect(() => {
    if (!isAuthModalOpen && signupPageNumber) {
      logEvent({
        key: `sign-up-modal-page-${signupPageNumber}-exit`,
        data: {},
      });
      setSignupPageNumber("");
    }
  }, [isAuthModalOpen]);

  const [advertOverlayModal, setAdvertOverlayModal] = useAtom(
    advertOverlayModalAtom
  );
  const [adverts] = useAtom(advertisingAtom);
  const [tabAdvert, setTabAdvert] = useState<JSX.Element | null>(null);

  const { isSmallAndDown, isMediumAndDown } = useResponsive();
  const [user] = useAtom(userAtom);
  const [globalPlayerSource, setGlobalPlayerSource] = useAtom(
    globalPlayerSourceAtom
  );
  const setAppScrollRef = useSetAtom(appScrollRefAtom);
  const [showPreferenceHeader] = useAtom(showPreferenceHeaderAtom);
  const currentOffset = useRef(0);
  const tabBarHeight = 60;
  const preferenceHeaderHeight = 80;
  const preferenceHeightValue = useRef(
    new Animated.Value(preferenceHeaderHeight)
  ).current;
  const showTabBar =
    !isGlobalFullScreen && (isMediumAndDown || Platform.OS !== "web");
  const [tabBarTrigger, setTabBarTrigger] = useState(showTabBar);

  const [, setFreeVideoPeriods] = useAtom(freeVideoPeriodsAtom);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [, setTabAdLayout] = useAtom(tabAdLayoutAtom);
  const [, setTabLayout] = useAtom(tabLayoutAtom);

  // When the app goes to the foreground, check for updates
  useEffect(() => {
    if (Platform.OS === "web") return;
    console.log("about to check for updates");
    // call update check once initially
    checkForUpdateAsync();

    // then setup a listener for when the app comes back to the foreground
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (nextAppState === "active" && Platform.OS !== "web") {
        console.log("App has come to the foreground!");
        checkForUpdateAsync();
      }
    });

    return () => {
      subscription.remove();
    };
  }, []);

  const { createCable } = useWebsocket();

  // only load zendesk on web when auth modal is open
  if (Platform.OS == "web" && APP_ENV != "integration") {
    const shouldShowHelpWidget = [
      "/contact",
      "/benefits",
      "/my-account",
    ].includes(window.location.pathname);

    useZendesk(isAuthModalOpen || shouldShowHelpWidget, showTabBar);
    useCookieBanner();
  }

  if (Platform.OS !== "web") {
    usePushNotifications();
    useDotDigital();
    useDeepLink();
  }

  if (APPLE_PAY_AVAILABLE) {
    const { getEverything } = useApplePay();

    useEffect(() => {
      getEverything();
    }, []);
  }

  const { updateModal, checkForUpdateAsync } = UseUpdates(
    showUpdateModal,
    setShowUpdateModal
  );

  // Setup websockets
  useEffect(() => {
    createCable();
  }, []);

  useEffect(() => {
    // request permission + setup tokens for notifications
    // requestDevicePermission();

    // setup service worker for web notifications
    if (Platform.OS === "web") {
      onMessageListener();
      setAppScrollRef(appScrollRef);
    }
  }, []);

  //handle overlay adverts
  useEffect(() => {
    setShowTakeover(true); // reset takeover ad state when ads change
    if (!adverts || isDrawerOpen) return;

    //check if we have adverts
    setAdvertOverlayModal(
      adverts?.takeover ||
        adverts?.overlay_primary ||
        adverts?.overlay_secondary ||
        {}
    );
  }, [adverts]);

  const { width } = useWindowDimensions();

  useMemo(() => {
    if (!!adverts && !adverts?.tab) {
      setTabAdvert(null);
      return;
    }

    if (!adverts?.tab) return;

    const tabAd = (
      <AdBanner
        accessibilityLabel="Banner Advert"
        onPress={() => handleOverlayPress(advertOverlayModal)}
        style={[
          {
            aspectRatio: 8.14 / 1,
            backgroundColor: variables.colors.palette.rtv.primary,
            minHeight: width / 8.14,
          },
          Platform.OS == "web" ? { justifyContent: "flex-end" } : {},
        ]}
        ad={adverts.tab}
      />
    );

    setTabAdvert(tabAd);
  }, [adverts?.tab?.campaign_id]);

  const handleOverlayPress = async (
    overlay: AdvertDetails,
    isDismiss: boolean = false
  ) => {
    // call to api on ad click if not dismissed
    !isDismiss && advertClick(overlay.link_url);
    // close overlay add
    setAdvertOverlayModal({} as AdvertDetails);
  };

  const handleFinishSetup = () => {
    if (Platform.OS == "web") return;
    SplashScreen.hideAsync();
  };

  useEffect(() => {
    const userState = getUserState(user);
    if (userState === "paid_account") return; // no need to check for free video period if user is paid
    getLiveChannelsSource(undefined, true)
      .then((res: string) => {
        setFreeVideoPeriods(res);
      })
      .catch((err: Error) => {
        // ensure false is set on error
        setFreeVideoPeriods(undefined);
      });
  }, [user]);

  useEffect(() => {
    getCmsData()
      .then((res) => {
        setCmsData(res);
        handleFinishSetup();
      })
      .catch((err: Error) => {
        handleFinishSetup();
        console.log(err.message);
      });
  }, []);

  // TODO - check this logic - it feels like
  // we shouldn't need to delete the user details
  // just because they've closed a modal. (if it was
  // temporary details, that would be different)
  const handleUpdateDetailsModalClose = async () => {
    await storage.remove({
      key: STORAGE_KEYS.USER_DETAILS,
    });
    setIsEditDetailsModalOpen(false);
  };

  useEffect(() => {
    if (Platform.OS != "web") return;

    debounce(() => {
      const launcher = document?.getElementById("launcher");
      const webWidget = document?.getElementById("webWidget");
      const justAboveTabBar = Math.max(tabBarTrigger ? 60 : 0);
      const justAbovePreferenceHeader = Math.max(
        preferenceHeightValue._value || 0
      );

      if (showTabBar) {
        if (showPreferenceHeader) {
          launcher &&
            (launcher.style.bottom =
              justAbovePreferenceHeader + justAboveTabBar + "px");
          webWidget &&
            (webWidget.style.bottom =
              justAbovePreferenceHeader + justAboveTabBar + "px");
        } else {
          launcher && (launcher.style.bottom = justAboveTabBar + "px");
          webWidget && (webWidget.style.bottom = justAboveTabBar + "px");
        }
      } else {
        launcher && (launcher.style.bottom = "0px");
        webWidget && (webWidget.style.bottom = "0px");
      }
    }, 50)();
  }, [showTabBar, showPreferenceHeader]);

  const [showTakeover, setShowTakeover] = useState(true);
  const isIframeAd = advertOverlayModal?.iframe;

  const tabBarAdBottomAmount = useRef(new Animated.Value(60)).current;

  useEffect(() => {
    if (!isWeb || APP_ENV == "integration") return;
    Animated.timing(tabBarAdBottomAmount, {
      toValue: tabBarTrigger ? 60 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [tabBarTrigger]);

  const adModal = advertOverlayModal?.takeover_id ? (
    <Modal transparent visible={showTakeover && !showUpdateModal}>
      {width >= variables.width4K && Platform.OS === "web" ? (
        <div
          className="exclude-zoom"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
          }}
        >
          <TakeoverAdBanner
            closeFunction={setShowTakeover}
            ad={advertOverlayModal}
          ></TakeoverAdBanner>
        </div>
      ) : (
        <TakeoverAdBanner
          closeFunction={setShowTakeover}
          ad={advertOverlayModal}
        ></TakeoverAdBanner>
      )}
    </Modal>
  ) : (
    <RModal
      style={{
        container: {
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          flex: 1,
          minHeight: 0,
          // maxHeight: 415,
          backgroundColor: "transparent",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "35%",
          maxWidth: isSmallAndDown ? 350 : undefined, //may come back to this
          top: isSmallAndDown ? "10%" : 0,
        },
        closeBtn: {
          backgroundColor: "white",
          borderRadius: 20,
          top: isSmallAndDown ? "-5%" : 0,
          right: 0,
          padding: 5,
        },
      }}
      visible={!!Object.keys(advertOverlayModal).length && !showUpdateModal}
      setVisibility={async () =>
        await handleOverlayPress(advertOverlayModal, true)
      }
      adOverlay
    >
      <View style={{ display: "flex" }} fsClass="fs-unmask">
        <AdBanner
          onPress={() => handleOverlayPress(advertOverlayModal)}
          style={
            isMediumAndDown
              ? {
                  minWidth: isSmallAndDown ? 350 : 500,
                  aspectRatio: isIframeAd ? undefined : 16 / 9,
                }
              : { minWidth: 700, aspectRatio: isIframeAd ? undefined : 16 / 9 }
          }
          ad={advertOverlayModal}
        />
      </View>
    </RModal>
  );

  const arrowRef = useRef(null);
  const {
    refs,
    floatingStyles,
    scrollProps,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement,
    update,
  } = useFloating({
    sameScrollView: false,
    middleware: [
      offset(POPUP_OFFSET),
      autoPlacement(),
      shift(),
      arrow({ element: arrowRef }),
    ],
  });
  const floatingPopup = useAtomValue(floatingPopupAtom);
  const placementShort = placement.split("-")[0];
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const staticSide = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[placementShort];

  // This useEffect adds the reference of the element that the floating popup should be attached to
  useEffect(() => {
    if (floatingPopup.reference) {
      refs.setReference(floatingPopup.reference.current);
    }
  }, [floatingPopup.reference]);

  // This is a workaround for the floating popup that pops top left before moving to the correct position
  // It should be fixed through a useLayoutEffect with next react-native release (0.75)
  useEffect(() => {
    if (floatingPopup.visible) {
      setTimeout(() => {
        setShouldShowPopup(true);
      }, POPUP_DELAY_MS);
    } else {
      setShouldShowPopup(false);
    }
  }, [floatingPopup.visible]);

  return (
    <>
      {Platform.OS !== "web" ? (
        <>
          <StatusBar style="light" />
          {!isGlobalFullScreen && (
            <View
              style={{
                backgroundColor: variables.colors.palette.rtv.primary,
              }}
            >
              <Gap size="xxxsmall" vertical />
            </View>
          )}
        </>
      ) : (
        <View style={{ display: isGlobalFullScreen ? "none" : "flex" }}>
          <Helmet>
            <link
              rel="sitemap"
              type="application/xml"
              title="Sitemap"
              href={`${BASE_URL}sitemap.xml`}
            />
            {/* defaults to meta_title but should be set via window.document.title when we
                want to autogenerate - see Racecards.tsx */}
            <title>{pageData?.page?.meta_title}</title>{" "}
            <meta
              name="description"
              content={autoMetaDescription || pageData?.page?.meta_description}
            />
            <link
              rel="canonical"
              href={pageData?.page?.canonical_url_path || window.location.href}
            />
            {!!window.location.pathname.includes("privacy") && (
              <meta name="robots" content="noindex" />
            )}
          </Helmet>
          <Header tabBarHeightValue={tabBarHeight} />
        </View>
      )}
      <ScrollView
        fsClass="fs-unmask"
        nativeID="appScrollView"
        accessibilityLabel={Platform.OS === "web" ? "Scroll Bar" : undefined}
        ref={appScrollRef}
        scrollEnabled={!isDrawerOpen && isScrollEnabled}
        contentContainerStyle={{ height: "100%" }}
        alwaysBounceVertical={false}
        scrollEventThrottle={10}
        {...scrollProps}
        onScroll={Platform.select({
          web: (e) => {
            update();
            const newOffset = e.nativeEvent.contentOffset.y;
            const scrollDifference = newOffset - currentOffset?.current;

            // are we close to the top
            if (newOffset < 200) {
              !tabBarTrigger && setTabBarTrigger(true);
              return;
            }

            if (!scrollDifference) return;

            // If you're scrolling down, reduce the height of the tab bar.
            if (scrollDifference > 0) {
              tabBarTrigger && setTabBarTrigger(false);
            }
            // If you're scrolling up, increase the height of the tab bar.
            else {
              !tabBarTrigger && setTabBarTrigger(true);
            }

            currentOffset.current = newOffset;
          },
          default: () => {},
        })}
      >
        {/* // we can stop this flash on web by not wrapping in suspense. but apps need it.. RSuspense? */}
        <RSuspense>
          <Drawer />
        </RSuspense>
        {!!isAuthModalOpen && (
          <AuthModal url={MEMBERS_APP_URL} type={isAuthModalOpen} user={user} />
        )}
      </ScrollView>

      {isWeb && (
        <View
          nativeID="floating-popup"
          ref={refs.setFloating}
          collapsable={false}
          style={[
            floatingStyles,
            isWeb && { visibility: shouldShowPopup ? "visible" : "hidden" },
          ]}
        >
          {floatingPopup.content}
          <View
            ref={arrowRef}
            collapsable={false}
            style={[
              styles.arrow,
              arrowX != null && { left: arrowX },
              arrowY != null && { top: arrowY },
              {
                [staticSide]: -POPUP_OFFSET,
                ...dynamicArrowStyle[placementShort],
              },
              isWeb && { visibility: shouldShowPopup ? "visible" : "hidden" },
            ]}
          />
        </View>
      )}
      <CalendarPickerCalendar navRef={navRef} />
      {showPreferenceHeader && isMediumAndDown ? <PreferenceHeader /> : null}
      {!isGlobalFullScreen && isMediumAndDown && !isDrawerOpen ? (
        <Animated.View
          style={{
            marginBottom:
              Platform.OS === "ios"
                ? tabBarAdBottomAmount?._value + 10
                : tabBarAdBottomAmount,
          }}
          onLayout={(event) => {
            if (tabAdvert) {
              setTabAdLayout(event.nativeEvent.layout);
              console.log(`EVENT: ${JSON.stringify(event.nativeEvent.layout)}`);
            }
          }}
        >
          {tabAdvert}
        </Animated.View>
      ) : null}
      {showTabBar ? (
        <TabBar
          showTabBar={tabBarTrigger}
          onLayout={(event) => {
            setTabLayout(event.nativeEvent.layout);
            // console.log(
            //   `TABBAR EVENT: ${JSON.stringify(event.nativeEvent.layout)}`
            // );
          }}
        />
      ) : null}
      {isEditDetailsModalOpen && !isGlobalFullScreen ? (
        <RModal
          style={{
            content: {
              maxWidth: 500,
              alignSelf: "center",
              marginBottom: isMediumAndDown ? undefined : "15%",
            },
          }}
          visible={isEditDetailsModalOpen}
          setVisibility={async () => handleUpdateDetailsModalClose()}
        >
          <UpdateDetailsModal cancelFunction={handleUpdateDetailsModalClose} />
        </RModal>
      ) : null}

      {showUpdateModal && updateModal()}

      {!!Object.keys(advertOverlayModal).length &&
        !isGlobalFullScreen &&
        adModal}

      <Modal
        visible={!!globalPlayerSource.source}
        transparent={true}
        supportedOrientations={["portrait", "landscape"]}
      >
        {!isGlobalFullScreen && (
          <Pressable
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            onPress={() =>
              setGlobalPlayerSource({
                source: "",
                preroll: undefined,
              })
            }
          />
        )}
        <Suspense fallback={<LoadingSpinner />}>
          <View
            style={{
              position: "absolute",
              alignSelf: "center",
              top: isIos && !isGlobalFullScreen ? 49 : 0,
              bottom: isSmallAndDown ? "auto" : 0,
              margin: "auto",
              width: isSmallAndDown || isGlobalFullScreen ? "100%" : "50%",
              height: isWeb ? "fit-content" : "25%",
            }}
          >
            <RVideoPlayer
              source={globalPlayerSource.source}
              displayType="embedded"
              onClose={() =>
                setGlobalPlayerSource({
                  source: "",
                  preroll: undefined,
                })
              }
              advertisement={
                globalPlayerSource?.preroll && {
                  actionDomain: globalPlayerSource?.preroll?.action_domain,
                  actionText: globalPlayerSource?.preroll?.action_text,
                  source: globalPlayerSource?.source,
                  logo: globalPlayerSource?.preroll?.icon
                    ?.placeholder_image_url,
                  prerollsId: globalPlayerSource?.preroll?.id,
                  onEnd: async () => {
                    const newSource =
                      await globalPlayerSource.getNewSourceAfterAd();
                    setGlobalPlayerSource({
                      source: newSource,
                    });
                  },
                }
              }
            />
          </View>
        </Suspense>
      </Modal>
    </>
  );
};

// this safely enables apple pay on 'real' ios devices
// which is the only environment it works without crashing in.
// it's not needed in the simulator or on android, so standard app
// is returned.
const wrappedApp = APPLE_PAY_AVAILABLE ? withIAPContext(App) : App;

export default wrappedApp;

const styles = StyleSheet.create({
  arrow: {
    position: "absolute",
    width: 12,
    height: 12,
    borderWidth: 1,
    borderColor: variables.colors.palette.neutral.x200,
    transform: [{ rotate: "45deg" }],
    backgroundColor: "white",
  },
});

const dynamicArrowStyle = {
  bottom: {
    borderBottomColor: "transparent",
    borderRightColor: "transparent",
  },
  top: {
    borderTopColor: "transparent",
    borderLeftColor: "transparent",
  },
  right: {
    borderTopColor: "transparent",
    borderRightColor: "transparent",
  },
  left: {
    borderLeftColor: "transparent",
    borderBottomColor: "transparent",
  },
};
