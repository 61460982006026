import { StyleSheet, Text, View } from "react-native";
import React from "react";
import RHeading from "../RHeading/RHeading";
import { TextLoader } from "../States/Loading/Loaders";
import { RTextColors } from "../RText/RText";

type Props = {
  fallback: string | React.ReactNode;
  level: "1" | "2" | "3" | "4" | "5" | "6";
  cmsTitle: string;
  showLoader: boolean;
  color?: RTextColors;
};

const CmsHeading = ({
  fallback,
  level,
  cmsTitle,
  showLoader,
  color = "primary",
}: Props) => {
  if (showLoader) return <TextLoader height={30} />;

  return (
    <RHeading uppercase={level === "1"} level={level} color={color}>
      {cmsTitle || fallback}
    </RHeading>
  );
};

export default CmsHeading;

const styles = StyleSheet.create({});
