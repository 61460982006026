import { Linking, Platform, ViewStyle, Pressable } from "react-native";
import React, { useState } from "react";
import CmsContent from "../../cms/CmsContent";
import { AdvertDetails, isIframeAd, isInsAd } from "../../../types/advert";
import WebView from "react-native-webview";
import RImageBackground from "../RImageBackground/RImageBackground";
import { advertClick } from "../../../client/adverts";

type Props = {
  ad: AdvertDetails;
  style: ViewStyle;
  onPress?: Function;
  resizeMode?: "cover" | "contain" | "stretch" | "repeat" | "center";
  header?: boolean;
  skyscraper?: boolean;
  heightOffset?: number;
  accessibilityLabel?: string;
};

const AdBanner = ({
  ad,
  style,
  onPress,
  resizeMode = "contain",
  header = false,
  skyscraper = false,
  heightOffset = 0,
  accessibilityLabel = undefined,
}: Props) => {
  const [error, setError] = useState(false);

  if (error || !ad) return null;
  if (isIframeAd(ad)) {
    if (isInsAd(ad)) {
      if (Platform.OS === "web") {
        // Iframe ad where we inject the ins tag
        //

        let pattern = /width:(\d+)px;height:(\d+)px/;

        let match = ad.iframe?.match(pattern);

        let width = null;
        let height = null;
        if (match) {
          width = match[1];
          height = match[2]; //
          console.log(`Width: ${width}px, Height: ${height}px`);
        }

        return (
          <iframe
            aria-label={accessibilityLabel}
            title="Advert"
            srcDoc={ad.iframe}
            width={parseInt(width) + 10 || 170} // 10 seems to be the extra bit we lose on web
            height={parseInt(height) + 10 + (heightOffset || 20) || 300} // 10 seems to be the extra bit we lose on web
            frameBorder={0}
          />
        );
      } else {
        // extract styles from the iframe
        let pattern = /width:(\d+)px;height:(\d+)px/;
        let match = ad.iframe?.match(pattern);
        let width = null;
        let height = null;
        if (match) {
          width = match[1];
          height = match[2];
        }

        return (
          <WebView
            accessibilityLabel={accessibilityLabel}
            originWhitelist={["*"]}
            scalesPageToFit={false}
            javaScriptEnabled={true}
            style={{
              width: "100%", // these styles affect the box but not the content. Until we see a
              height: height ? Number(height) + 20 : 300, // an ins add on the apps, will leave this as is.
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              opacity: 0.99, // yeah really. https://github.com/react-native-webview/react-native-webview/issues/811#issuecomment-614152848
            }}
            source={{
              html: `
              <!DOCTYPE html>
              <html lang="en">
                <head>
                <meta content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" name="viewport">
                <style>
                body {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;

                }

                    </style>
                </head>
                <body>
                <div>
                  ${ad.iframe}
                  </div>
                <div />
                </body>
              </html>


              `,
            }}
            onShouldStartLoadWithRequest={(request) => {
              // open external link in browser
              //this only seems to run on ios
              //runs on mount and on every link click
              if (request.url !== "about:blank" && request.isTopFrame) {
                Linking.canOpenURL(request.url).then(
                  (supported) => supported && Linking.openURL(request.url)
                );

                return false;
              } else return true;
            }}
          />
        );
      }
      // return <CmsContent content={ad.iframe} />;
    }
    return (
      <CmsContent
        accessibilityLabel={accessibilityLabel}
        content={ad.iframe}
        allContent={false}
        header={header}
        skyscraper={skyscraper}
        style={style}
      />
    );
  }

  return (
    <Pressable
      accessibilityLabel={accessibilityLabel}
      key={ad.campaign_id}
      onPress={async () =>
        (onPress && (await onPress())) || (await advertClick(ad.link_url))
      }
    >
      <RImageBackground
        ad={ad}
        style={style}
        resizeMode={resizeMode}
        setError={setError}
      />
    </Pressable>
  );
};

export default AdBanner;
