import React, { FC, useState, useEffect } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { useAtom } from "jotai";
import Icon from "../../../../Icon";
import RText from "../../../../RText/RText";
import {
  showOddsCheckerOnRacecardAtom,
  showTrackerOnRacecardAtom,
  showFormOnRacecardAtom,
  showTimeformOnRacecardAtom,
} from "../../../../../atoms";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import { Odd, Runner } from "../../../../../types/races/runner";
import { TrackedHorseComment } from "../../../Tracker/components/TrackedHorseComment";
import { HorseFormTable } from "../../../More/Horse/components/FormTable";
import { getHorseProfile } from "../../../../../client/more/horse";
import {
  Performance,
  HorseTrackerType,
} from "../../../../../types/races/horse";
import SquarePriceButton from "../../../../Buttons/SquarePriceButton";
import GridLayout from "../../../../Layout/GridLayout/GridLayout";
import { PriceFormat } from "../../../../../types/tipster";
import { formatCountryCode } from "../../../../../utils/race";
import TimeformIcon from "../../../../Icon/TimeformIcon";
import { sortOddsByPriority } from "../../../../../client/odds";
import { OddBookmakers } from "../../../../../types/bookmaker";
import RScrollView from "../../../../RScrollView";
import Gap from "../../../../Gap";

// see form table
type Props = {
  runner: Runner;
  priceFormat: PriceFormat;
  displaysPremiumData: boolean;
  bookmakers: OddBookmakers;
  refreshListOfTrackedHorses: Function;
  trackedHorse?: HorseTrackerType;
  isExpanded?: boolean;
};

const ExpandedRacecardRunnerRow: FC<Props> = ({
  runner,
  priceFormat = "fractional",
  displaysPremiumData,
  bookmakers,
  refreshListOfTrackedHorses,
  trackedHorse,
  isExpanded = false,
}) => {
  const { isSmallAndDown } = useResponsive();
  const [showTracker] = useAtom(showTrackerOnRacecardAtom);
  const [showForm] = useAtom(showFormOnRacecardAtom);
  const [showTimeform] = useAtom(showTimeformOnRacecardAtom);

  const [error, setError] = useState();
  const [formRecords, setFormRecords] = useState<Performance[]>();
  const [formRecordsLoading, setFormRecordsLoading] = useState(true);
  const [showOddsChecker] = useAtom(showOddsCheckerOnRacecardAtom);

  useEffect(() => {
    fetchHorseData();
  }, []);

  const fetchHorseData = async () => {
    try {
      const response = await getHorseProfile(runner.horse.slug);
      setFormRecords(response.horse.performances);
    } catch (error) {
      setError(error);
    } finally {
      setFormRecordsLoading(false);
    }
  };

  return (
    <View style={{ paddingLeft: isSmallAndDown ? 0 : 60 }}>
      <View
        style={[
          styles.horseDataContainer,
          { width: isSmallAndDown ? "100%" : "65%" },
        ]}
      >
        <View style={styles.horseData}>
          <RText color="grey">Col./Sex: </RText>
          <RText weight="semiBold">
            {runner.horse.colors[0]} {runner.horse.gender}
          </RText>
        </View>
        <View style={styles.horseData}>
          <RText color="grey">S: </RText>
          {!!runner.horse.sire_name && (
            <RText weight="semiBold">
              {runner.horse.sire_name}{" "}
              {!!runner.horse.country_code?.racing &&
                runner.horse.country_code?.racing !== "GBR" &&
                `(${formatCountryCode(runner.horse.country_code?.racing)})`}
            </RText>
          )}
        </View>
        <View style={styles.horseData}>
          <RText color="grey">D: </RText>
          {!!runner.horse.dam_name && (
            <RText weight="semiBold">
              {runner.horse.dam_name}{" "}
              {!!runner.horse.country_code?.racing &&
                runner.horse.country_code?.racing !== "GBR" &&
                `(${formatCountryCode(runner.horse.country_code?.racing)})`}
            </RText>
          )}
        </View>
      </View>

      {showTimeform ? (
        <View style={styles.timeFormText}>
          <View
            style={{
              height: 25,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              name="timeform"
              style={{
                width: 54.83,
                height: 12,
                alignItems: "center",
                alignSelf: "center",
                marginRight: 5,
              }}
            />
          </View>
          <View
            style={{ marginRight: 5, flexDirection: "row", marginBottom: 2 }}
          >
            {[...Array(5)].map((_, i) => (
              <RText
                key={i}
                style={{ marginRight: i === 4 ? 5 : 1 }}
                color={i + 1 <= runner.timeform_star_rating ? "black" : "grey"}
              >
                ★
              </RText>
            ))}
          </View>
          <RText>
            {runner.analyst_comment}{" "}
            {displaysPremiumData &&
              runner.icon_badges?.map((badge, i) => (
                <TimeformIcon badge={badge} key={i} />
              ))}
          </RText>
        </View>
      ) : null}

      {showForm && isExpanded ? (
        <HorseFormTable
          type="horse"
          form={formRecords}
          loading={formRecordsLoading}
          tableTitle={false}
          expandFormBtn
          limit={6}
        />
      ) : null}

      <TrackedHorseComment
        horseId={runner.horse?.id || runner.id}
        horseName={runner.horse.name}
        isExpanded={showTracker}
        withControls
        refreshListOfTrackedHorses={refreshListOfTrackedHorses}
        trackedHorse={trackedHorse}
      />
      <Gap vertical size="small" />
      {!!runner?.odds && showOddsChecker && (
        <Odds
          runner={runner}
          priceFormat={priceFormat}
          bookmakers={bookmakers}
        />
      )}
    </View>
  );
};

const Odds = ({
  runner,
  priceFormat,
  bookmakers,
}: {
  runner: Runner;
  priceFormat: PriceFormat;
  bookmakers: OddBookmakers;
}) => {
  const scrollRef = React.useRef<ScrollView>(null);
  const { isLargeAndUp } = useResponsive();

  if (isLargeAndUp)
    return (
      <GridLayout
        flexBasis={{
          gap: 300,
          item: 60,
        }}
        styleOverrides={{ paddingTop: 0 }}
        titleMaxWidth={70}
      >
        {sortOddsByPriority(runner.odds, bookmakers).map((odd, i) => {
          return (
            <SquarePriceButton
              key={i}
              odd={odd}
              priceFormat={priceFormat}
              size="large"
              bold
              onPress={() => {}}
              bookmakers={bookmakers}
            />
          );
        })}
      </GridLayout>
    );

  return (
    <View style={{ marginBottom: 10 }}>
      <RScrollView
        scrollRef={scrollRef}
        elementDistance={60 + variables.spacing.small}
        horizontal
        showsHorizontalScrollIndicator={false}
        childrenWidth={60 * runner.odds?.length}
      >
        {sortOddsByPriority(runner.odds, bookmakers).map((odd, i) => {
          return (
            <SquarePriceButton
              key={i}
              odd={odd}
              priceFormat={priceFormat}
              size="large"
              bold
              onPress={() => {}}
              bookmakers={bookmakers}
              style={
                i !== runner.odds.length - 1 && {
                  marginRight: variables.spacing.small,
                }
              }
            />
          );
        })}
      </RScrollView>
    </View>
  );
};

export default ExpandedRacecardRunnerRow;

const { spacing } = variables;
const styles = StyleSheet.create({
  horseDataContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "70%",
    justifyContent: "space-between",
  },
  horseData: {
    flexDirection: "row",
    margin: spacing.xxxsmall,
  },
  timeFormText: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
  },
});
