import React from "react";
import { Platform, StyleSheet } from "react-native";
import RText from "../RText/RText";
import { RLink } from "./RLink";
import variables from "../../styles/variables";

interface RLinkButtonProps {
  href?: string | undefined;
  slug?: string | undefined;
  text: string;
  backgroundColor?: string;
  textColor?: string;
  onPressCallback?: () => void;
}

// This component is a link in button form.
// For simple links see RLink.

// This could be extended to include internal navigation
// but there weren't any uses in the codebase at the time of writing.
// If that is the case, we should use the pressableProps to
// ensure the internal navigation link creates an <a> tag on web.
const RLinkButton = ({
  href = undefined,
  slug = undefined,
  text,
  backgroundColor = variables.colors.palette.rtv.secondary,
  textColor = "white",
  onPressCallback = () => {},
}: RLinkButtonProps) => (
  <RLink
    slug={slug} // passing a slug will create an internal navigation link
    href={href} // passing an href will create an external navigation link
    // no idea what happens if you do both...
    type="href"
    pressableStyle={[
      styles.button,
      Platform.OS === "web"
        ? {
            justifyContent: "center",
          }
        : {
            justifyContent: "center",
            paddingHorizontal: 6,
            height: 35,
          },

      // match the border color to the background color
      { backgroundColor: backgroundColor, borderColor: backgroundColor },
    ]}
    onPressCallback={onPressCallback}
  >
    <RText
      color={textColor}
      weight="bold"
      style={styles.text}
      dynamicTextStyle={{}}
    >
      {text}
    </RText>
  </RLink>
);

export default RLinkButton;

const styles = StyleSheet.create({
  button: {
    borderRadius: 3,
    borderWidth: 1.5,
    userSelect: "none",
  },
  text: {
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 6,
    ...Platform.select({
      native: {
        lineHeight: 20, // centers text vertically
      },
      default: {},
    }),
  },
});
