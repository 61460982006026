import React, { useState } from "react";
import { StyleSheet, View, Platform, ViewStyle, TextStyle } from "react-native";
import variables from "../../styles/variables";
import RText, { RTextSize } from "../RText/RText";
import Icon from "../Icon/Icon";
import { useResponsive } from "../../hooks/useResponsive";
import usePressableProps from "../../hooks/usePressableProps";
import RTouchableOpacity from "../RLink/RTouchableOpacity";

type Props = {
  navigateTo?: any;
  text: string;
  queryParams?: any;
  dark?: boolean;
  navOverride?: () => void;
  size?: RTextSize;
  style?: ViewStyle;
  textStyle?: TextStyle;
};

const { spacing } = variables;

const ShowMore: React.FC<Props> = ({
  navigateTo,
  text,
  queryParams,
  dark = false,
  navOverride,
  style,
  textStyle,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isSmallAndDown } = useResponsive();

  const pressableProps = navOverride
    ? {}
    : usePressableProps({
        screen: navigateTo,
        params: queryParams,
      });

  const lineHeights = {
    web: 28,
    ios: 20,
    android: 20,
  };

  const largeLineHeights = {
    web: 20,
    ios: 28,
    android: 22,
  };

  return (
    <RTouchableOpacity
      onPress={navOverride ? navOverride : undefined}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={[
        {
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: 4,
        },
        isHovered && styles.hover,
        style,
      ]}
      {...pressableProps}
    >
      <RText
        size={isSmallAndDown ? "sm" : "md"}
        lineHeight={
          isSmallAndDown
            ? lineHeights[Platform.OS]
            : largeLineHeights[Platform.OS]
        }
        color={dark ? "white" : "primary"}
        style={[
          {
            alignItems: "center",
            justifyContent: "flex-end",
            textAlign: "right",
            minHeight: 20,
            ...(Platform.OS === "android" ? { marginRight: 4 } : {}),
          },
          textStyle,
        ]}
      >
        {text}
      </RText>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginLeft: Platform.OS === "android" ? 0 : spacing.xxsmall,
        }}
      >
        <Icon
          name={dark ? "chevronBoldRightWhite" : "chevronBoldRight"}
          size="small"
        />
      </View>
    </RTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  hover: {
    transform: [{ scale: 1.1 }],
  },
});

export default ShowMore;
