import { deep_value } from "./deepValue";
import variables from "../styles/variables";

// Determine whether color in variables file should be used or hex

export const getColorValue = (color: string) => {
  // First check if given color is nested in the colors object. If so, use that color
  // If not, check if fill is a valid hex color string. If so, use that color
  const colorValue = deep_value(variables.colors, color);
  if (colorValue) {
    return colorValue;
  } else if (isValidHexColor(color)) {
    return color;
  }
  // Otherwise default to black
  return "#000000";
};

// Function to validate hex color strings
const isValidHexColor = (hex: string): boolean => {
  return /^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(hex);
};
