import React, { useEffect } from "react";
import {
  View,
  StyleSheet,
  Platform,
  useWindowDimensions,
  ViewStyle,
} from "react-native";
import RText from "../../RText";
import variables from "../../../styles/variables";
import { useAtom } from "jotai";
import { pageDataAtom, openAuthModalAtom } from "../../../atoms";
import Layout from "../../Layout/Layout";
import Icon from "../../Icon/Icon";

// If there is no content to display and the data request responds with an error, display this component

type Props = {
  iconType?: string | null;
  message?: Error | string;
  style?: ViewStyle;
  inLayout?: boolean;
  component?: React.ReactNode;
  dark?: boolean;
};

const { spacing, colors } = variables;

const ErrorState: React.FC<Props> = ({
  iconType = "warning",
  message,
  style = { marginVertical: spacing.small },
  inLayout = false,
  component,
  dark = false,
}) => {
  const [pageData] = useAtom(pageDataAtom);
  const [_, setAuthModalOpen] = useAtom(openAuthModalAtom);

  const { width } = useWindowDimensions();
  const isSmallScreen = width < 567;

  useEffect(() => {
    if (!pageData) return;
    if (pageData?.error && pageData?.meta?.status === 401) {
      setAuthModalOpen("login");
    }
    if (pageData?.error && pageData?.meta?.status === 403) {
      if (Platform.OS !== "ios") setAuthModalOpen("upgrade");
    }
  }, [pageData]);

  let messageStr: string =
    typeof message === "object" ? message.message : message;

  if (pageData?.meta?.status === 401)
    messageStr = "You must be signed in to access this page";
  if (pageData?.meta?.status === 403)
    messageStr = "You must be a member to access this page";

  const Content = () => (
    <View
      style={[
        style,
        styles.container,
        isSmallScreen && !dark
          ? {
              aspectRatio: 1,
            }
          : {},
        {
          backgroundColor: dark ? "transparent" : colors.palette.neutral.x100,
        },
        { minHeight: iconType ? 200 : 100 },
      ]}
    >
      {iconType ? (
        <Icon
          name={iconType}
          size="xxxlarge"
          style={styles.icon}
          fill={dark ? "palette.neutral.x0" : "palette.neutral.x500"}
          stroke={dark ? "palette.neutral.x0" : "palette.neutral.x500"}
        />
      ) : null}
      <RText
        family="secondary"
        weight="bold"
        color={dark ? "white" : "grey"}
        uppercase
        style={[styles.text, style]}
      >
        {messageStr || "Sorry, something went wrong"}
      </RText>
      {component ? component : null}
    </View>
  );
  return inLayout ? (
    <Layout content={<Content />} headingLoader={false} />
  ) : (
    <Content />
  );
};

export default ErrorState;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  icon: {
    marginTop: 20,
  },
  text: { textAlign: "center" },
});
