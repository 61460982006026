import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import { FRONTEND_URL, fetchPost } from "..";
import { APIResponse } from "../../types/responses/APIResponse";
import { Dimensions, Platform } from "react-native";
import { buildPath } from "../../utils/buildPath";
import linking from "../../components/Navigation/URLMapper";
import FullStory from "../../services/FullStory";
import { sendAnalyticsEventAsync } from "../firebase/analytics";
import { analytics } from "../firebase/analytics/index";

export const postPageView = async (
  width: string,
  height: string,
  url: string,
  route: string
): Promise<APIResponse<any>> => {
  try {
    return fetchPost(
      "onspace/analytics/tracking/page-view",
      {},
      {
        session: {
          width: width,
          height: height,
        },
        page: {
          url: url,
          route: route.replaceAll("?", ""),
        },
      }
    );
  } catch (e) {
    console.log("error tracking pageview: ", route);
  }
};

export const trackPageView = (
  navRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
) => {
  if (!navRef) return;

  // e.g /racecards/2024-01-01 - GA needs these.
  const builtPath = buildPath(navRef);

  // e.g /racecards/:date? - onspace needs these
  let path =
    linking.config.screens[navRef.getCurrentRoute()?.name]?.path ||
    linking.config.screens[navRef.getCurrentRoute()?.name];

  console.log("[pageview] tracking pageview: ", builtPath);

  // we specifically build screen view as that shows in GA under the Pages and Screens report which RTV use.
  // it shows the real path of the page, not the route name, e.g /racecards/2024-01-01
  // web handles this automatically so we return early in that instance
  if (Platform.OS != "web") {
    if (builtPath) {
      const data = {
        screen_name: builtPath,
        screen_class: builtPath,
      };
      console.log("[pageview] trackPageView:: logging screen view: ", data);

      analytics().logScreenView(data);
    } else {
      console.log(
        "[pageview] trackPageView:: path is null so not logging screen view"
      );
    }
  }

  // set / to /home for onspace
  let url = `${FRONTEND_URL}${builtPath}`;
  const homeUrl = `${FRONTEND_URL}/`;

  if (url == homeUrl) {
    url = `${FRONTEND_URL}/home`;
    path = "/home";
  }

  // this goes to onspace
  return postPageView(
    // screenWidth,
    Dimensions.get("window").width.toString(),
    Dimensions.get("window").height.toString(),
    url,
    path
  );
};

export const logEvent = async ({ key, data }: { key: string; data: any }) => {
  try {
    FullStory.event(key, data);
    console.log(`[FullStory] sent ${key} event with: `, data);
  } catch (e) {
    console.log(`[FullStory] failed to send ${key} event with ${data}: `, e);
  }

  const dataWithTimestamp = { ...data, timestamp: Date.now() };
  try {
    await sendAnalyticsEventAsync(key, dataWithTimestamp);
    console.log(`[GA] sent ${key} event with: `, dataWithTimestamp);
  } catch (e) {
    console.log(
      `[GA] failed to send ${key} event with ${dataWithTimestamp}: `,
      e
    );
  }
};
