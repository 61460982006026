import React, { useRef, useState } from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";
import variables from "../../../styles/variables";
import { RTextColors } from "../../RText/RText";
import { Picker } from "@react-native-picker/picker";
import { RTextWeight, RTextSize } from "../../RText/RText";

const { colors: fc, weight: fw, size: fs, family: ff } = variables.font;

const { rtv } = variables.colors.palette;
const { primary: blue } = rtv;
const isWeb = Platform.OS === "web";

type Props = {
  data: any[];
  onSelect: (selectedItem?) => void;
  selectedOption?: string;
  defaultText?: string;
  borderStyle?: {
    color: RTextColors;
    width: number;
  };
  containerStyle?: ViewStyle | ViewStyle[];
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between";
  fontStyle?: {
    color?: RTextColors;
    size?: RTextSize;
    weight?: RTextWeight;
    uppercase?: boolean;
    family?: "primary" | "secondary";
    capitalize?: boolean;
  };
  displayOnlyChevron?: boolean;
  placeholderAlwaysVisible?: boolean;
  setCategorySelectIsOpen?: (val: boolean) => void;
};

const Dropdown = ({
  data,
  onSelect,
  selectedOption = null,
  defaultText,
  borderStyle,
  containerStyle,
  justifyContent = "flex-end",
  fontStyle,
  displayOnlyChevron,
  placeholderAlwaysVisible,
}: Props) => {
  const pickerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const showLabels = placeholderAlwaysVisible ? isFocused : true;

  const handlePress = (selectedItem?) => {
    if (defaultText && selectedItem === defaultText) return;
    onSelect(selectedItem);
    if (isWeb) {
      pickerRef.current.blur();
    }
  };

  const passedInStyles = Array.isArray(containerStyle)
    ? StyleSheet.flatten(containerStyle)
    : containerStyle;

  // TODO: This is a good example of a slide out animation running on the UI thread
  // We should use this in more places than just the dropdown, where we're currently fading in and out

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Picker
      ref={pickerRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      nativeID={`picker${data.length}`}
      selectedValue={selectedOption || defaultText}
      onValueChange={(itemValue) => handlePress(itemValue)}
      style={[
        {
          borderColor: borderStyle?.color ? fc[borderStyle.color] : "none",
          borderWidth: borderStyle?.width ? borderStyle.width : 0,
          borderStyle: borderStyle?.width ? "solid" : null,
          borderRadius: borderStyle?.width ? 5 : null,
          fontFamily: `${
            ff[fontStyle?.family ? fontStyle.family : "primary"]
          }_${fw[fontStyle?.weight ? fontStyle?.weight : "regular"]}`,
          color: fontStyle?.color ? fontStyle.color : blue,
          fontSize: fs[fontStyle?.size ? fontStyle.size : "lg"],
          textTransform: fontStyle?.uppercase ? "uppercase" : null,
          justifyContent: justifyContent,
          ...passedInStyles,
        },
        displayOnlyChevron && { width: variables.spacing.xsmall },
      ]}
    >
      {defaultText && (
        <Picker.Item
          style={{ padding: 10 }}
          label={defaultText.capitalize()}
          value={defaultText.toLowerCase()}
        />
      )}
      {showLabels && data.length ? (
        data.map((option: string, i: number) => (
          <Picker.Item
            style={{ padding: 10 }}
            key={i}
            label={option.capitalize()}
            value={option.toLowerCase()}
          />
        ))
      ) : (
        <Picker.Item
          style={{ padding: 10 }}
          key={1}
          label={"no options available"}
          value={null}
        />
      )}
    </Picker>
  );
};

export default Dropdown;
