import React, { useState } from "react";
import {
  StyleSheet,
  Pressable,
  Text,
  ViewStyle,
  TextStyle,
  View,
  Platform,
} from "react-native";
import variables from "../../styles/variables";
import Icon from "../Icon";
import RText from "../RText";
import { useResponsive } from "../../hooks/useResponsive";

export type RButtonProps = {
  title?: string;
  color?: "primary" | "secondary" | "tertiary" | "disable";
  initialBehaviour?: "hover" | "selected" | "default";
  disabled?: boolean;
  behaviour?: "default" | "hover" | "selected";
  children?: React.ReactNode;
  style?: ViewStyle;
  borderStyle?: ViewStyle;
  onPress?: Function;
  customTextStyle?: TextStyle;
  locked?: boolean;
  wide?: boolean;
  accessibilityLabel?: string;
};

export default function RButton(props: RButtonProps) {
  const {
    title,
    onPress = () => console.log(`pressed ${title}`),
    color = "primary",
    initialBehaviour = "default",
    disabled = false,
    children,
    style,
    borderStyle,
    customTextStyle,
    locked = false,
    wide = false,
    accessibilityLabel = undefined,
  } = props;

  let activeColor = disabled ? "disable" : color;

  const { isSmallAndDown } = useResponsive();

  const [behaviour, setBehaviour] = useState(initialBehaviour);

  const handleOnPress = () => {
    if (disabled) return;
    setBehaviour(initialBehaviour);
    onPress();
  };

  return (
    <View style={[{ alignItems: "center" }]}>
      <Pressable
        accessibilityLabel={accessibilityLabel}
        style={[
          styles.button,
          borderStyle,
          colorStyles[activeColor][behaviour],
          locked ? { opacity: 0.3 } : null,
          wide ? { minWidth: "100%" } : null,
          { ...style },
          { height: isSmallAndDown ? 35 : 40 },
        ]}
        onHoverIn={() => setBehaviour("hover")}
        onHoverOut={() => setBehaviour(initialBehaviour)}
        onPressIn={() => setBehaviour("selected")}
        onPress={() => {
          if (disabled) return;
          setBehaviour(initialBehaviour);
          onPress();
        }}
      >
        <RText
          style={[
            { textAlign: "center", fontSize: 14 },
            textStyle[activeColor],
            behaviour === "selected" ? textStyle.whiteSelected : null,
            { ...customTextStyle },
          ]}
        >
          {title}
        </RText>
        {children}
      </Pressable>
      <Pressable
        style={{ alignItems: "center", position: "absolute" }}
        onPress={handleOnPress}
      >
        {locked ? (
          <Icon
            name="lock"
            size="large"
            style={{ position: "absolute", opacity: 1 }}
          />
        ) : null}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    justifyContent: "center",
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRadius: 3,
    borderWidth: 1.5,

    userSelect: "none",
  },
});

const {
  default: defaultStyle,
  outline,
  alternate,
  disabled,
} = variables.colors.palette.buttons;

// color themes for different behaviours - they are used in eval function
const primary = StyleSheet.create({
  default: {
    backgroundColor: defaultStyle.backgroundColor,
    borderColor: defaultStyle.borderColor,
  },
  hover: {
    backgroundColor: defaultStyle.hovered.backgroundColor,
    borderColor: defaultStyle.hovered.borderColor,
    ...Platform.select({
      web: {
        pointer: "cursor",
      },
    }),
  },
  selected: {
    backgroundColor: defaultStyle.selected.backgroundColor,
    borderColor: defaultStyle.selected.borderColor,
  },
});

const secondary = StyleSheet.create({
  default: {
    backgroundColor: outline.backgroundColor,
    borderColor: outline.borderColor,
  },
  hover: {
    backgroundColor: outline.hovered.backgroundColor,
    borderColor: outline.hovered.borderColor,
    ...Platform.select({
      web: {
        pointer: "cursor",
      },
    }),
  },
  selected: {
    backgroundColor: outline.selected.backgroundColor,
    borderColor: outline.selected.borderColor,
  },
});

const tertiary = StyleSheet.create({
  default: {
    backgroundColor: alternate.backgroundColor,
    borderColor: alternate.borderColor,
  },
  hover: {
    backgroundColor: alternate.hovered.backgroundColor,
    borderColor: alternate.hovered.borderColor,
    ...Platform.select({
      web: {
        pointer: "cursor",
      },
    }),
  },
  selected: {
    backgroundColor: alternate.selected.backgroundColor,
    borderColor: alternate.selected.borderColor,
  },
});

const disable = StyleSheet.create({
  default: {
    backgroundColor: disabled.backgroundColor,
    borderColor: disabled.borderColor,
    // pointerEvents: "none",
    cursor: "not-allowed",
  },
  hover: {
    backgroundColor: disabled.backgroundColor,
    borderColor: disabled.borderColor,
    cursor: "not-allowed",
  },
  selected: {
    backgroundColor: disabled.backgroundColor,
    borderColor: disabled.borderColor,
    cursor: "not-allowed",
  },
});

const textStyle = StyleSheet.create({
  primary: {
    color: defaultStyle.color,
  },
  secondary: {
    color: outline.color,
  },
  tertiary: {
    color: alternate.color,
  },
  disable: {
    color: disabled.text.color,
  },
  whiteSelected: {
    color: defaultStyle.color,
  },
});

// Map styles to color names
const colorStyles = {
  primary,
  secondary,
  tertiary,
  disable,
};
