import { Platform } from "react-native";
import { getAndroidId, getIosIdForVendorAsync } from "expo-application";

export const getDeviceId = async (): Promise<string | null> => {
  // For iOS, use iosIdForVendor
  // (remains the same for apps from the same vendor on the device)
  if (Platform.OS === "ios") return await getIosIdForVendorAsync();

  // For Android, use androidId
  // (remains the same until app data is cleared or the device is factory reset)
  if (Platform.OS === "android") return await getAndroidId();

  // Return null if no specific ID is available
  // (or if running on an unsupported platform like web)
  return null;
};
