import React, { useEffect, useMemo, useState } from "react";
import {
  StyleSheet,
  Pressable,
  ViewStyle,
  View,
  Linking,
  Platform,
} from "react-native";
import variables from "../../styles/variables";
import RText from "../RText";
import { PriceFormat, TipRunner } from "../../types/tipster";
import RImage from "../RImage/RImage";
import { Odd, Runner } from "../../types/races/runner";
import { DEFAULT_BOOKMAKER_IMAGE } from "../Image/images/defaultImages";
import { BASE_URL } from "../../client";
import { getBookmaker } from "../../client/odds";
import Race from "../../types/races/race";
import { Race as TipRace } from "../../types/tipster";
import { getRunnerFinishText } from "../../utils/getRunnerFinishText";
import { RectangleLoader } from "../States/Loading/Loaders";
import { Bookmaker, OddBookmaker } from "../../types/bookmaker";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms";
import { convertUserPriceFormat } from "../Screens/Account/components/Utils";
import useUser from "../../hooks/useUser";

type Behaviour = "default" | "hover";

type RButtonProps = {
  race: Race | TipRace;
  runner: Runner | TipRunner;
  priceFormat: PriceFormat;
  advisedPrice?: PriceFormat;
  startingPrice?: PriceFormat;
  odd?: Odd;
  size?: "large" | "medium" | "small";
  bookmaker?: Bookmaker | OddBookmaker;
  finishPlace?: number;
};

const { colors, sizes, spacing } = variables;

// The logic should be as follows:
// * 1. All upcoming races either today or tomorrow should have odds from oddschecker (best odds)
// * 2. All resulted races and most off races should have a starting price
// * 3. Tips will optionally have an advised price at the discretion of the tipster - reverting to bestOdds otherwise.

export default function StandardPriceButton({
  race,
  runner,
  priceFormat = "fractional",
  advisedPrice = null,
  startingPrice = null,
  odd,
  size = "small",
  bookmaker,
  finishPlace,
}: RButtonProps) {
  const [bmLogo, setBmLogo] = useState<string>();
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [behaviour, setBehaviour] = useState<Behaviour>("default");
  const [user] = useAtom(userAtom);
  const { isLoggedIn } = useUser();

  //If the user is logged in, use their preferred price format
  const oddsFormat = isLoggedIn ? convertUserPriceFormat(user) : priceFormat;

  const raceState = race?.status?.state;
  const runnerState = runner?.status?.state;

  const isNonRunner =
    runnerState === "scratched" ||
    runnerState === "withdrawn" ||
    runner?.withdrawn ||
    runner?.scratched;

  const isSP =
    !!odd?.price &&
    odd.price["decimal"] === "0.00" &&
    odd.price["fractional"] === "-1/1" &&
    odd.price["moneyline"] === "+100";

  const startingPriceData = startingPrice || runner?.starting_price;

  const finishPlaceData = finishPlace || runner?.finish_place;

  useEffect(() => {
    setContentLoading(true);
    if (isNonRunner) {
      setBmLogo(DEFAULT_BOOKMAKER_IMAGE);
      setContentLoading(false);
      return;
    }
    if (!odd) {
      setContentLoading(false);
      return;
    }
    if (bookmaker) {
      setBmLogo(
        bookmaker.logo_square?.viewer_sources[0]?.url ||
          bookmaker.logo_square?.placeholder_image_url
      );
      setContentLoading(false);

      return;
    }
    if (odd?.bookmaker?.logo_square) {
      setBmLogo(
        odd?.bookmaker?.logo_square?.viewer_sources[0]?.url ||
          odd?.bookmaker?.logo_square?.placeholder_image_url
      );
      setContentLoading(false);
      return;
    }

    if (odd?.bookmaker_id || odd?.bookmaker?.id) {
      getBookmaker(odd.bookmaker_id || odd.bookmaker?.id)
        .then((res) => {
          const { bookmaker } = res;
          setBmLogo(
            bookmaker?.logo_square?.viewer_sources[0]?.url ||
              bookmaker.logo_square?.placeholder_image_url
          );
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setContentLoading(false);
          return;
        });
    } else {
      setBmLogo(DEFAULT_BOOKMAKER_IMAGE);
      setContentLoading(false);
    }
  }, [odd]);

  if (contentLoading) return <RectangleLoader width={80} height={32} />;

  return (
    <>
      {/*For resulted races, show finish place, finish text and starting price*/}
      {raceState === "resulted" && !isNonRunner && (
        <View
          style={[
            {
              alignItems: "flex-end",
              marginTop: "auto",
              marginBottom: "auto",
              minWidth: 90,
            },
            finishPlaceData === 1 && styles.successfulTip,
          ]}
        >
          {(finishPlaceData || runner.finish_text) && (
            <RText>{`Finished: ${getRunnerFinishText(
              finishPlaceData,
              runner?.finish_text
            )}`}</RText>
          )}
          {startingPriceData && (
            <RText>{`SP: ${startingPriceData[oddsFormat]}`}</RText>
          )}
        </View>
      )}

      {/*For abandoned races, show 'abandoned' text only*/}
      {raceState === "abandoned" && <RText>Abandoned</RText>}

      {/*For void races, show 'race void' text only*/}
      {raceState === "void" && <RText>Race void</RText>}

      {/*For future or off races, show advised price for tips or best odds otherwise*/}
      {(raceState === "started" ||
        raceState === "scheduled" ||
        !raceState ||
        isNonRunner) && (
        <Button
          nonRunner={isNonRunner}
          size={size}
          // advisedPrice={advisedPrice}
          odd={odd}
          setBehaviour={setBehaviour}
        >
          <BookmakerLogo
            logoKey={`${runner?.id}-${odd}`}
            size={size}
            bmLogo={bmLogo}
            odd={odd}
          />
          <Odds
            size={size}
            behaviour={behaviour}
            isSP={isSP}
            isNonRunner={isNonRunner}
            odd={odd}
            // advisedPrice={advisedPrice}
            priceFormat={oddsFormat}
          />
        </Button>
      )}
    </>
  );
}

const Button = ({
  nonRunner,
  children,
  size,
  advisedPrice,
  odd,
  setBehaviour,
}) => {
  if (!advisedPrice && !odd) return;

  //Use a pressable for all buttons apart from non runners
  if (nonRunner)
    return (
      <View
        style={[
          styles.buttonContainer,
          { width: width(size) },
          { height: width(size) },
        ]}
      >
        {children}
      </View>
    );

  return (
    <Pressable
      onHoverIn={() => setBehaviour("hover")}
      onHoverOut={() => setBehaviour("default")}
      onPress={() => {
        Linking.openURL(`${BASE_URL}racing/runner/odds/${odd.id}/clickthrough`);
      }}
      style={[
        styles.buttonContainer,
        { width: width(size) },
        { height: width(size) },
      ]}
    >
      {children}
    </Pressable>
  );
};

type BookmakerLogoProps = {
  size: "large" | "medium" | "small";
  bmLogo: string;
  logoKey?: string;
  odd: Odd;
};

export const BookmakerLogo: React.FC<BookmakerLogoProps> = ({
  size,
  bmLogo,
  logoKey = "default", // ensure this is stable
  odd,
}) => {
  const renderedLogo = useMemo(() => {
    console.log("Rendering BookmakerLogo:", odd.bookmaker_id, bmLogo, size);

    return (
      <View
        key={logoKey}
        style={[
          styles.bmImageContainer,
          { width: width(size), height: width(size) },
        ]}
      >
        <RImage source={bmLogo} style={styles.bmImage} />
      </View>
    );
  }, [logoKey, bmLogo, size, odd.bookmaker_id]);

  return renderedLogo;
};

const Odds = ({
  size,
  behaviour,
  isSP,
  isNonRunner,
  odd,
  advisedPrice,
  priceFormat,
}) => {
  const displayText = () => {
    //If no odds are yet available, display "SP"
    if (isSP) return "SP";

    //If the runner is a non runner, display "NR"
    if (isNonRunner) return "NR";
    //If the advised price is available (i.e. for tips), display the advised price
    // if (advisedPrice) return advisedPrice[priceFormat];
    //Otherwise display the best odds
    else return odd?.price[priceFormat];
  };

  return (
    <View
      style={[
        styles.odds,
        behaviourStyle(behaviour),
        styles.removeLeftBorder,
        {
          width: width(size) * 1.5,
        },
      ]}
    >
      <RText weight="semiBold" size="md" color="primary">
        {displayText()}
      </RText>
    </View>
  );
};

const behaviourStyle = (behaviour: Behaviour): ViewStyle => {
  return styles[behaviour];
};

const width = (size) => {
  if (size == "small") return sizes.large.height;
  if (size == "medium") return sizes.xlarge.height;
  if (size == "large") return sizes.xxlarge.height;
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    minWidth: 90,
    justifyContent: "flex-end",
    alignSelf: "center",
    marginTop: 0.1,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  bmImageContainer: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: colors.palette.neutral.x200,
  },
  bmImage: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  odds: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderColor: colors.palette.buttons.alternate.borderColor,
    borderWidth: 1,
    borderStyle: "solid",
    flexDirection: "row",
  },
  removeLeftBorder: {
    borderLeftWidth: 0,
  },
  hover: {
    ...Platform.select({
      web: {
        pointer: "cursor",
      },
    }),
    backgroundColor: colors.palette.buttons.alternate.hovered.backgroundColor,
  },
  selected: {
    backgroundColor: colors.palette.buttons.alternate.borderColor,
  },
  successfulTip: {
    padding: spacing.xxsmall,
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: colors.palette.rtv.secondary,
    marginBottom: spacing.xxsmall,
  },
});
