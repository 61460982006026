import React from "react";
import { View, Platform, Dimensions } from "react-native";
import {
  FaqList,
  MediaAsset,
  ROffer,
  RunnerTip,
  RaceAttachment,
  DemandVideoAsset,
  RunnerAttachment,
} from "./components";
import { Attachment } from "../../types/attachment";
import VideoAsset from "./components/VideoAsset";
import YouTubeVideoAsset from "./YouTubeVideoAsset";
import RText from "../RText";

type Props = {
  type: Attachment;
  id: string;
  caption?: string;
  metadata?: any;
  options?: string;
  href?: string;
};
export const RAttachment = ({
  type,
  id,
  caption,
  metadata,
  options,
  href,
}: Props) => {
  if (metadata) {
    try {
      metadata = JSON.parse(metadata);
    } catch (e) {
      console.error("** RAttachment: error parsing metadata:", e);
    }
  }

  if (options) {
    try {
      options = JSON.parse(options);
    } catch (e) {
      console.error("** RAttachment: error parsing options:", e);
    }
  }

  const Attachment = () => {
    if (type == "site/faq_list") return <FaqList id={id} />;
    if (type == "onspace/media/asset/blob") {
      if (metadata?.content_type == "video")
        return <VideoAsset metadata={metadata} />;
      return (
        <MediaAsset id={id} metadata={metadata} caption={caption} href={href} />
      );
    }
    if (type == "betting/bookmaker_offer")
      return (
        <>
          <ROffer id={id} key={id} />
          {/* this is strange, but Gaps aren't applying on ios. The only
              thing that creates a gap is a View with some content in it.
          */}
          <View
            style={{
              width: "100%",
              height: 15,
            }}
          >
            <RText> </RText>
          </View>
        </>
      );
    if (type == "betting/runner_tip") return <RunnerTip id={id} />;
    if (type == "racing/race") {
      return <RaceAttachment id={id} options={options} />;
    }
    if (type == "racing/runner") return <RunnerAttachment id={id} />;
    if (type == "simple_stream/demand_video_asset")
      return <DemandVideoAsset id={id} metadata={metadata} />;
    if (type == "onspace/media/asset/you_tube_video")
      return <YouTubeVideoAsset id={id} metadata={metadata} />;
  };

  if (Platform.OS === "android" || Platform.OS === "ios")
    return (
      <View
        style={{
          width: Dimensions.get("window").width - 20,
        }}
      >
        <Attachment />
      </View>
    );
  return <Attachment />;
};
