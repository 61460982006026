import { StyleSheet, ViewStyle, View } from "react-native";
import React from "react";
import Icon from "../Icon";
import RImage from "../RImage/RImage";
import { BASE_URL } from "../../client";
import { IconSize } from "../Icon/Icon";
import variables from "../../styles/variables";

const { sizes } = variables;

type Props = {
  style?: ViewStyle;
  silk: any;
  size?: IconSize;
};

const RunnerSilk = ({ style, silk, size = "xlarge" }: Props) => {
  return (
    <View style={styles.container}>
      {silk?.path || silk?.press_association_file?.path ? (
        <RImage
          style={{
            objectFit: "cover",
            ...sizes[size],
            ...style,
          }}
          source={BASE_URL + (silk.path || silk.press_association_file?.path)}
        />
      ) : (
        <Icon style={style} name="silkDefault" size={size} />
      )}
    </View>
  );
};

export default React.memo(RunnerSilk);

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
});
