import {
  deleteTrackedHorse,
  updateTrackedHorse,
} from "../../../client/tracker";
import { toastError, toastSuccess } from "../../Toasts/Toasts";

type DeleteTrackedHorse = {
  id: number;
  name: string;
  toast: any;
};

export const handleDeleteTrackedHorse = async ({
  id,
  name,
  toast,
}: DeleteTrackedHorse): Promise<boolean> => {
  try {
    console.log("[TrackedHorseComment] deleting horse from tracker: ", id);
    const response = await deleteTrackedHorse(id);
    if (response.meta.status !== 200) {
      throw new Error(`${name} was not deleted`);
    }
    toastSuccess(toast, `${name} removed from tracker`);
    return true;
  } catch (error) {
    toastError(toast, `${name} was not removed from tracker`);
    return false;
  }
};

type UpdateTrackedHorse = {
  id: number;
  name: string;
  toast: any;
  comment?: string;
  notifications?: boolean;
};

export const handleUpdateTrackedHorse = async ({
  id,
  name,
  toast,
  comment,
  notifications,
}: UpdateTrackedHorse) => {
  try {
    console.log("[TrackedHorseComment] updating horse from tracker: ", name);
    const response = await updateTrackedHorse(id, comment, notifications);
    if (response.meta.status !== 200) {
      throw new Error(`${name} was not updated`);
    }
    toastSuccess(toast, `${name} was successfully updated`);
    return response;
  } catch (error) {
    toastError(toast, `${name} was not updated`);
  }
};
