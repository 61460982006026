import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
  PixelRatio,
} from "react-native";
import {
  appScrollRefAtom,
  appScrollRefAtom2,
  tabAdLayoutAtom,
  tabLayoutAtom,
} from "../atoms";

export const LambdatestScrollOverlay = () => {
  const ref = useRef(null);
  const buttonRef = useRef(null);

  const [tabAdLayout] = useAtom(tabAdLayoutAtom);
  const [tabLayout] = useAtom(tabLayoutAtom);

  const [buttonTop, setButtonTop] = useState(0);
  const [reachedBottom, setReachedBottom] = useState(false);

  const testScrollRef =
    Platform.OS === "web"
      ? useAtom(appScrollRefAtom)?.[0]
      : useAtom(appScrollRefAtom2)?.[0];

  const scrollPageDown = () => {
    if (ref.current) {
      ref.current.measureInWindow((x, y, width, height) => {
        const screenHeight = Dimensions.get("window").height;
        const topEdge = y;
        let bottomEdge = tabAdLayout?.y || tabLayout?.y || screenHeight;

        if (Platform.OS === "android" && tabAdLayout?.height) {
          bottomEdge -= tabAdLayout.height;
        }

        let scrollToY = Math.abs(bottomEdge - topEdge);
        const maxButtonY = scrollToY;

        // Ensure scrollToY is a multiple of the device's pixel ratio.
        const pixelRatio = PixelRatio.get();

        scrollToY = Math.floor(scrollToY * pixelRatio) / pixelRatio;

        let reachedBottom = Math.ceil(bottomEdge - topEdge) >= height;
        if (Platform.OS === "android" && tabAdLayout?.height) {
          reachedBottom =
            Math.ceil(bottomEdge - topEdge) >= height - tabAdLayout.height;
        }

        console.log(`[lambda] Pixel Ratio: ${pixelRatio}`);

        console.log(`[lambda] x: ${x}`);
        console.log(`[lambda] y: ${y}`);
        console.log(`[lambda] width: ${width}`);
        console.log(`[lambda] height: ${height}`);

        console.log(`[lambda] screenHeight: ${screenHeight}`);
        console.log(`[lambda] topEdge: ${topEdge}`);
        console.log(`[lambda] bottomEdge: ${bottomEdge}`);
        console.log(`[lambda] maxButtonY: ${maxButtonY}`);
        console.log(`[lambda] tabAdLayout?.y: ${tabAdLayout?.y}`);
        console.log(`[lambda] tabAdLayout?.height: ${tabAdLayout?.height}`);
        console.log(`[lambda] tabLayout?.y: ${tabLayout?.y}`);
        console.log(`[lambda] tabLayout?.height: ${tabLayout?.height}`);
        console.log(
          `[lambda] Adjusted scrollToY (pixel-aligned): ${scrollToY}`
        );
        console.log(`[lambda] Reached Bottom: ${reachedBottom}`);

        if (testScrollRef?.current) {
          console.log(`[lambda] test scroll ref exists`);
          if (scrollToY > 0) {
            testScrollRef.current.scrollTo({
              y: scrollToY,
              animated: false,
            });
          }
        }

        setButtonTop(maxButtonY);
        setReachedBottom(reachedBottom);
      });
    }
  };

  const scrollTop = () => {
    if (testScrollRef?.current) {
      console.log(`[lambda] test scroll ref exists`);

      testScrollRef.current.scrollTo({
        y: 0,
        animated: false,
      });
    }

    setButtonTop(0);
    setReachedBottom(false);
  };

  return (
    <View
      style={styles.overlayContainer}
      ref={ref}
      accessibilityLabel="LambdatestScrollOverlay"
      pointerEvents="box-none"
    >
      <TouchableOpacity
        style={[styles.scrollPageDown, { top: buttonTop }]}
        onPress={scrollPageDown}
        ref={buttonRef}
        accessibilityLabel="LambdatestScrollPageDown"
      />
      <TouchableOpacity
        style={[styles.scrollTop, { top: buttonTop }]}
        onPress={scrollTop}
        accessibilityLabel="LambdatestScrollTop"
      />
      {!reachedBottom && (
        <View
          style={[styles.reachedBottom, { top: buttonTop }]}
          accessibilityLabel="LambdatestScrollReachedBottom"
          pointerEvents="none"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  overlayContainer: {
    flexDirection: "row",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    elevation: 9999,
    backgroundColor: "transparent",
  },
  scrollPageDown: {
    flexDirection: "row",
    position: "absolute",
    top: -50,
    left: 0,
    height: 20,
    width: 20,
    zIndex: 9999,
    elevation: 9999,
    backgroundColor: "transparent",
  },
  scrollTop: {
    flexDirection: "row",
    position: "absolute",
    top: 0,
    left: 20,
    height: 20,
    width: 20,
    zIndex: 9999,
    elevation: 9999,
    backgroundColor: "transparent",
  },
  reachedBottom: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    zIndex: 9999,
    elevation: 9999,
    backgroundColor: "transparent",
  },
});
