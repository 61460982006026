import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Colors, IconFill, IconSize } from "./Icon";
import { getColorValue } from "../../utils/colors";
import variables from "../../styles/variables";
import RText from "../RText";

type Props = {
  component: any;
  size?: IconSize;
  fill?: IconFill | string; // either a color from variables.ts or a hex color string
  style?: any;
  rotate?: number;
  notifications?: number;
  stroke?: IconFill;
  color?: Colors;
  onTabar?: boolean;
};

const SharedIcon = ({
  component: IconComponent,
  style,
  fill,
  size,
  rotate,
  stroke,
  notifications,
  onTabar,
  color,
  ...rest
}: Props) => {
  return (
    <>
      <IconComponent
        style={[
          styles.icon,
          style,
          rotate ? { transform: [{ rotate: `${rotate}deg` }] } : {},
        ]}
        color={color && color}
        fill={fill && getColorValue(fill)}
        stroke={stroke && getColorValue(stroke)}
        {...(size && variables.sizes[size])}
        {...rest}
      />
      {notifications > 0 && (
        <View
          style={{
            position: "absolute",
            top: onTabar ? 0 : -10,
            right: -10,
            width: 20,
            height: 20,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#18b8b8",
            borderRadius: 10,
          }}
        >
          <RText
            color="white"
            weight="bold"
            size={notifications > 99 ? "xxs" : "xs"}
            style={{ position: "absolute" }}
          >
            {`${notifications}`}
          </RText>
        </View>
      )}
    </>
  );
};

export default SharedIcon;

/* don't add height and width attrs for web */
const styles =
  Platform.OS === "web"
    ? StyleSheet.create({
        icon: {
          userSelect: "none", // stops any nearby text highlighting
        },
      })
    : StyleSheet.create({
        icon: {
          height: "100%",
          width: "100%",
        },
      });
