import React from "react";
import { View, StyleSheet, useWindowDimensions, ViewStyle } from "react-native";
import RText from "../../RText";
import variables from "../../../styles/variables";
import Icon from "../../Icon";
import Layout from "../../Layout/Layout";

const { colors, spacing } = variables;

// If the data request was successfully fulfilled but there is no content to display, display this component

type Props = {
  message?: string;
  style?: ViewStyle;
  iconType?: string | null;
  component?: React.ReactNode;
  inLayout?: boolean;
  dark?: boolean;
};

const EmptyState: React.FC<Props> = ({
  message,
  style = { marginVertical: spacing.small },
  iconType = "plug",
  component,
  inLayout = false,
  dark = false,
}) => {
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 567;

  const Content = () => (
    <View
      style={[
        style,
        styles.container,
        isSmallScreen && !dark
          ? {
              aspectRatio: 1,
            }
          : {},
        {
          backgroundColor: dark ? "transparent" : colors.palette.neutral.x100,
        },
      ]}
    >
      {iconType ? (
        <Icon
          name={iconType}
          size="xxxlarge"
          fill={dark ? "palette.neutral.x0" : "palette.neutral.x500"}
        />
      ) : null}
      <RText
        family="secondary"
        weight="bold"
        color={dark ? "white" : "grey"}
        uppercase
        style={[styles.text, style]}
      >
        {message || "No content to display"}
      </RText>
      {component ? component : null}
    </View>
  );

  return inLayout ? (
    <Layout headingLoader={false} content={<Content />} />
  ) : (
    <Content />
  );
};

export default EmptyState;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: spacing.medium,
    minHeight: 200,
    width: "100%",
  },
  text: { textAlign: "center" },
});
