import { Aborter } from "./Aborter";

export class AborterManager {
  private static instance: AborterManager;
  private aborters: Record<string, Aborter> = {};

  private constructor() {} // Private constructor to prevent direct instantiation

  /**
   * Retrieves the single instance of AborterManager.
   * @returns {AborterManager} The singleton instance.
   */
  public static getInstance(): AborterManager {
    if (!AborterManager.instance) {
      AborterManager.instance = new AborterManager();
    }
    return AborterManager.instance;
  }

  /**
   * Retrieves or creates an Aborter for the specified key.
   * @param key - Unique identifier for a screen or group of requests.
   * @returns {Aborter} An Aborter instance for the key.
   */
  getAborter(key: string): Aborter {
    if (!this.aborters[key]) {
      this.aborters[key] = new Aborter();
    }
    return this.aborters[key];
  }

  /**
   * Aborts all ongoing requests associated with the specified key.
   * @param key - Unique identifier for a screen or group of requests.
   */
  abortRequestsForKey(key: string) {
    if (this.aborters[key]) {
      this.aborters[key].abortOngoingRequest();
      delete this.aborters[key];
      console.log(`[AborterManager] Aborted all requests for key: ${key}`);
    }
  }

  /**
   * Aborts all ongoing requests across all keys.
   */
  abortAll() {
    Object.keys(this.aborters).forEach((key) => this.abortRequestsForKey(key));
    console.log(`[AborterManager] Aborted all requests.`);
  }
}
